import styled from "styled-components";

export const LargeCard = styled.div`
	height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #eeeeee;
	width: 30vw;
	margin-top: 115px;

	h2 {
		margin-top: 15px;
	}
`;

export const Circle = styled.div`
	background: var(--croi-green);
	border-radius: 50%;
	color: white;
	width: 75px;
	height: 75px;
	text-align: center;
	font-size: 30px;
	line-height: 70px;
	margin-left: 5%;
	padding-top: 4px;
`;

export const SchoolSection = styled.div`
	display: flex;
	margin-top: 5%;

	div {
		display: block;
	}

	h3 {
		padding-top: 15px;
		font-size: 35px;
		width: 22vw;
	}

	p {
		font-size: 15px;
	}
`;
