import {
	DataEntry,
	DataLargeInfo,
	DataRemaining,
	DataScore,
	GradeCircle,
	ResultCircle,
	ResultsTableStyle
} from "./Results.styles";
import { currencyFormatter } from "../../util/currencyFormatter";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchPetersonData } from "../../services/College.services";
import { Rankings } from "../../services/Store.services";
import { PetersonCollege } from "../../types/PetersonCollege.types";
import { User } from "../../types/User.types";
import { fetchUser } from "../../services/User.services";

export default function ResultsTable() {
	const getColleges = useQuery<PetersonCollege[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchPetersonData()
	});

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false
	});

	const netCost = (award: number, cost: number) => {
		if (cost - award <= 0) {
			return 0;
		} else {
			return cost - award;
		}
	};

	const grader = (score: number) => {
		if (score >= 80) {
			return "A";
		} else if (score >= 60) {
			return "B";
		} else if (score >= 40) {
			return "C";
		} else if (score >= 20) {
			return "D";
		} else {
			return "F";
		}
	};

	const colorChange = (score: number) => {
		if (score >= 80) {
			return "#3AD698";
		} else if (score >= 60) {
			return "#3ABAD6";
		} else if (score >= 40) {
			return "#FFD521";
		} else if (score >= 20) {
			return "#EB9C26";
		} else {
			return "#F50000";
		}
	};

	const createRankings = () => {
		const ranks: Rankings[] = [];

		if (getColleges.data) {
			for (let i = 0; i < getColleges.data.length; i++) {
				let totalAmount = 0;
				getColleges.data[i].financialAwards.map((financialAward) => (totalAmount += financialAward.data.amount));

				ranks.push({
					name: getColleges.data[i].name,
					awards: totalAmount,
					field: getColleges.data[i].fieldOfStudy ? getColleges.data[i].fieldOfStudy : "Undecided",
					cost: getColleges.data[i].costPerYear,
					salary: getColleges.data[i].expectedSalary,
					acceptance: getColleges.data[i].acceptanceRate,
					retention: getColleges.data[i].freshmanRetentionRate,
					graduation: getColleges.data[i].graduationRate,
					success: getColleges.data[i].jobSuccessRate,
					roi: getColleges.data[i].ROI,
					environment: getColleges.data[i].environment,
					size: getColleges.data[i].size,
					type: getColleges.data[i].type,
					life: getColleges.data[i].life,
					studentTeacher: getColleges.data[i].studentTeacherRatio,
					maleFemale: getColleges.data[i].maleFemaleRatio,
					score: getColleges.data[i].score
				});
			}
		}

		ranks.sort((a, b) => {
			if (a.score > b.score) {
				return -1;
			} else if (a.score < b.score) {
				return 1;
			} else {
				return 0;
			}
		});

		return ranks;
	};

	const [ranks, setRanks] = useState<Rankings[]>(createRankings);

	useEffect(() => {
		if (getColleges.data && !getColleges.isFetching) {
			setRanks(createRankings);
		}
	}, [getColleges.isFetching]);

	const envChecker = (env: string) => {
		if (getUser.data) {
			if (getUser.data.data.pref.environment == env) {
				return (
					<>
						{env}&nbsp;<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
					</>
				);
			} else {
				return (
					<>
						{env}&nbsp;<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
					</>
				);
			}
		}
	};

	const sizeChecker = (size: string) => {
		if (getUser.data) {
			if (getUser.data.data.pref.size == size) {
				return (
					<>
						{size}&nbsp;<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
					</>
				);
			} else {
				return (
					<>
						{size}&nbsp;<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
					</>
				);
			}
		}
	};

	const typeList = (list: string[]) => {
		const html = list.map((value) => <>{value}&nbsp;</>);

		if (getUser.data) {
			for (let i = 0; i < getUser.data.data.pref.type.length; i++) {
				if (!list.includes(getUser.data.data.pref.type[i])) {
					html.push(<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>);
					return html;
				}
			}
			html.push(<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>);
			return html;
		}
	};

	const lifeList = (list: string[]) => {
		const max = list.length > 3 ? 3 : list.length;
		let correct = true;

		if (getUser.data) {
			const lifePreferences = getUser.data.data.pref.campusLife;
			let line = "";
			let i: number;

			for (i = 0; i < lifePreferences.length && i < max; i++) {
				if (i < lifePreferences.length) {
					if (!list.includes(lifePreferences[i])) {
						correct = false;
					} else {
						line += lifePreferences[i] + ", ";
						list = list
							.slice(0, list.indexOf(lifePreferences[i]))
							.concat(list.slice(list.indexOf(lifePreferences[i]) + 1, list.length - 1));
					}
				}
			}

			for (i = max - i; i > 0; i--) {
				line += list[i] + ", ";
			}

			line = line.slice(0, line.length - 2);

			if (correct) {
				return (
					<>
						{line}&nbsp;<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
					</>
				);
			} else {
				return (
					<>
						{line}&nbsp;<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
					</>
				);
			}
		}
	};

	return (
		<ResultsTableStyle>
			{ranks.map((ranking, index) => (
				<DataEntry key={ranking.score + new Date().getTime()}>
					<DataScore>
						<h3>{index + 1}</h3>
						<GradeCircle style={{ backgroundColor: colorChange(ranking.score) }}>{grader(ranking.score)}</GradeCircle>
						<h5>{ranking.score.toFixed(1)}%</h5>
					</DataScore>
					<DataLargeInfo>
						<h3>{ranking.name}</h3>
						<div style={{ display: "flex" }}>
							<div style={{ width: "40%" }}>
								<b>4 Year Cost</b>
								<p>{currencyFormatter.format(netCost(ranking.awards, ranking.cost * 4))}</p>
								<b>Expected Salary</b>
								<p>{ranking.salary ? currencyFormatter.format(ranking.salary) : "N/A"}</p>
								<b>20-Year ROI</b>
								<p>{currencyFormatter.format(ranking.roi)}</p>
							</div>
							<div>
								<p style={{ display: "flex" }}>
									<b>Environment:&nbsp;</b> {envChecker(ranking.environment)}
								</p>
								<p style={{ display: "flex" }}>
									<b>Size:&nbsp;</b> {sizeChecker(ranking.size)}
								</p>
								<p style={{ display: "flex" }}>
									<b>School Type:&nbsp;</b> {typeList(ranking.type)}
								</p>
								<p style={{ display: "flex" }}>
									<b>Campus Life:&nbsp;</b> {lifeList(ranking.life)}
								</p>
							</div>
						</div>
					</DataLargeInfo>
					<DataRemaining>
						<li>
							<b>Cost Per Year</b> = {ranking.cost ? currencyFormatter.format(ranking.cost) : "N/A"}
						</li>
						<li>
							<b>Financial Award</b> = {ranking.awards ? currencyFormatter.format(ranking.awards) : "N/A"}
						</li>
						<li>
							<b>Field of Study</b> = {ranking.field ? ranking.field : "Undecided"}
						</li>
						<li>
							<b>Student:Teacher Ratio</b> = {ranking.studentTeacher}
						</li>
						<li>
							<b>Male:Female Ratio</b> = {ranking.maleFemale}
						</li>
						<li>
							<b>Job Matriculation Rate</b> = {ranking.success}%
						</li>
						<li>
							<b>Acceptance Rate</b> = {ranking.acceptance}%
						</li>
						<li>
							<b>Freshman Retention Rate</b> = {ranking.retention}%
						</li>
						<li>
							<b>4-year Graduation Rate</b> = {ranking.graduation}%
						</li>
					</DataRemaining>
				</DataEntry>
			))}
		</ResultsTableStyle>
	);
}
