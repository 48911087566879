import React, { useEffect, useState } from "react";
import {
	ApplyButton,
	FedLenderCard,
	FederalSection,
	InfoBox,
	LenderImage,
	LenderSection,
	LoanChangeButton,
	LoanChart,
	LoanRow,
	ModalStyle,
	PrivLenderCard,
	PrivateSection,
	// PrivateLendors,
	// RateButton,
	ResetButton,
	SLTButton,
	SLTOptions
} from "./StudentLoan.styles";
import { currencyFormatter } from "../../util/currencyFormatter";
import { loan } from "../../types/Loan.types";
import LoanBarChart from "../../components/LoanBarChart";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addLoan, deleteLoan, fetchLoanList, updateLoan } from "../../services/Loan.services";
import { Modal } from "react-bootstrap";
import { queryClient } from "../../services/QueryClient.services";
import { orderBy } from "lodash";
import privateList from "../../atoms/PrivateLendorList";
import federalList from "../../atoms/FederalLendorList";
import { fetchCollegeList } from "../../services/College.services";
import { College } from "../../types/College.types";

const loanDefault: loan = {
	id: "",
	uid: "",
	collegeID: "0",
	name: "name",
	classYear: "Freshman",
	amount: 0,
	interest: 0,
	term: 0,
	priFed: "private",
	subUnsub: "subsidized",
	pay: 0
};

export default function StudentLoan() {
	const [show, setShow] = useState(false);
	const [option, setOption] = useState("Add");
	const [data, setData] = useState(loanDefault);
	const [enabler, setEnabler] = useState(true);
	const [showDropDown, setShowDropDown] = useState(false);

	const getColleges = useQuery<College[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchCollegeList(),
		refetchInterval: false
	});

	if (getColleges.isLoading || getColleges.isFetching) {
		console.log("getColleges is loading");
	}

	if (getColleges.isError) {
		console.log("getColleges ERROR");
	}

	const schools = getColleges.data ? getColleges.data : [];

	const [selectedName, setSelectedName] = useState(schools[0].name);
	const [selectedID, setSelectedID] = useState(schools[0].collegeScorecardId);

	const getLoans = useQuery<loan[], Error>({
		queryKey: ["loans"],
		queryFn: () => fetchLoanList(),
		refetchInterval: false,
		enabled: enabler
	});

	if (getLoans.isError) {
		console.log("Error with getLoans: " + getLoans.error);
	}

	const createLoan = useMutation({
		mutationKey: ["loans"],
		mutationFn: (body: any) => addLoan(body),
		onSuccess: () => {
			console.log("Created Loan Successfully!");
			return queryClient.invalidateQueries(["loans"]);
		}
	});

	const changeLoan = useMutation({
		mutationKey: ["loans"],
		mutationFn: (body: any) => updateLoan(body),
		onSuccess: () => {
			console.log("Edited Loan Successfully!");
			return queryClient.invalidateQueries(["loans"]);
		}
	});

	const delLoan = useMutation({
		mutationKey: ["loans"],
		mutationFn: (body: any) => deleteLoan(body),
		onSuccess: () => {
			console.log("Deleted Loan Successfully!");
			return queryClient.invalidateQueries(["loans"]);
		}
	});

	if (createLoan.isError || changeLoan.isError || delLoan.isError) {
		console.log("Error Loan Mutation: " + createLoan?.error + "\n" + changeLoan?.error + "\n" + delLoan?.error);
	}

	const monthlyCalc = (loaner: loan) => {
		const interest = loaner.interest / 100 / 12;
		const months = 12 * loaner.term;
		const fixed = Math.pow(1 + interest, months);
		return (loaner.amount / (fixed - 1)) * (interest * fixed) + loaner.pay;
	};

	const totalCalc = () => {
		let total = 0;

		if (getLoans.data) {
			for (let i = 0; i <= getLoans.data.length - 1; i++) {
				if (getLoans.data[i].collegeID === selectedID || selectedName === "No School Selected") {
					total += monthlyCalc(getLoans.data[i]) * getLoans.data[i].term * 12;
				}
			}
		}

		return total;
	};

	const mostTime = () => {
		let time = 0;

		if (getLoans.data) {
			for (let i = 0; i <= getLoans.data.length - 1; i++) {
				if (getLoans.data[i].collegeID === selectedID || selectedName === "No School Selected") {
					if (getLoans.data[i].term > time) {
						time = getLoans.data[i].term;
					}
				}
			}
		}

		return time;
	};

	const loanChecker = () => {
		const loans = [];

		if (getLoans.data) {
			for (let i = 0; i <= getLoans.data.length - 1; i++) {
				if (getLoans.data[i].collegeID === selectedID || selectedName === "No School Selected") {
					loans.push(getLoans.data[i]);
				}
			}
		}

		return loans;
	};

	const addFunc = () => {
		setShow(true);
		setOption("Add");
		setData(loanDefault);
	};

	const editFunc = (LoanInfo: loan) => {
		setShow(true);
		setOption("Edit");
		setData(LoanInfo);
	};

	const modalDone = (l: loan) => {
		setShow(false);

		const body = {
			id: l.id,
			collegeID: l.collegeID,
			name: l.name,
			classYear: l.classYear,
			amount: l.amount,
			interest: l.interest,
			term: l.term,
			priFed: l.priFed,
			subUnsub: l.subUnsub,
			pay: l.pay
		};

		console.log("This is the body creating the loan: ", body);

		if (option === "Add") {
			createLoan.mutate(body);
		} else if (option === "Edit") {
			changeLoan.mutate(body);
		}
	};

	const deleteFunc = (LoanId: string) => {
		const body = { id: LoanId };
		delLoan.mutate(body);
	};

	const loanRenderer = orderBy(getLoans.data).map((p) => {
		console.log(p);
		console.log(selectedID);
		if (p.collegeID === selectedID) {
			return (
				<LoanRow>
					<div style={{ width: "5%" }}>
						<LoanChangeButton onClick={() => editFunc(p)}>
							+
						</LoanChangeButton>
					</div>
					<div>{p.name}</div>
					<div>{p.classYear}</div>
					<div>{currencyFormatter.format(p.amount)}</div>
					<div>{p.interest}%</div>
					<div>{p.term} Year</div>
					<div>{p.priFed}</div>
					<div>{p.subUnsub}</div>
					<div>{currencyFormatter.format(p.pay)}</div>
					<div>{currencyFormatter.format(monthlyCalc(p))}</div>
					<div style={{ width: "5%" }}>
						<LoanChangeButton onClick={() => deleteFunc(p.id)}>X</LoanChangeButton>
					</div>
				</LoanRow>
			);
		}
	});

	const toggleDropDown = () => {
		setShowDropDown(!showDropDown);
	};

	const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
		if (event.currentTarget === event.target) {
			setShowDropDown(false);
		}
	};

	useEffect(() => {
		setShowDropDown(showDropDown);
	}, [showDropDown]);

	const mapper = schools.map((school: College) =>
		schools.length > 0 ? (
			<SLTOptions
				onClick={(): void => {
					setSelectedName(school.name);
					setSelectedID(school.collegeScorecardId);
				}}>
				{school.name}
			</SLTOptions>
		) : (
			<></>
		)
	);

	// const allLoans = (
	// 	<SLTOptions
	// 		onClick={(): void => {
	// 			setSelectedName(schools[0].name);
	// 			setSelectedID(schools[0].collegeScorecardId);
	// 		}}>
	// 	</SLTOptions>
	// );

	const modalView = () => {
		const classYearOptions = ["Freshman", "Sophomore", "Junior", "Senior"];
		const priFedOptions = ["Private", "Federal"];
		const subUnsubOptions = ["Subsidized", "Unsubsidized"];

		return (
			<Modal show={show} onHide={() => setShow(!show)}>
				<ModalStyle>
					<Modal.Title style={{ margin: "10px" }}>{option} Loan</Modal.Title>
					<div>
						<p>Name: </p>
						<input
							type="text"
							placeholder={"name"}
							value={data.name}
							onChange={(e) => setData({ ...data, name: e.target.value })}
						/>
					</div>

					<div>
						<p>Class Year: </p>
						<select value={data.classYear} onChange={(e) => setData({ ...data, classYear: e.target.value })}>
							{classYearOptions.map((year, index) => (
								<option key={index} value={year}>
									{year}
								</option>
							))}
						</select>
					</div>

					<div>
						<p>Amount: </p>
						<input
							type="number"
							placeholder={"amount"}
							value={data.amount}
							onChange={(e) => setData({ ...data, amount: e.target.valueAsNumber })}
							min={0}
						/>
					</div>

					<div>
						<p>Interest: </p>
						<input
							type="text"
							placeholder={"interest"}
							onChange={(e) => {
								setData({ ...data, interest: parseFloat(e.target.value) });
							}}
						/>
					</div>

					<div>
						<p>Term: </p>
						<input
							type="number"
							placeholder={"term"}
							value={data.term}
							onChange={(e) => setData({ ...data, term: e.target.valueAsNumber })}
							min={0}
							max={100}
						/>
					</div>

					<div>
						<p>Private/ Federal: </p>
						<select value={data.priFed} onChange={(e) => setData({ ...data, priFed: e.target.value })}>
							{priFedOptions.map((op, index) => (
								<option key={index} value={op}>
									{op}
								</option>
							))}
						</select>
					</div>

					<div>
						<p>Subsidized/ Unsubsidized: </p>
						<select value={data.subUnsub} onChange={(e) => setData({ ...data, subUnsub: e.target.value })}>
							{subUnsubOptions.map((op, index) => (
								<option key={index} value={op}>
									{op}
								</option>
							))}
						</select>
					</div>

					<div>
						<p>Payment: </p>
						<input
							type="number"
							placeholder={"extra payment per month"}
							value={data.pay}
							onChange={(e) => {
								setData({ ...data, pay: e.target.valueAsNumber });
							}}
							min={0}
							max={data.amount}
						/>
					</div>

					<ResetButton
						onClick={() => {
							const temp: loan = {
								id: data.id,
								uid: data.uid,
								collegeID: selectedID,
								name: data.name,
								classYear: data.classYear,
								amount: data.amount,
								interest: data.interest,
								term: data.term,
								priFed: data.priFed,
								subUnsub: data.subUnsub,
								pay: data.pay
							};

							console.log(temp);
							modalDone(temp);
						}}
						style={{ margin: "10px" }}>
						Done
					</ResetButton>
				</ModalStyle>
			</Modal>
		);
	};

	// const lenderRender = lendorList.map((p) => {
	// 	return (
	// 		<div>
	// 			<p>{p.name}</p>
	// 			<p>{p.credit}</p>
	// 			<p>{p.fixed}</p>
	// 			<p>{p.variable}</p>
	// 			<p>
	// 				<a href={p.link}>
	// 					<RateButton>Check Rate</RateButton>
	// 				</a>
	// 			</p>
	// 		</div>
	// 	);
	// });

	const resetEvent = (): void => {
		if (getLoans.data) {
			const loanList = getLoans.data;
			setEnabler(false);

			for (let i = 0; i < loanList.length; i++) {
				deleteFunc(loanList[i].id);
			}

			setEnabler(true);
		} else {
			return;
		}
	};

	const federalLenderCard = federalList.map((p) => {
		return (
			<FedLenderCard>
				<h4>{p.name}</h4>
				<LenderImage imageUrl={p.image} />
				<a href={p.link}>
					<ApplyButton>Apply</ApplyButton>
				</a>
			</FedLenderCard>
		);
	});

	const privateLenderCard = privateList.map((p) => {
		return (
			<PrivLenderCard>
				<h4>{p.name}</h4>
				<LenderImage imageUrl={p.image} />
				<a href={p.link}>
					<ApplyButton>Apply</ApplyButton>
				</a>
			</PrivLenderCard>
		);
	});

	return (
		<>
			<SLTButton
				className={showDropDown ? "active" : undefined}
				onClick={(): void => toggleDropDown()}
				onBlur={(e: React.FocusEvent<HTMLButtonElement>): void => dismissHandler(e)}>
				{" "}
				Select School
				{showDropDown && (
					<div className={showDropDown ? "dropdown" : "dropdown active"}>
						{/* {allLoans} */}
						{mapper}
					</div>
				)}
			</SLTButton>
			<h2
				style={{
					width: "40%",
					textAlign: "center",
					marginLeft: "auto",
					marginRight: "auto",
					marginTop: "30px",
					borderBottom: "black solid 3px"
				}}>
				{selectedName}
			</h2>
			<h2 style={{ textAlign: "center", marginTop: "20px" }}>Payment Calculator</h2>
			<LoanChart>
				<LoanRow>
					<p style={{ width: "5%" }}>Edit</p>
					<p>Loan</p>
					<p>Class Year</p>
					<p>Amount ($)</p>
					<p>Interest Rate</p>
					<p>Loan Term</p>
					<p>Pri/Fed</p>
					<p>Sub/Unsub</p>
					<p>Monthly Pre-Pay</p>
					<p style={{ background: "var(--croi-green)", color: "white" }}>Monthly Payment</p>
					<p style={{ width: "5%" }}>Delete</p>
				</LoanRow>
				{getLoans.data ? modalView() : ""}
				{getLoans.data ? loanRenderer : ""}
				<LoanRow style={{ height: "60px" }}>
					<div style={{ borderStyle: "none", marginTop: "10px", marginRight: "80%" }}>
						<LoanChangeButton onClick={() => addFunc()}>
							+
						</LoanChangeButton>{" "}
						Add a Loan
					</div>
					{/* <div style={{ borderStyle: "none", marginTop: "4px", marginLeft: "10%" }}>
						<SaveButton onClick={() => saveEvent()}>Save School</SaveButton>
					</div> */}
					<div style={{ borderStyle: "none", marginTop: "4px" }}>
						<ResetButton onClick={() => resetEvent()}>Reset</ResetButton>
					</div>
				</LoanRow>
			</LoanChart>

			{getLoans.data ? (
				<InfoBox>
					Total Cost (with interest) = <b>{currencyFormatter.format(totalCalc())} </b>
					over <b>{mostTime()} years</b>.
				</InfoBox>
			) : (
				<InfoBox>No loans yet!</InfoBox>
			)}
			<div style={{ marginLeft: "12vw", marginTop: "30px" }}>
				<h4>Loan Balance Over Time</h4>
				{getLoans.data
					? loanChecker().length > 0
						? LoanBarChart(loanChecker())
						: "No loans to render"
					: "No loans to render"}
			</div>

			<LenderSection>
				<h2>Federal Loans</h2>
					<FederalSection>
						{federalLenderCard}
					</FederalSection>
				<h2>Private Loans</h2>
					<PrivateSection>
						{privateLenderCard}
					</PrivateSection>
			</LenderSection>
		</>
	);
}
