import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { FinancialProfile } from "../../types/FinancialProfile.types";
import { updateFinancialProfile } from "../../services/Profile.services";
import { queryClient } from "../../services/QueryClient.services";
import {
	CloseXButton,
	FinancialBackdrop,
	FincancialWindow,
	GreenCheckCircle,
	TitleWButton,
	WindowLeftSide,
	WindowRightSide
} from "./FinancialProfileWindow.styles";
import Select, { MultiValue } from "react-select";
import { fetchMatchData } from "../../services/College.services";

interface valueLabel {
	value: string;
	label: string;
}

const typeChoices: valueLabel[] = [
	{
		value: "Public",
		label: "Public"
	},
	{
		value: "Private",
		label: "Private"
	},
	{
		value: "Catholic",
		label: "Catholic"
	},
	{
		value: "Jewish",
		label: "Jewish"
	},
	{
		value: "Protestant",
		label: "Protestant"
	}
];

export default function FinancialProfileWindow(props: {
	profile: FinancialProfile;
	state: "Profile" | "Match" | "None";
	setState: any;
	setMatchList: any;
}) {
	const [profile] = useState<FinancialProfile>(props.profile);
	const [type, setType] = useState<string[]>([]);
	const [environment, setEnvironment] = useState("");
	const [size, setSize] = useState("");
	const [fos, setFOS] = useState("");
	const [budget, setBudget] = useState(50000);
	const [housing, setHousing] = useState("On-Campus");
	const year = new Date().getFullYear() - 2;

	const editProfile = useMutation({
		mutationKey: ["profile"],
		mutationFn: (body: any) => updateFinancialProfile(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["profile"]);
			props.setState("None");
		}
	});

	const matchGetter = useMutation({
		mutationKey: ["Match"],
		mutationFn: (body: any) => fetchMatchData(body),
		onSuccess: () => {
			setFOS("");
			setSize("");
			setEnvironment("");
		}
	});

	const typeSetter = (vals: MultiValue<valueLabel>) => {
		const temp: string[] = [];
		let religion = "";
		let colType = "";

		for (const value of vals) {
			if (value.value == "Private" || value.value == "Public") {
				colType = value.value;
			} else {
				religion = value.value;
			}
		}

		if (religion.length > 0) {
			temp.push(religion);
		}
		if (colType.length > 0) {
			temp.push(colType);
		}

		setType(temp);
	};

	const getTypeList = () => {
		const list: valueLabel[] = [];

		for (const item of type) {
			list.push({
				value: item,
				label: item
			});
		}

		return list;
	};

	const save = () => {
		const body = {
			id: props.profile.id,
			parentIncome: profile.data.parentIncome > -1 ? profile.data.parentIncome : 0,
			taxPaid: profile.data.taxPaid > -1 ? profile.data.taxPaid : 0,
			untaxedIncome: profile.data.untaxedIncome > -1 ? profile.data.untaxedIncome : 0,
			parentAssetValue: profile.data.parentAssetValue > -1 ? profile.data.parentAssetValue : 0,
			studentIncome: profile.data.studentIncome > -1 ? profile.data.studentIncome : 0,
			numParents: profile.data.numParents > -1 ? profile.data.numParents : 2,
			familyInCollege: profile.data.familyInCollege > -1 ? profile.data.familyInCollege : 0,
			familySize: profile.data.familySize > -1 ? profile.data.familySize : 3,
			oldestParent: profile.data.oldestParent > -1 ? profile.data.oldestParent : 34,
			studentAssetValue: profile.data.studentAssetValue > -1 ? profile.data.studentAssetValue : 0
		};

		editProfile.mutate(body);
	};

	const matcher = async () => {
		const body = {
			fos: fos.length > 0 ? fos : undefined,
			environment: environment.length > 0 ? environment : undefined,
			size: size.length > 0 ? size : undefined,
			type: type.length > 0 ? type : undefined,
			estBudget: budget,
			housing: housing
		};

		const data = await matchGetter.mutateAsync(body);

		props.setMatchList(data);
		props.setState("None");
	};

	const closeWindow = () => {
		props.setState("None");
	};

	if (props.state === "Profile") {
		return (
			<>
				<FinancialBackdrop />
				<FincancialWindow>
					<TitleWButton>
						<CloseXButton onClick={closeWindow}>X</CloseXButton>
						<h1>Financial Aid Profile</h1>
					</TitleWButton>
					<h2>croi</h2>
					<h3>My Family Finances</h3>
					<p>
						<i>Parent Combined Income ({year}):</i>
						<input
							defaultValue={profile.data.parentIncome > -1 ? profile.data.parentIncome : 0}
							type={"number"}
							onChange={(e) => {
								profile.data.parentIncome = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Federal/State Taxes Paid ({year}):</i>
						<input
							defaultValue={profile.data.taxPaid > -1 ? profile.data.taxPaid : 0}
							type={"number"}
							onChange={(e) => {
								profile.data.taxPaid = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Untaxed Income ({year}):</i>
						<input
							defaultValue={profile.data.untaxedIncome > -1 ? profile.data.untaxedIncome : 0}
							type={"number"}
							onChange={(e) => {
								profile.data.untaxedIncome = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Current Value of Parent's Assets:</i>
						<input
							defaultValue={profile.data.parentAssetValue > -1 ? profile.data.parentAssetValue : 0}
							type={"number"}
							onChange={(e) => {
								profile.data.parentAssetValue = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Current Value of Student's Assets:</i>
						<input
							defaultValue={profile.data.studentAssetValue > -1 ? profile.data.studentAssetValue : 0}
							type={"number"}
							onChange={(e) => {
								profile.data.studentAssetValue = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Student Income ({year}):</i>
						<input
							defaultValue={profile.data.studentIncome > -1 ? profile.data.studentIncome : 0}
							type={"number"}
							onChange={(e) => {
								profile.data.studentIncome = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<br />
					<p>
						<i>Number of Parents:</i>
						<input
							defaultValue={profile.data.numParents > -1 ? profile.data.numParents : 2}
							type={"number"}
							onChange={(e) => {
								profile.data.numParents = e.target.valueAsNumber;
							}}
							min={0}
							max={10}
							step={1}
						/>
					</p>
					<p>
						<i>Family Members in College:</i>
						<input
							defaultValue={profile.data.familyInCollege > -1 ? profile.data.familyInCollege : 0}
							type={"number"}
							onChange={(e) => {
								profile.data.familyInCollege = e.target.valueAsNumber;
							}}
							min={0}
							max={30}
							step={1}
						/>
					</p>
					<p>
						<i>Family Size:</i>
						<input
							defaultValue={profile.data.familySize > -1 ? profile.data.familySize : 3}
							type={"number"}
							onChange={(e) => {
								profile.data.familySize = e.target.valueAsNumber;
							}}
							min={1}
							max={30}
							step={1}
						/>
					</p>
					<p>
						<i>Oldest Parent's Age:</i>
						<input
							defaultValue={profile.data.oldestParent > -1 ? profile.data.oldestParent : 34}
							type={"number"}
							onChange={(e) => {
								profile.data.oldestParent = e.target.valueAsNumber;
							}}
							min={30}
							max={120}
							step={1}
						/>
					</p>
					<button onClick={() => save()}>Save and Continue</button>
				</FincancialWindow>
			</>
		);
	} else if (props.state === "Match") {
		return (
			<>
				<FinancialBackdrop></FinancialBackdrop>
				<FincancialWindow>
					<TitleWButton>
						<CloseXButton onClick={closeWindow}>X</CloseXButton>
						<h1>College Match</h1>
					</TitleWButton>
					<h2>croi</h2>
					<div style={{ display: "flex" }}>
						<WindowLeftSide>
							<h3>School Criteria</h3>
							<p>
								<i>Field of Study:</i>
								<select onChange={(e) => setFOS(e.target.value)}>
									<option></option>
									<option>Art</option>
									<option>Business</option>
									<option>Communications</option>
									<option>Computer Science</option>
									<option>Education</option>
									<option>Engineering</option>
									<option>Health Science</option>
									<option>Humanities</option>
									<option>Math</option>
									<option>Physical & Life Science</option>
									<option>Social Science</option>
								</select>
							</p>
							<p>
								<i>Environment:</i>
								<select onChange={(e) => setEnvironment(e.target.value)}>
									<option></option>
									<option>Rural</option>
									<option>Small</option>
									<option>Suburban</option>
									<option>Urban</option>
								</select>
							</p>
							<p>
								<i>Size:</i>
								<select onChange={(e) => setSize(e.target.value)}>
									<option></option>
									<option>Small (&lt;5,000)</option>
									<option>Medium (5,000-15,000)</option>
									<option>Large (&gt;15,000)</option>
								</select>
							</p>
							<p>
								<i>School Type:</i>
								<Select
									value={getTypeList()}
									onChange={(val) => typeSetter(val)}
									getOptionLabel={(val: valueLabel) => val.label}
									getOptionValue={(val: valueLabel) => val.value}
									options={typeChoices}
									isClearable={true}
									backspaceRemovesValue={true}
									isMulti={true}
									className={"select-container"}
								/>
							</p>
						</WindowLeftSide>
						<WindowRightSide>
							<h3>Finances</h3>
							<div
								style={{
									display: "flex",
									justifyContent: "space-evenly",
									width: "75%",
									marginRight: "auto",
									marginLeft: "auto"
								}}>
								<i>Financial Aid Profile:</i>
								<button
									style={{ width: "90px", height: "26px", fontSize: "14px", margin: 0 }}
									onClick={() => props.setState("Profile")}>
									Edit
								</button>
							</div>
							<b>
								Complete <GreenCheckCircle>&#10004;</GreenCheckCircle>
							</b>
							<br />
							<div style={{ marginBottom: "20px" }}>
								<i>Estimated Annual Budget:</i>
								<br />
								<select onChange={(e) => setBudget(parseInt(e.target.value))}>
									<option value={25000}>&lt;$25,000</option>
									<option value={50000}>$25,000 - $50,000</option>
									<option value={1000000}>&gt;$50,000</option>
								</select>
							</div>
							<div style={{ marginBottom: "20px" }}>
								<i>First Year Housing:</i>
								<br />
								<select onChange={(e) => setHousing(e.target.value)}>
									<option>On-Campus</option>
									<option>Off-Campus</option>
								</select>
							</div>
						</WindowRightSide>
					</div>
					<button onClick={() => matcher()}>
						Match Me&nbsp;
						<img src={"images/MatchMe.png"} alt={"MatchMeIcon"} />
					</button>
				</FincancialWindow>
			</>
		);
	} else {
		return <></>;
	}
}
