import React from "react";
import { Container, Row } from "react-bootstrap";
import bgSVG from "../../components/Background.svg";
import {
	TitleSection,
	Title,
	PageText,
	PageTitle,
	TitleWrapper,
	TitleImage,
	PageSubTitle,
	PageSubText,
	InputBox,
	TriangleButton
} from "./CustomerService.styles";
import NavBar from "../../components/Navbar/Navbar";

export default function CustomerService() {
	return (
		<>
			<NavBar />
			<TitleWrapper
				style={{
					backgroundImage: `url(${bgSVG})`,
					backgroundRepeat: "no-repeat",
					transform: "translateY(-1%)"
				}}>
				<TitleSection>
					<Title>AI-Powered Customer Service Chat-Bot</Title>
					<PageText>For further assistance, please contact your Account Representative or email: help@croi.io</PageText>
				</TitleSection>
			</TitleWrapper>
			<TitleImage>
				<img
					alt="CROI Logo"
					src={"/images/croi_white.png"}
					style={{
						width: "1200px",
						position: "absolute",
						top: "200px",
						left: "900px"
					}}></img>
			</TitleImage>

			<Container style={{ marginBottom: "70px" }} fluid>
				<Row>
					<PageTitle>Ask me anything</PageTitle>
					<PageSubTitle>Examples:</PageSubTitle>
					<PageSubText>Why is my highest scoring college choice so low?</PageSubText>
					<PageSubText>Where does the college data come from?</PageSubText>
					<PageSubText>What do I do if I do not see my major option?</PageSubText>
					<InputBox placeholder="Send a message" />
					<TriangleButton></TriangleButton>
				</Row>
			</Container>
		</>
	);
}
