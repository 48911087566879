import { useState, useEffect } from "react";
import { schoolApi } from "../api/CollegeSource";
import { SchoolName } from "../types/SchoolName.types";

const useFetch = () => {
	const [searchResults, setSearchResults] = useState<SearchFetch>({
		slug: "",
		results: [],
		loading: false
	});

	useEffect(() => {
		if (searchResults.slug !== "") {
			const fetchResults = async () => {
				setSearchResults((prevSearchResults) => ({
					...prevSearchResults,
					results: [],
					loading: true
				}));
				try {
					const res = await schoolApi.get(`/colleges/${searchResults.slug}`);
					setSearchResults((prevSearchResults) => ({
						...prevSearchResults,
						results: res.data,
						loading: false
					}));
				} catch (err) {
					console.log(err);
				}
			};

			const timeoutId = setTimeout(fetchResults, 1000);
			return () => clearTimeout(timeoutId);
		}
	}, [searchResults.slug]); // Only include searchResults.slug as a dependency

	return { searchResults, setSearchResults };
};

interface SearchFetch {
	slug: string;
	results: SchoolName[];
	loading: boolean;
}
export default useFetch;
