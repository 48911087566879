import styled from "styled-components";
import { device } from "../../util/device";

export const TopWrapper = styled.div`
	@media only screen and ${device.mobileM} {
		display: flow;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: space-evenly;
	}
`;

export const Disclaimer = styled.div`
	display: block;
	margin-left: 17.5vw;
	text-align: center;

	@media only screen and ${device.mobileM} {
		font-size: 13px;
		width: 80vw;
	}

	@media only screen and ${device.tablet} {
		font-size: 15px;
		width: 80vw;
	}
`;
