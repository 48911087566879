import styled from "styled-components";

export const LoginWrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 95vw;
`;

export const LoginCardWrapper = styled.div`
	border: 3px solid var(--croi-green);
	border-radius: 8px;
`;
