import React from "react";
import { DashColumn, DashMaster, DashNext, ProfileBlock, ProfileImageCircle } from "./Dashboard.styles";
import { useQuery } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { fetchUser } from "../../services/User.services";
import { auth } from "../../firebase";
import CollegeList from "../../components/CollegeDecisionModules/CollegeList";

export default function Dashboard(props: { setTool: any }) {
	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchOnWindowFocus: false,
		staleTime: 600000
	});

	const user = auth.currentUser;

	const isAdminCheck = (check: boolean) => {
		if (check) {
			return <u onClick={() => props.setTool("Admin")}>Admin Panel</u>;
		}
	};

	return (
		<DashMaster>
			<DashColumn>
				<ProfileBlock>
					{user?.photoURL ? (
						<ProfileImageCircle imageUrl={user.photoURL} style={{ transform: "translateY(-20%)" }} />
					) : (
						<ProfileImageCircle
							imageUrl={"images/profileLogo.png"}
							style={{
								transform: "translateY(-20%)",
								backgroundSize: "50%",
								backgroundRepeat: "no-repeat"
							}}
						/>
					)}

					<div>
						<p>
							{getUser.data?.data.firstName} {getUser.data?.data.lastName}
						</p>
						<p>{getUser.data?.data.highSchoolName}</p>
						<p>{isAdminCheck(getUser.data?.data.isAdmin ? getUser.data?.data.isAdmin : false)}</p>
						<u onClick={() => props.setTool("Settings")}>Account Settings</u>
					</div>
				</ProfileBlock>

				<DashNext>
					<h3>What's Next</h3>
					Some croiLearn stuff here
				</DashNext>
			</DashColumn>

			<DashColumn>
				<CollegeList />
			</DashColumn>
		</DashMaster>
	);
}
