import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { SalaryBox, SalaryDataRow, SalaryTable } from "./SalaryDataBox.styles";
import { currencyFormatter } from "../../util/currencyFormatter";
import { BestByMajor, CommonJobs } from "../../types/Salary.types";

export default function SalaryDataBox(props: { type: string; data: any[] }) {
	const [open, setOpen] = useState(false);
	const title = () => {
		if (props.type === "BestAllBachelor") {
			return "Best Bachelor Degrees";
		} else if (props.type === "BestSchoolByMajor") {
			return "Best Schools By Major";
		} else if (props.type === "BestMajorBySalary") {
			return "Best Majors By Salary";
		} else if (props.type === "BestTwoYear") {
			return "Best Associate Degrees";
		} else if (props.type === "CommonJobs") {
			return "Common Jobs By Major";
		} else if (props.type === "MajorPayBack") {
			return "Majors That Pay You Back";
		} else {
			return "Something went wrong!";
		}
	};

	const bigMapper = () => {
		if (props.type === "BestAllBachelor") {
			return (
				<SalaryTable>
					<thead>
						<tr>
							<td>Rank</td>
							<td>IPEDS ID</td>
							<td>School Name</td>
							<td>Early Career Median Pay</td>
							<td>Mid-Career Median Pay</td>
							<td>High Job Meaning</td>
							<td>State</td>
							<td>Private/Public</td>
							<td>School Type</td>
							<td>% Male/Female</td>
							<td>Undergrad Enrollment</td>
							<td>STEM</td>
							<td>Zip Code</td>
						</tr>
					</thead>
					<tbody>
						{props.data.map((p) => {
							return (
								<tr key={`Big${p.id}`}>
									<td>{p.rank}</td>
									<td>{p.id}</td>
									<td>{p.name}</td>
									<td>{currencyFormatter.format(p.earlyPay)}</td>
									<td>{currencyFormatter.format(p.midPay)}</td>
									<td>{p.jobMeaning}%</td>
									<td>{p.state}</td>
									<td>{p.sector}</td>
									<td>
										{p.type.map((t: string) => {
											return `${t}, `;
										})}
									</td>
									<td>
										{p.male}% / {p.female}%
									</td>
									<td>{p.enrollment}</td>
									<td>{p.stem}%</td>
									<td>{p.zip}</td>
								</tr>
							);
						})}
					</tbody>
				</SalaryTable>
			);
		} else if (props.type === "BestSchoolByMajor") {
			return (
				<SalaryTable>
					<thead>
						<tr>
							<td>Rank</td>
							<td>IPEDS ID</td>
							<td>School Name</td>
							<td>Major</td>
							<td>Early Career Median Pay</td>
							<td>Mid-Career Median Pay</td>
						</tr>
					</thead>
					<tbody>
						{props.data.map((p) => {
							return (
								<tr key={`Big${p.rank}SCHOOL${p.major}`}>
									<td>{p.rank}</td>
									<td>{p.id}</td>
									<td>{p.name}</td>
									<td>{p.major}</td>
									<td>{currencyFormatter.format(p.earlyPay)}</td>
									<td>{currencyFormatter.format(p.midPay)}</td>
								</tr>
							);
						})}
					</tbody>
				</SalaryTable>
			);
		} else if (props.type === "BestMajorBySalary") {
			return (
				<SalaryTable>
					<thead>
						<tr>
							<td>Rank</td>
							<td>IPEDS ID</td>
							<td>School Name</td>
							<td>Major</td>
							<td>Early Career Median Pay</td>
							<td>Mid-Career Median Pay</td>
						</tr>
					</thead>
					<tbody>
						{props.data
							.sort((a, b) => {
								return a.midPay > b.midPay ? -1 : 1;
							})
							.map((p) => {
								return (
									<tr key={`Big${p.rank}Salary${p.major}`}>
										<td>{p.rank}</td>
										<td>{p.id}</td>
										<td>{p.name}</td>
										<td>{p.major}</td>
										<td>{currencyFormatter.format(p.earlyPay)}</td>
										<td>{currencyFormatter.format(p.midPay)}</td>
									</tr>
								);
							})}
					</tbody>
				</SalaryTable>
			);
		} else if (props.type === "CommonJobs") {
			return (
				<SalaryTable>
					<thead>
						<tr>
							<td>Rank</td>
							<td>Major</td>
							<td>title</td>
							<td>Relative Commonness</td>
							<td>Mid-Career Median Pay</td>
							<td>High Job Meaning</td>
							<td>Most Common Major</td>
							<td>% With Major</td>
						</tr>
					</thead>
					<tbody>
						{props.data.map((p) => {
							return (
								<tr key={`Big${p.rank}${p.title}`}>
									<td>{p.rank}</td>
									<td>{p.major}</td>
									<td>{p.title}</td>
									<td>{p.common}</td>
									<td>{currencyFormatter.format(p.midPay)}</td>
									<td>{p.jobMeaning}%</td>
									<td>{p.commonMajor}</td>
									<td>{p.percentage}%</td>
								</tr>
							);
						})}
					</tbody>
				</SalaryTable>
			);
		} else if (props.type === "MajorPayBack") {
			return (
				<SalaryTable>
					<thead>
						<tr>
							<td>Rank</td>
							<td>major</td>
							<td>Early Career Median Pay</td>
							<td>Mid-Career Median Pay</td>
							<td>High Job Meaning</td>
						</tr>
					</thead>
					<tbody>
						{props.data.map((p) => {
							return (
								<tr key={`Big${p.rank}${p.major}`}>
									<td>{p.rank}</td>
									<td>{p.major}</td>
									<td>{currencyFormatter.format(p.earlyPay)}</td>
									<td>{currencyFormatter.format(p.midPay)}</td>
									<td>{p.jobMeaning}%</td>
								</tr>
							);
						})}
					</tbody>
				</SalaryTable>
			);
		} else if (props.type === "BestTwoYear") {
			return (
				<SalaryTable>
					<thead>
						<tr>
							<td>Rank</td>
							<td>IPEDS ID</td>
							<td>School Name</td>
							<td>Early Career Median Pay</td>
							<td>Mid-Career Median Pay</td>
							<td>High Job Meaning</td>
							<td>State</td>
							<td>School Type</td>
							<td>% Male/Female</td>
							<td>Undergrad Enrollment</td>
							<td>STEM</td>
							<td>Zip Code</td>
						</tr>
					</thead>
					<tbody>
						{props.data.map((p) => {
							return (
								<tr key={`Big${p.rank}${p.id}`}>
									<td>{p.rank}</td>
									<td>{p.id}</td>
									<td>{p.name}</td>
									<td>{currencyFormatter.format(p.earlyPay)}</td>
									<td>{currencyFormatter.format(p.midPay)}</td>
									<td>{p.jobMeaning ? p.jobMeaning + "%" : "N/A"}</td>
									<td>{p.state}</td>
									<td>
										{p.type.map((t: string) => {
											return `${t}, `;
										})}
									</td>
									<td>
										{p.male}% / {p.female}%
									</td>
									<td>{p.enrollment}</td>
									<td>{p.stem}%</td>
									<td>{p.zip}</td>
								</tr>
							);
						})}
					</tbody>
				</SalaryTable>
			);
		} else {
			return <>Something went wrong!</>;
		}
	};

	const smallMapper = () => {
		if (props.type === "BestAllBachelor" || props.type === "BestTwoYear") {
			return props.data.slice(0, 3).map((p) => {
				return (
					<SalaryDataRow key={`${p.rank}${p.id}`}>
						<p className={"title"}>{p.name}</p>
						<p>{currencyFormatter.format(p.midPay)}</p>
					</SalaryDataRow>
				);
			});
		} else if (props.type === "BestSchoolByMajor") {
			return props.data
				.filter((p) => {
					return p.rank === 1;
				})
				.slice(0, 3)
				.map((p: BestByMajor) => {
					return (
						<SalaryDataRow key={`${p.rank}${p.major}${p.id}`}>
							<p className={"title"}>
								{p.name} ({p.major})
							</p>
							<p>{currencyFormatter.format(p.midPay)}</p>
						</SalaryDataRow>
					);
				});
		} else if (props.type === "BestMajorBySalary") {
			return props.data
				.filter((p: BestByMajor) => {
					return p.rank === 1;
				})
				.sort((a, b) => {
					return a.midPay > b.midPay ? -1 : 1;
				})
				.slice(0, 3)
				.map((p: BestByMajor) => {
					return (
						<SalaryDataRow key={`${p.id}${p.major}${p.rank}`}>
							<p className={"title"}>{p.major}</p>
							<p>{currencyFormatter.format(p.midPay)}</p>
						</SalaryDataRow>
					);
				});
		} else if (props.type === "CommonJobs") {
			return props.data
				.filter((p) => {
					return p.rank === 1;
				})
				.slice(0, 3)
				.map((p: CommonJobs) => {
					return (
						<SalaryDataRow key={`${p.major}${p.title}`}>
							<p className={"title"}>
								{p.title} ({p.major})
							</p>
							<p>{currencyFormatter.format(p.midPay)}</p>
						</SalaryDataRow>
					);
				});
		} else if (props.type === "MajorPayBack") {
			return props.data.slice(0, 3).map((p) => {
				return (
					<SalaryDataRow key={`${p.major}${p.midPay}`}>
						<p className={"title"}>{p.major}</p>
						<p>{currencyFormatter.format(p.midPay)}</p>
					</SalaryDataRow>
				);
			});
		} else {
			return <>Something went wrong!</>;
		}
	};

	return (
		<>
			<Modal show={open} onHide={() => setOpen(false)} fullscreen={true}>
				<Modal.Title
					onClick={() => setOpen(false)}
					style={{ textAlign: "center", background: "Red", cursor: "pointer" }}>
					To Exit: Press Esc or Click Here
				</Modal.Title>
				<Modal.Body>{bigMapper()}</Modal.Body>
			</Modal>
			<SalaryBox>
				<h4>{title()}</h4>
				{smallMapper()}
				<button onClick={() => setOpen(true)}>View All Data</button>
			</SalaryBox>
		</>
	);
}
