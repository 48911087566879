import styled from "styled-components";
import { device } from "../../util/device";

export const CalcButton = styled.button`
	background-color: var(--croi-green);
	border: none;
	border-radius: 10px;
	color: white;
	height: 50px;
	width: 80%;
	margin-top: 20px;
	font-weight: bold;
	font-size: 20px;
`;

export const LargeCard = styled.div`
	height: 678px;
	text-align: center;

	p {
		font-size: 30px;
		margin-top: 10px;
	}

	h2 {
		font-weight: bold;
		margin-top: 15px;
	}

	@media only screen and ${device.mobileM} {
		width: 80vw;
		margin-left: auto;
		margin-right: auto;

		h2 {
			padding-top: 15px;
		}
	}

	@media only screen and ${device.tablet} {
		width: 33vw;
		margin-left: 0;
		margin-right: 0;

		h2 {
			padding-top: 0;
		}
	}
`;

export const Importance = styled.div`
	justify-content: space-between;
	display: flex;

	p {
		font-size: 14px;
		width: 90px;
		text-align: center;
	}
`;

export const PrefList = styled.div`
	overflow-x: hidden;
	overflow-y: auto;
	height: 77%;

	&::-webkit-scrollbar {
		width: 15px;
	}

	&::-webkit-scrollbar-track {
		background: #eeeeee;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--croi-green);
		border-radius: 10px;
		border: 2px solid var(--croi-green);;
	}
`;

export const SmallCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 95%;
	margin-left: 2.5%;
	background-color: #eeeeee;
	margin-bottom: 10px;
	border-radius: 10px;
	height: 110px;

	h2 {
		font-size: 20px;
		margin-bottom: 0;
		margin-top: 0;
	}

	p {
		font-size: 15px;
		margin-top: 0;
		margin-bottom: 20px;
	}
`;

export const handleStyle = {
	width: 20,
	height: 35,
	borderRadius: "25px",
	marginTop: -20,
	backgroundColor: "var(--croi-green)",
	opacity: "100",
	border: "none"
};

export const trackStyle = {
	// height: 5,
	// backgroundColor: "var(--croi-green)"
	display: "none"
};

export const dotStyle = {
	width: 20,
	height: 20,
	borderRadius: "50%",
	backgroundColor: "#6D7471",
	border: "none"
};

export const railStyle = {
	height: 5,
	backgroundColor: "#6D7471",
	marginTop: "-7px"
};
