import React, { useEffect, useState } from "react";
import {
	Bar,
	Circle,
	LoggedInButtons,
	NumberLabelBottom,
	NumberLabelMiddle,
	NumberLabelTop,
	NumberLineContainer,
	ProfileButton,
	ProfileOptions,
	ResultsLargeCard,
	TempLine,
	TriangleBlack,
	TriangleGreen,
	TriangleRed
} from "./Results.styles";
import { useQuery } from "@tanstack/react-query";
import { College } from "../../types/College.types";
import { fetchCollegeList } from "../../services/College.services";
import { ScaleLoader } from "react-spinners";
import { Col, Row } from "react-bootstrap";
import { useAtom } from "jotai";
import { gradingsAtom } from "../../services/Store.services";

export default function Results() {
	const [showDropDown, setShowDropDown] = useState(false);
	const { isLoading, isError, data, error } = useQuery<College[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchCollegeList()
	});
	const [gradings] = useAtom(gradingsAtom);
	const [currCollege, setCurrCollege] = useState(gradings.length ? gradings[0] : null);

	useEffect(() => {
		setCurrCollege(gradings[0]);
	}, [gradings]);

	if (isLoading) {
		return (
			<ResultsLargeCard>
				<h2>Results</h2>
				<div style={{ marginLeft: "49%" }}>
					<ScaleLoader color="black" loading={isLoading} />
				</div>
			</ResultsLargeCard>
		);
	}

	if (isError) {
		return (
			<ResultsLargeCard>
				<h2>Results</h2>
				<span>
					Error: {error.message} {data}
				</span>
			</ResultsLargeCard>
		);
	}

	const colorCalc = (userNum: number, collNum: number) => {
		if (userNum == collNum) {
			return "black";
		} else if (userNum > collNum) {
			return "green";
		} else {
			return "red";
		}
	};

	const triangle = (color: string) => {
		if (color === "red") {
			return <TriangleRed></TriangleRed>;
		} else if (color === "green") {
			return <TriangleGreen></TriangleGreen>;
		} else {
			return <TriangleBlack></TriangleBlack>;
		}
	};

	const wordScore = (score: number) => {
		if (score >= 90) {
			return "Safety";
		} else if (score >= 80) {
			return "Target";
		} else if (score >= 70) {
			return "Reach";
		} else if (score >= 60) {
			return "Unlikely";
		} else if (score < 60) {
			return "Improbable";
		}
	};

	const letterScore = (score: number) => {
		if (score >= 90) {
			return "A";
		} else if (score >= 80) {
			return "B";
		} else if (score >= 70) {
			return "C";
		} else if (score >= 60) {
			return "D";
		} else {
			return "F";
		}
	};

	const barPercent = (userNum: number, collNum: number) => {
		if (userNum >= collNum) {
			userNum = collNum;
		} else if (userNum <= 0) {
			userNum = 0;
		}
		return (userNum / collNum) * 100;
	};

	const displayData = () => {
		if (currCollege) {
			const cData: collegeData[] = [
				{
					type: "SAT-ERW",
					score: currCollege.satEng_user,
					color: colorCalc(currCollege.satEng_user, currCollege.satEng_college)
				},
				{
					type: "SAT-M",
					score: currCollege.satMat_user,
					color: colorCalc(currCollege.satMat_user, currCollege.satMat_college)
				},
				{
					type: "ACT",
					score: currCollege.actScore_user,
					color: colorCalc(currCollege.actScore_user, currCollege.actScore_college)
				},
				{ type: "GPA", score: currCollege.gpa_user, color: colorCalc(currCollege.gpa_user, currCollege.gpa_college) }
			];
			return (
				<>
					<h2>{currCollege.name}</h2>
					<h5>Field of Study: {currCollege.FOS ? currCollege.FOS : "N/A"} </h5>
					<TempLine></TempLine>
					<Row className="m-auto">
						<Col xs={6}>
							<h2>{wordScore(currCollege.score)}</h2>
							<Circle>{letterScore(currCollege.score)}</Circle>
							<h1 style={{ paddingTop: "10px" }}>{currCollege.score.toFixed(2)}%</h1>
							<h5>Chance of Admission</h5>
							<TempLine></TempLine>
							<h4 style={{ paddingBottom: "25px" }}>Admission Statistics</h4>
							<table
								style={{
									width: "50%",
									paddingLeft: "25%",
									margin: "auto"
								}}>
								{cData.map((cd) => (
									<tr style={{ fontSize: "20px", justifyContent: "space-evenly" }}>
										<td style={{ textAlign: "right" }}>{cd.type}</td>
										<td>{cd.score}</td>
										<td style={{ paddingRight: "30%" }}>{triangle(cd.color)}</td>
									</tr>
								))}
							</table>
							<TempLine></TempLine>
						</Col>
						<Col className="m-auto" xs={6}>
							<h3 style={{ paddingBottom: "25px", marginRight: "10%" }}>SAT-ERW</h3>
							<NumberLineContainer>
								<NumberLabelMiddle style={{ left: "0%", paddingRight: "30px" }}>0</NumberLabelMiddle>
								<Bar style={{ left: barPercent(currCollege.satEng_user, 800) + "%", backgroundColor: "red" }} />
								<NumberLabelTop
									style={{ left: barPercent(currCollege.satEng_user, 800) + "%", color: "var(--croi-green)" }}>
									{currCollege.satEng_user} (You)
								</NumberLabelTop>
								<Bar style={{ left: barPercent(currCollege.satEng_college, 800) + "%" }} />
								<NumberLabelBottom style={{ left: barPercent(currCollege.satEng_college, 800) + "%" }}>
									{currCollege.satEng_college} (AVG)
								</NumberLabelBottom>
								<NumberLabelMiddle style={{ left: "100%", paddingLeft: "45px" }}>800</NumberLabelMiddle>
							</NumberLineContainer>
							<h3 style={{ paddingTop: "60px", paddingBottom: "25px", marginRight: "10%" }}>SAT-M</h3>
							<NumberLineContainer>
								<NumberLabelMiddle style={{ left: "0%", paddingRight: "30px" }}>0</NumberLabelMiddle>
								<Bar style={{ left: barPercent(currCollege.satMat_user, 800) + "%", backgroundColor: "red" }} />
								<NumberLabelTop
									style={{ left: barPercent(currCollege.satMat_user, 800) + "%", color: "var(--croi-green)" }}>
									{currCollege.satMat_user} (You)
								</NumberLabelTop>
								<Bar style={{ left: barPercent(currCollege.satMat_college, 800) + "%" }} />
								<NumberLabelBottom style={{ left: barPercent(currCollege.satMat_college, 800) + "%" }}>
									{currCollege.satMat_college} (AVG)
								</NumberLabelBottom>
								<NumberLabelMiddle style={{ left: "100%", paddingLeft: "45px" }}>800</NumberLabelMiddle>
							</NumberLineContainer>
							<h3 style={{ paddingTop: "60px", paddingBottom: "25px", marginRight: "10%" }}>ACT</h3>
							<NumberLineContainer>
								<NumberLabelMiddle style={{ left: "0%", paddingRight: "30px" }}>0</NumberLabelMiddle>
								<Bar style={{ left: barPercent(currCollege.actScore_user, 36) + "%", backgroundColor: "red" }} />
								<NumberLabelTop
									style={{ left: barPercent(currCollege.actScore_user, 36) + "%", color: "var(--croi-green)" }}>
									{currCollege.actScore_user} (You)
								</NumberLabelTop>
								<Bar style={{ left: barPercent(currCollege.actScore_college, 36) + "%" }} />
								<NumberLabelBottom style={{ left: barPercent(currCollege.actScore_college, 36) + "%" }}>
									{currCollege.actScore_college} (AVG)
								</NumberLabelBottom>
								<NumberLabelMiddle style={{ left: "100%", paddingLeft: "40px" }}>36</NumberLabelMiddle>
							</NumberLineContainer>
							<h3 style={{ paddingTop: "60px", paddingBottom: "25px", marginRight: "10%" }}>GPA</h3>
							<NumberLineContainer>
								<NumberLabelMiddle style={{ left: "0%", paddingRight: "30px" }}>0</NumberLabelMiddle>
								<Bar style={{ left: barPercent(currCollege.gpa_user, 4.0) + "%", backgroundColor: "red" }} />
								<NumberLabelTop
									style={{ left: barPercent(currCollege.gpa_user, 4.0) + "%", color: "var(--croi-green)" }}>
									{currCollege.gpa_user} (You)
								</NumberLabelTop>
								<Bar style={{ left: barPercent(currCollege.gpa_college, 4.0) + "%" }} />
								<NumberLabelBottom style={{ left: barPercent(currCollege.gpa_college, 4.0) + "%" }}>
									{currCollege.gpa_college} (AVG)
								</NumberLabelBottom>
								<NumberLabelMiddle style={{ left: "100%", paddingLeft: "40px" }}>4.0</NumberLabelMiddle>
							</NumberLineContainer>
						</Col>
					</Row>
				</>
			);
		} else {
			return <>Nothing to see</>;
		}
	};

	const toggleDropDown = () => {
		setShowDropDown(!showDropDown);
	};

	const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
		if (event.currentTarget === event.target) {
			setShowDropDown(false);
		}
	};

	const options = gradings.map((grading) => {
		return grading.name;
	});

	const optionSelection = (option: string): void => {
		setCurrCollege(gradings.find((grading) => grading.name === option)!);
	};

	const mapper = options.map((option: string) => (
		<ProfileOptions
			key={option}
			onClick={(): void => {
				optionSelection(option);
			}}>
			{option}
		</ProfileOptions>
	));

	return (
		<>
			<ResultsLargeCard>
				<div style={{ display: "flex", justifyContent: "center", marginLeft: "10%", paddingTop: "20px" }}>
					<h1>Results</h1>
					<LoggedInButtons>
						<ProfileButton
							imageUrl={"/images/listBuilderDropDown.png"}
							className={showDropDown ? "active" : undefined}
							onClick={(): void => toggleDropDown()}
							onBlur={(e: React.FocusEvent<HTMLButtonElement>): void => dismissHandler(e)}>
							{showDropDown && (
								<div className="dropdown" style={{ position: "absolute", marginTop: "-30px" }}>
									{mapper}
								</div>
							)}
						</ProfileButton>
					</LoggedInButtons>
				</div>
				{displayData()}
			</ResultsLargeCard>
		</>
	);
}

type collegeData = {
	type: string;
	score: number;
	color: string;
};
