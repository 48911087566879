import { fetcher } from "./Fetcher.services";
import { loan } from "../types/Loan.types";
import { mutator } from "./Mutator.services";

export function fetchLoanList() {
	return fetcher<loan[]>("/loans", "GET");
}

export function addLoan(body?: any | null) {
	return mutator("/loans", "POST", body);
}

export function updateLoan(body?: any | null) {
	return mutator(`/loans/${body.id}`, "PUT", body);
}

export function deleteLoan(body?: any | null) {
	return mutator(`/loans/${body.id}`, "DELETE", body);
}

export function fetchStudentLoans(uid: string) {
	return fetcher<loan[]>(`/loans/student/${uid}`, "GET");
}
