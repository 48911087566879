import styled from "styled-components";
import { device } from "../../util/device";

export const ResultsLargeCard = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	width: 95%;
	margin-left: 2.5%;
	margin-bottom: 20px;
	min-height: 1000px;

	h2 {
		text-align: center;
		margin-top: 10px;
		font-size: 40px;
	}
`;

export const DataGraphs = styled.div`
	background-color: #eeeeee;

	@media only screen and ${device.mobileM} {
		display: flow;

		div {
			display: flow;
		}

		h2 {
			display: flow;
			font-size: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;

		div {
			display: block;
		}

		h2 {
			display: none;
			font-size: 20px;
		}
	}
`;

export const GradeCircle = styled.div`
	background: var(--croi-green);
	border-radius: 50%;
	color: white;
	width: 130px;
	height: 130px;
	text-align: center;
	padding-top: 5%;
	font-size: 80px;
	margin-left: auto;
	margin-right: auto;
`;

export const ResultsTableStyle = styled.div`
	width: 100%;
	padding: 25px;
	border-collapse: separate;
	border-spacing: 0 15px;
`;

export const DataEntry = styled.div`
	background-color: #eeeeee;
	border-radius: 10px;
	padding-top: 10px;
	display: flex;
	margin-bottom: 15px;
	height: 250px;
	justify-content: space-between;
`;

export const DataScore = styled.div`
	width: 12%;

	h3 {
		font-weight: bolder;
		text-align: center;
		font-size: 35px;
	}

	h5 {
		text-align: center;
		font-weight: bold;
		margin-top: 10px;
	}
`;

export const DataLargeInfo = styled.div`
	width: 60%;

	h3 {
		color: var(--croi-green);
		font-weight: bold;
	}
`;

export const DataRemaining = styled.div`
	height: 95%;
	width: 25%;
	border-left: 3px solid lightgrey;

	li {
		list-style: none;
		padding-left: 15px;
	}
`;

export const ResultCircle = styled.div`
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	color: white;
	font-weight: bold;
`;
