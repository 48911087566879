import styled from "styled-components";

export const SettingsWrapper = styled.div`
	margin-left: 100px;
	margin-top: 10px;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const AreaOfChange = styled.div`
	margin-top: 30px;
`;

export const InputField = styled.div`
	width: 20%;
	background: gray;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;

	input {
		width: 400px;
		height: 30px;
		background: gray;
		text-indent: 5px;
		border: none;
		outline: none;
		margin-left: -25%;
	}
`;

export const ButtonOfChange = styled.button`
	width: 200px;
	height: 30px;
	border-radius: 10px;
	background-color: var(--croi-green);
	margin: 5px;
	margin-bottom: 20px;
`;

export const ProfileCircle = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	width: 225px;
	height: 225px;
	border-radius: 50%;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
`;

interface ImageProps {
	imageUrl: string;
}
