import styled from "styled-components";

export const CardSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 20px;
	margin-top: 20px;
	flex-wrap: wrap;
	width: 82vw;
`;

export const LearnCard = styled.div`
	border-radius: 10px;
	background: #F2F2F2;
	width: 350px;
	text-align: center;
    align-items: center;
	height: 450px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 15px;
    display: flex;
    flex-direction: column;

	h2 {
		height: 40px;
        margin-top: 10px;
	}
`;

export const LiteracyButton = styled.button`
	height: 40px;
	width: 275px;
    background: #F2F2F2;
    color: var(--croi-green);
    border-radius: 8px;
    border: var(--croi-green) solid 2px;
`;

export const AidButton = styled.button`
	height: 40px;
	width: 275px;
    background: var(--croi-green);
    color: white;
    border-radius: 8px;
`;

export const ProgressBar = styled.div `
    height: 20px;
    width: 80%;
    background-color: #F2F2F2;
    border-radius: 50px;
    margin: 20px 0;
    border: var(--croi-green) solid 2px;
`;

export const Filler = styled.div`
    height: 100%;
    width: 0;
    background-color: var(--croi-green);
    border-radius: inherit;
    text-align: center;
    transition: width 0.2s ease-in;
`;

export const LearnNavImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	height: 150px;
	width: 150px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 20%;
	margin-bottom: 7px;
	margin-top: 5px;
    background-size: contain;
`;

export const SmallLearnNavImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	height: 100px;
	width: 100px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 20%;
	margin-bottom: 7px;
	margin-top: 15px;
    background-size: contain;
`;

interface ImageProps {
	imageUrl: string;
}

export const BannerSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 20px;
	margin-top: 20px;
	flex-wrap: wrap;
	width: 82vw;
`;

export const LearnBanner = styled.div`
    width: 740px;
    height: 150px;
    background: #F2F2F2;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const ImageSection = styled.div`
    
`;

export const TitleSection = styled.div`
    margin-top: 10px;
`;

export const CroiHowToButton = styled.button`
	height: 40px;
	width: 200px;
    background: #F2F2F2;
    color: var(--croi-green);
    border-radius: 8px;
    border: var(--croi-green) solid 2px;
`;