import styled from "styled-components";

export const CostNavMaster = styled.div`
	width: 90%;
	margin-left: 2vw;
	margin-top: 10px;
`;

export const CostSearchBar = styled.div`
	border-radius: 5px;
	border: black 1px solid;
	width: 85%;
	height: 50px;
	padding: 10px;
	font-size: 20px;
	font-weight: bold;

	input {
		width: 100%;
		outline: none;
	}
`;

export const CostSearchBox = styled.div`
	position: absolute;
	background-color: white;
	border-radius: 10px;
	width: 66%;
	opacity: 90%;
`;

export const CostSearchItem = styled.div`
	text-align: center;
	line-height: 50px;
	margin-top: 5px;
	padding: 5px;
	cursor: pointer;
	min-height: 50px;
	:hover {
		background: #cccccc;
	}
`;

export const YearsBox = styled.select`
	border-radius: 5px;
	border: black 1px solid;
	width: 10%;
	height: 50px;
	padding: 10px;
	font-size: 20px;
	font-weight: bold;
	color: grey;
	background: white;
`;

export const CostNavTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
`;

export const CostNavMid = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const CostDataBox = styled.div`
	border-radius: 5px;
	background: #eeeeee;
	width: 100%;

	h5 {
		padding: 10px;
		border-radius: 5px 5px 0 0;
		background: #d9d9d9;
	}

	h6 {
		font-weight: bold;
		font-size: 18px;
		padding-left: 10px;
		margin-bottom: 0;
	}

	select {
		margin-left: 3%;
		margin-bottom: 15px;
		width: 94%;
		background: white;
		padding: 5px;
		border-radius: 5px;
		border: black 1px solid;
	}

	p {
		margin-left: 3%;
		margin-bottom: 15px;
		width: 94%;
		background: white;
		padding: 5px;
		border-radius: 5px;
		border: black 1px solid;
	}
`;

export const YearlyTotal = styled.div`
	margin-left: 20px;
	margin-top: 20px;

	h6 {
		font-weight: bolder;
		font-size: 20px;
		margin-bottom: 0;
	}

	p {
		color: green;
		font-weight: bolder;
		font-size: 30px;
	}
`;

export const EstimatedTotal = styled.div`
	background-color: var(--croi-green);
	height: 120px;
	color: white;
	font-weight: bold;
	text-align: right;
	padding-right: 40px;
	border-radius: 10px;

	h6 {
		margin-bottom: 0;
		font-weight: bold;
		padding-top: 20px;
		font-size: 17px;
	}

	p {
		font-size: 42px;
		font-weight: bolder;
	}
`;
