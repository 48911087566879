import { atom } from "jotai";

export interface Rankings {
	name: string;
	awards: number;
	field: string;
	cost: number;
	salary: number;
	acceptance: number;
	retention: number;
	graduation: number;
	success: number;
	roi: number;
	environment: string;
	size: string;
	type: string[];
	life: string[];
	studentTeacher: string;
	maleFemale: string;
	score: number;
}
export const rankingsAtom = atom<Rankings[]>([]);

export interface Gradings {
	name: string;
	score: number;
	FOS: string;
	satEng_user: number;
	satEng_college: number;
	satMat_user: number;
	satMat_college: number;
	actScore_user: number;
	actScore_college: number;
	gpa_user: number;
	gpa_college: number;
}
export const gradingsAtom = atom<Gradings[]>([]);
