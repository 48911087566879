import React from "react";
import {
	CollegeBox,
	CollegeListBox,
	LargeCard,
	SearchBar,
	SearchBox,
	SearchInputs,
	SearchItem
} from "./CollegeList.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createCollege, fetchPetersonData } from "../../services/College.services";
import { orderBy } from "lodash";
import { ScaleLoader } from "react-spinners";
import useFetch from "../../hooks/useFetch";
import { queryClient } from "../../services/QueryClient.services";
import { PetersonCollege } from "../../types/PetersonCollege.types";
import { CollegeEntry } from "../../atoms/CollegeEntry";

export default function CollegeList() {
	const { searchResults, setSearchResults } = useFetch();

	const getColleges = useQuery<PetersonCollege[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchPetersonData(),
		refetchInterval: false
	});

	const collegeCreation = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (id: string) => createCollege(id),
		onSuccess: () => {
			console.log("Created College Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	if (getColleges.isLoading || getColleges.isFetching) {
		return (
			<LargeCard className="text-center">
				<h2>My College List</h2>
				<ScaleLoader color="black" loading={getColleges.isLoading || getColleges.isFetching} />
			</LargeCard>
		);
	}

	if (getColleges.isError) {
		return (
			<LargeCard className="text-center">
				<h2>My College List</h2>
				<span>
					Error: {getColleges.error.message} {getColleges.data}
				</span>
			</LargeCard>
		);
	}

	const listRenderer = orderBy(getColleges.data).map((p) => {
		let totalAmount = 0;
		p.financialAwards.map((financialAward) => (totalAmount += financialAward.data.amount));
		return (
			<div key={`{College ${p.id}}`} style={{ display: "flex", justifyContent: "space-evenly" }}>
				<CollegeBox>
					<CollegeEntry college={p}></CollegeEntry>
				</CollegeBox>
			</div>
		);
	});

	const handleItemClick = (collegeId: string) => {
		collegeCreation.mutate(collegeId, {
			onSuccess: () => {
				getColleges.refetch();
			}
		});
		setSearchResults({ ...searchResults, slug: "" });
	};

	return (
		<>
			<LargeCard>
				<h2>My College List</h2>
				<SearchBar>
					<SearchInputs>
						<input
							className="p-2 w-100"
							style={{ border: "1px solid black", borderRadius: "5px" }}
							type="text"
							placeholder={"+ Search Colleges to Add to Your List"}
							value={searchResults.slug}
							onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
						/>
					</SearchInputs>
					{searchResults.slug !== "" && (
						<SearchBox>
							{searchResults.loading && (
								<SearchItem>
									<div style={{ margin: "50px auto 50px auto" }}>
										<ScaleLoader color="black" loading={searchResults.loading} />
									</div>
								</SearchItem>
							)}
							{searchResults.results && (
								<SearchBar>
									{searchResults.results.map((value) => {
										return (
											<SearchItem key={value.id} onClick={() => handleItemClick(value.id)}>
												{value["school.name"]}
											</SearchItem>
										);
									})}
								</SearchBar>
							)}
						</SearchBox>
					)}
				</SearchBar>

				<CollegeListBox>{listRenderer}</CollegeListBox>
			</LargeCard>
		</>
	);
}
