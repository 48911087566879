import React from "react";
import { Circle, Section, SmallCard } from "./ColorKey.styles";

//3AD698 4C8CEB FFEE58 F29914 EB4545

export default function ColorKey() {
	return (
		<SmallCard>
			<h2>KEY</h2>
			<Section>
				<Circle style={{ backgroundColor: "#3AD698" }}>A</Circle>
				<h4>100-80</h4>
			</Section>
			<Section>
				<Circle style={{ backgroundColor: "#4C8CEB" }}>B</Circle>
				<h4>80-60</h4>
			</Section>
			<Section>
				<Circle style={{ backgroundColor: "#FFEE58" }}>C</Circle>
				<h4>60-40</h4>
			</Section>
			<Section>
				<Circle style={{ backgroundColor: "#F29914" }}>D</Circle>
				<h4>40-20</h4>
			</Section>
			<Section>
				<Circle style={{ backgroundColor: "#EB4545" }}>F</Circle>
				<h4>20-0</h4>
			</Section>
		</SmallCard>
	);
}
