import React from "react";
import { Disclaimer, TopWrapper } from "./ListBuilderTool.styles";
import MyAcademics from "../../components/ListBuilderModules/Academics";
import CollegeList from "../../components/ListBuilderModules/CollegeList";
import SchoolGrade from "../../components/ListBuilderModules/SchoolGrade";
import Results from "../../components/ListBuilderModules/Results";
import ToolHeader from "../../components/ToolHeader/ToolHeader";
import NavBar from "../../components/Navbar/Navbar";

export default function ListBuilderTool() {
	return (
		<>
			<NavBar />
			{ToolHeader("List Builder Tool", "https://www.youtube.com/watch?v=KlQ4iMWu_Mc")}
			<Disclaimer>
				Disclaimer: The List Builder Tool provides an initial assessment of a student's likelihood of admission by
				comparing GPA and SAT scores to the national averages for each respective school. It's important to understand
				that college admissions consider a myriad of variables, including extracurricular activities, personal essays,
				recommendations, and more. This tool is designed as a starting point in the college search process and should
				not be the sole determinant in your college selection. Always consult with college admissions counselors, and
				consider a comprehensive view of your qualifications when assessing your chances of admission.
			</Disclaimer>
			<TopWrapper>
				<CollegeList />
				<MyAcademics />
				<SchoolGrade />
			</TopWrapper>
			<Results />
		</>
	);
}
