import React, { useState } from "react";
import { CalcButton, LargeCard, SearchInputs } from "./Academics.styles";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { College } from "../../types/College.types";
import { fetchCollegeList } from "../../services/College.services";
import { gradingsAtom } from "../../services/Store.services";
import { useAtom } from "jotai";

interface NormalizedGrade {
	name: string;
	FOS: string;
	admissionRate: number;
	satEng_college: number;
	satMat_college: number;
	actScore_college: number;
	gpa_college: number;
}

export default function MyAcademics() {
	const [satERW, setSATERW] = useState<number>(0);
	const [satM, setSATM] = useState<number>(0);
	const [act, setACT] = useState<number>(0);
	const [gpa, setGPA] = useState<number>(0);
	const [, setGrading] = useAtom(gradingsAtom);
	const { isLoading, isError, data } = useQuery<College[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchCollegeList()
	});

	const calcScore = (normalizedGrade: NormalizedGrade) => {
		let satEng = 0;
		let satMat = 0;
		let actScore = 0;
		let weight = 20;

		if (normalizedGrade.satEng_college && satERW > 0) {
			satEng = (satERW / normalizedGrade.satEng_college) * 20;
			if (satEng >= 20) {
				satEng = 20;
			} else if (satEng <= 0) {
				satEng = 0;
			}
		} else {
			weight += 10;
		}

		if (normalizedGrade.satMat_college && satM > 0) {
			satMat = (satM / normalizedGrade.satMat_college) * 20;
			if (satMat >= 20) {
				satMat = 20;
			} else if (satMat <= 0) {
				satMat = 0;
			}
		} else {
			weight += 10;
		}

		if (normalizedGrade.actScore_college && act > 0) {
			actScore = (act / normalizedGrade.actScore_college) * 20;
			if (actScore >= 20) {
				actScore = 20;
			} else if (actScore <= 0) {
				actScore = 0;
			}
		} else {
			weight += 10;
		}

		let gpaScore = (gpa / normalizedGrade.gpa_college) * weight;
		let admiss = normalizedGrade.admissionRate * weight;
		if (gpaScore >= weight) {
			gpaScore = weight;
		} else if (gpaScore <= 0) {
			gpaScore = 0;
		}
		if (admiss >= weight) {
			admiss = weight;
		} else if (admiss <= 0) {
			admiss = 0;
		}

		return satEng + satMat + actScore + gpaScore + admiss;
	};

	const calculateGradings = () => {
		const normalizedGrades = data!.map((school) => normalizeGrade(school));
		const gradings = normalizedGrades.map((normalizedGrade: NormalizedGrade) => {
			const score = calcScore(normalizedGrade);

			return {
				name: normalizedGrade.name,
				score,
				FOS: normalizedGrade.FOS,
				admissionRate: normalizedGrade.admissionRate,
				satEng_user: satERW ? satERW : 0,
				satEng_college: normalizedGrade.satEng_college,
				satMat_user: satM ? satM : 0,
				satMat_college: normalizedGrade.satMat_college,
				actScore_user: act ? act : 0,
				actScore_college: normalizedGrade.actScore_college,
				gpa_user: gpa,
				gpa_college: normalizedGrade.gpa_college
			};
		});
		setGrading(gradings);
		window.scrollBy(0, 900 - window.scrollY);
	};

	const normalizeGrade = (school: College) => {
		return {
			name: school.name,
			FOS: school.fieldOfStudyName ? school.fieldOfStudyName : "",
			admissionRate: school.admissionRate,
			satEng_college: school.satEng,
			satMat_college: school.satMat,
			actScore_college: school.actScore,
			gpa_college: school.gpa
		};
	};

	return (
		<>
			<LargeCard className="text-center">
				<h2>My Academics</h2>
				<Row className="m-auto">
					<Col>
						<span style={{ fontWeight: "bold" }}>
							<p>SAT-ERW</p>
							<p>SAT-M</p>
							<p>ACT</p>
							<p>GPA</p>
						</span>
					</Col>
					<Col className="m-auto">
						<SearchInputs>
							<input
								className="p-2 w-50"
								style={{ border: "2px solid black", textAlign: "center" }}
								type="number"
								placeholder={"SAT-ERW"}
								value={satERW}
								onChange={(event) => setSATERW(event.target.valueAsNumber)}
								max={800}
								min={0}
							/>
						</SearchInputs>
						<SearchInputs>
							<input
								className="p-2 w-50"
								style={{ border: "2px solid black", textAlign: "center" }}
								type="number"
								placeholder={"SAT-M"}
								value={satM}
								onChange={(event) => setSATM(event.target.valueAsNumber)}
								max={800}
								min={0}
							/>
						</SearchInputs>
						<SearchInputs>
							<input
								className="p-2 w-50"
								style={{ border: "2px solid black", textAlign: "center" }}
								type="number"
								placeholder={"ACT"}
								value={act}
								onChange={(event) => setACT(event.target.valueAsNumber)}
								max={36}
								min={0}
							/>
						</SearchInputs>
						<SearchInputs>
							<input
								className="p-2 w-50"
								style={{ border: "2px solid black", textAlign: "center" }}
								type="text"
								placeholder={"GPA"}
								onChange={(event) => setGPA(parseFloat(event.target.value))}
								max={4.0}
								min={0}
								step={0.0001}
							/>
						</SearchInputs>
					</Col>
				</Row>
				<CalcButton onClick={() => calculateGradings()} disabled={isLoading || isError}>
					Calculate Scores
				</CalcButton>
			</LargeCard>
		</>
	);
}
