import React, { useState } from "react";
import { AreaOfChange, ButtonOfChange, InputField, ProfileCircle, SettingsWrapper } from "./UserSettings.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchUser, updateUser } from "../../services/User.services";
import { User } from "../../types/User.types";
import { reauthenticateWithCredential, updatePassword, updateProfile } from "firebase/auth";
import { auth } from "../../firebase";
import firebase from "firebase/compat/app";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { queryClient } from "../../services/QueryClient.services";

export default function UserSettings() {
	const user = auth.currentUser;
	//*************************************** Change User Image **************************************//
	const [link, setLink] = useState<string>(user?.photoURL ? user.photoURL : "");
	const [imageOutput, setImageOutput] = useState("");

	const [file, setFile] = useState<File>();

	const changeImgHandler = (input: File) => {
		if (user) {
			const storage = getStorage();
			const storageRef = ref(storage, user.uid);

			// 'file' comes from the Blob or File API
			const uploadTask = uploadBytesResumable(storageRef, input);

			// Listen for state changes, errors, and completion of the upload.
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log("Upload is " + progress + "% done");
					switch (snapshot.state) {
						case "paused":
							console.log("Upload is paused");
							break;
						case "running":
							console.log("Upload is running");
							break;
					}
				},
				(error) => {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					switch (error.code) {
						case "storage/unauthorized":
							// User doesn't have permission to access the object
							break;
						case "storage/canceled":
							// User canceled the upload
							break;

						// ...

						case "storage/unknown":
							// Unknown error occurred, inspect error.serverResponse
							break;
					}
				},
				() => {
					// Upload completed successfully, now we can get the download URL
					getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
						console.log("File available at", downloadURL);
						setLink(downloadURL);
						updateProfile(user, {
							photoURL: downloadURL
						})
							.then(() => {
								setImageOutput("Image Updated Successfully!");
							})
							.catch((error) => {
								setImageOutput(`Error: ${error}`);
							});
					});
				}
			);
		}
	};
	//*************************************** Change User Image **************************************//

	//*************************************** Change User Info ***************************************//
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false
	});

	const changeName = useMutation({
		mutationKey: ["users"],
		mutationFn: (body: any) => updateUser("", body),
		onSuccess: () => {
			console.log("Changed Name Successfully!");
			return queryClient.invalidateQueries(["users"]);
		}
	});

	if (changeName.isError) {
		return <span>Error: Did not mutate correctly</span>;
	}

	if (getUser.isError) {
		return (
			<span>
				Error: {getUser.error.message} {getUser.data}
			</span>
		);
	}

	const firstNameChanger = (input: string) => {
		const body = {
			firstName: input
		};

		changeName.mutate(body);
	};

	const lastNameChanger = (input: string) => {
		const body = {
			lastName: input
		};

		changeName.mutate(body);
	};
	//*************************************** Change User Info ***************************************//

	//*************************************** Change Password ****************************************//
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [rePass, setRePass] = useState("");
	const [outputMessage, setOutputMessage] = useState("");
	const [message, setMessage] = useState("");

	const passwordResetHandler = () => {
		const email = user?.email;

		//Checks if user exists
		if (email && user) {
			//Old password & email
			const credential = firebase.auth.EmailAuthProvider.credential(email, oldPass);

			//Check old password
			reauthenticateWithCredential(user, credential)
				.then(() => {
					if (newPass === rePass) {
						updatePassword(user, newPass)
							.then(() => {
								setOutputMessage("Password Change Successful!");
							})
							.catch((error) => {
								//Update Password fail
								setOutputMessage(`Error: ${error}`);
							});
					} else {
						//New password entered incorrectly
						setOutputMessage("Error: The new password mismatch.");
					}
				})
				.catch((error) => {
					//Old password check fail
					setOutputMessage(`Error: ${error}`);
				});
		} else {
			//User does not exist
			setOutputMessage("Error: Email or User not verified.");
		}
	};
	//*************************************** Change Password ****************************************//

	return (
		<>
			<SettingsWrapper>
				<AreaOfChange>
					<h3>Change Your Profile Picture</h3>
					<div>{imageOutput}</div>
					<ProfileCircle imageUrl={link} />
					<input
						type={"file"}
						accept="image/*"
						onChange={(e) => {
							const files = e.target.files;

							if (files && files.length > 0) {
								setFile(files[0]);
							}
						}}
					/>
					<br />
				</AreaOfChange>

				<AreaOfChange>
					<h3>Change Your Name</h3>
					<div>
						Example: Hello {getUser.data?.data.firstName} {getUser.data?.data.lastName}!
					</div>
					<InputField>
						<input
							type={"text"}
							placeholder={"Enter First Name"}
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</InputField>
					<InputField>
						<input
							type={"text"}
							placeholder={"Enter Last Name"}
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</InputField>
				</AreaOfChange>

				<AreaOfChange>
					<h3>Change Your Password</h3>
					<div>{outputMessage}</div>
					<InputField>
						<input
							type={"text"}
							placeholder={"Enter old password"}
							value={oldPass}
							onChange={(e) => setOldPass(e.target.value)}
						/>
					</InputField>
					<InputField>
						<input
							type={"text"}
							placeholder={"Enter new password"}
							value={newPass}
							onChange={(e) => setNewPass(e.target.value)}
						/>
					</InputField>
					<InputField>
						<input
							type={"text"}
							placeholder={"Re-enter new password"}
							value={rePass}
							onChange={(e) => setRePass(e.target.value)}
						/>
					</InputField>
					<div style={{ marginTop: "10px", marginBottom: "10px", color: "red" }}>{message}</div>
					<ButtonOfChange
						onClick={() => {
							setMessage("Updated Profile!");
							if (file && link != auth.currentUser?.photoURL) {
								file ? changeImgHandler(file) : console.log("There is no file");
								console.log("File changed");
							}
							if (oldPass != "") {
								if (newPass.length > 1) {
									if (newPass != oldPass) {
										passwordResetHandler();
										console.log("Pass changed");
									} else {
										setMessage("The new password is the same as the old.");
									}
								} else {
									setMessage("New password is not long enough.");
								}
							}
							if (firstName != "") {
								if (firstName != getUser.data?.data.firstName) {
									firstNameChanger(firstName);
									console.log("firstName changed");
								} else {
									setMessage("First Name did not change.");
								}
							}
							if (lastName != "") {
								if (lastName != getUser.data?.data.lastName) {
									lastNameChanger(lastName);
									console.log("lastName changed");
								} else {
									setMessage("Last Name did not change.");
								}
							}
						}}>
						Update Profile
					</ButtonOfChange>
				</AreaOfChange>
			</SettingsWrapper>
		</>
	);
}
