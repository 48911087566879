import React, { useState } from "react";
import { Criteria, CriteriaBox, CriteriaContainer } from "./MyCriteria.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { User, userPreferences } from "../../types/User.types";
import { changePreferences, fetchUser } from "../../services/User.services";
import Select, { MultiValue } from "react-select";
import { queryClient } from "../../services/QueryClient.services";

interface valueLabel {
	value: string;
	label: string;
}

const lifeChoices: valueLabel[] = [
	{
		value: "",
		label: "---"
	},
	{
		value: "Band",
		label: "Band"
	},
	{
		value: "Campus Ministries",
		label: "Campus Ministries"
	},
	{
		value: "Campus Newspaper",
		label: "Campus Newspaper"
	},
	{
		value: "Chorus Group",
		label: "Chorus Group"
	},
	{
		value: "Concert Band",
		label: "Concert Band"
	},
	{
		value: "Dance",
		label: "Dance"
	},
	{
		value: "Drama/Theater Group",
		label: "Drama/Theater Group"
	},
	{
		value: "Film Society",
		label: "Film Society"
	},
	{
		value: "Greek Life",
		label: "Greek Life"
	},
	{
		value: "International Student Organization",
		label: "International Student Organization"
	},
	{
		value: "Jazz Band",
		label: "Jazz Band"
	},
	{
		value: "Literary Magazine",
		label: "Literary Magazine"
	},
	{
		value: "Model UN",
		label: "Model UN"
	},
	{
		value: "Musical Theater",
		label: "Musical Theater"
	},
	{
		value: "Music Ensemble",
		label: "Music Ensemble"
	},
	{
		value: "Opera",
		label: "Opera"
	},
	{
		value: "Pep Band",
		label: "Pep Band"
	},
	{
		value: "Student Government",
		label: "Student Government"
	},
	{
		value: "Student Radio",
		label: "Student Radio"
	},
	{
		value: "Student TV",
		label: "Student TV"
	},
	{
		value: "Symphony Orchestra",
		label: "Symphony Orchestra"
	},
	{
		value: "Yearbook Club",
		label: "Yearbook Club"
	}
];

const typeChoices: valueLabel[] = [
	{
		value: "Public",
		label: "Public"
	},
	{
		value: "Private",
		label: "Private"
	},
	{
		value: "Catholic",
		label: "Catholic"
	},
	{
		value: "Jewish",
		label: "Jewish"
	},
	{
		value: "Protestant",
		label: "Protestant"
	}
];

export default function MyCriteria() {
	const [environment, setEnv] = useState("");
	const [size, setSize] = useState("");
	const [type, setType] = useState<string[]>([]);
	const [life, setLife] = useState<string[]>([]);

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false
	});

	const setPreferences = useMutation({
		mutationKey: ["preferences"],
		mutationFn: (body: any) => changePreferences(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
		}
	});

	const envSetter = (val: string) => {
		setEnv(val);
		updatePreferences();
	};

	const sizeSetter = (val: string) => {
		setSize(val);
		updatePreferences();
	};

	const typeSetter = (vals: MultiValue<valueLabel>) => {
		const temp: string[] = [];
		let religion = "";
		let colType = "";

		for (const value of vals) {
			if (value.value == "Private" || value.value == "Public") {
				colType = value.value;
			} else {
				religion = value.value;
			}
		}

		if (religion.length > 0) {
			temp.push(religion);
		}
		if (colType.length > 0) {
			temp.push(colType);
		}

		setType(temp);
		updatePreferences();
	};

	const lifeSetter = (vals: MultiValue<valueLabel>) => {
		const temp: string[] = [];

		for (const value of vals) {
			temp.push(value.value);
		}

		setLife(temp);
		updatePreferences();
	};

	const updatePreferences = () => {
		const pref: userPreferences = {
			acceptance: getUser.data!.data.pref.salary,
			graduation: getUser.data!.data.pref.graduation,
			retention: getUser.data!.data.pref.retention,
			salary: getUser.data!.data.pref.salary,
			success: getUser.data!.data.pref.success,
			cost: getUser.data!.data.pref.cost,
			campusLife: life.length > 0 ? life : getUser.data!.data.pref.campusLife,
			environment: environment.length > 0 ? environment : getUser.data!.data.pref.environment,
			size: size.length > 0 ? size : getUser.data!.data.pref.size,
			type: type.length > 0 ? type : getUser.data!.data.pref.type
		};

		const body = {
			pref: pref
		};

		setPreferences.mutate(body);
	};

	const getLifeList = () => {
		const list: valueLabel[] = [];

		for (const item of life) {
			list.push({
				value: item,
				label: item
			});
		}

		return list;
	};

	const getTypeList = () => {
		const list: valueLabel[] = [];

		for (const item of type) {
			list.push({
				value: item,
				label: item
			});
		}

		return list;
	};

	return (
		<CriteriaContainer>
			<h2>My Criteria</h2>
			<p><em>Enter your criteria to tailor the calculations to your ideal college experience.</em></p>
			<CriteriaBox>
				<Criteria>
					<p>Environment:</p>
					<select onChange={(e) => envSetter(e.target.value)}>
						<option></option>
						<option>Rural</option>
						<option>Small</option>
						<option>Suburban</option>
						<option>Urban</option>
					</select>
				</Criteria>
				<Criteria>
					<p>Size:</p>
					<select onChange={(e) => sizeSetter(e.target.value)}>
						<option></option>
						<option>Small (&lt;5,000)</option>
						<option>Medium (5,000-15,000)</option>
						<option>Large (&gt;15,000)</option>
					</select>
				</Criteria>
				<Criteria>
					<p>School Type:</p>
					<Select
						value={getTypeList()}
						onChange={(val) => typeSetter(val)}
						getOptionLabel={(val: valueLabel) => val.label}
						getOptionValue={(val: valueLabel) => val.value}
						options={typeChoices}
						isClearable={true}
						backspaceRemovesValue={true}
						isMulti={true}
						className={"select-container"}
						placeholder={"Select all that apply..."}
					/>
				</Criteria>
				<Criteria>
					<p>Campus Life:</p>
					<Select
						value={getLifeList()}
						onChange={(val) => lifeSetter(val)}
						getOptionLabel={(val: valueLabel) => val.label}
						getOptionValue={(val: valueLabel) => val.value}
						options={lifeChoices}
						isClearable={true}
						backspaceRemovesValue={true}
						isMulti={true}
						className={"select-container"}
						placeholder={"Select all that apply..."}
					/>
				</Criteria>
			</CriteriaBox>
		</CriteriaContainer>
	);
}
