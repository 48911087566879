import { BestAllBachelor, BestByMajor, BestTwoYear, CommonJobs, MajorPayBack } from "../types/Salary.types";
import { fetcher } from "./Fetcher.services";

export function fetchBachelors() {
	return fetcher<BestAllBachelor[]>("/salary/allBachelor", "GET");
}

export function fetchMajors() {
	return fetcher<BestByMajor[]>("/salary/bestMajor", "GET");
}

export function fetchCommunities() {
	return fetcher<BestTwoYear[]>("/salary/bestCommunity", "GET");
}

export function fetchCommonJobs() {
	return fetcher<CommonJobs[]>("/salary/commonJobs", "GET");
}

export function fetchPayBack() {
	return fetcher<MajorPayBack[]>("/salary/payBack", "GET");
}
