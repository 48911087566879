import React from "react";
import { CollegeBox, LargeCard, SearchBar, SearchBox, SearchInputs, SearchItem } from "./CollegeList.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createCollege, fetchCollegeList } from "../../services/College.services";
import { College } from "../../types/College.types";
import { orderBy } from "lodash";
// import { CroiModal } from "../../atoms/CroiModal";
import { ScaleLoader } from "react-spinners";
import useFetch from "../../hooks/useFetch";
import { currencyFormatter } from "../../util/currencyFormatter";
import { queryClient } from "../../services/QueryClient.services";

export default function CollegeList() {
	const { searchResults, setSearchResults } = useFetch();

	const getColleges = useQuery<College[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchCollegeList(),
		refetchInterval: false
	});

	const collegeCreation = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (id: string) => createCollege(id),
		onSuccess: () => {
			console.log("Created College Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	if (getColleges.isLoading || getColleges.isFetching) {
		return (
			<LargeCard className="text-center">
				<h2>My College List</h2>
				<ScaleLoader color="black" loading={getColleges.isLoading || getColleges.isFetching} />
			</LargeCard>
		);
	}

	if (getColleges.isError) {
		return (
			<LargeCard className="text-center">
				<h2>My College List</h2>
				<span>
					Error: {getColleges.error.message} {getColleges.data}
				</span>
			</LargeCard>
		);
	}

	/*
	const sumAwards = () => {
		let sum = 0
		return data.map((college) => {
			sum += college.financialAwards
		})
	}
	*/
	const listRenderer = orderBy(getColleges.data).map((p, index) => {
		let totalAmount = 0;
		p.financialAwards.map((financialAward) => (totalAmount += financialAward.data.amount));
		console.log(p);
		return (
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
				<h4>{index + 1}</h4>
				<CollegeBox>
					<h3>{p.name}</h3>
					<p>Financial Award = {currencyFormatter.format(totalAmount)}</p>
					<p>Field of Study: {p.fieldOfStudyName ?? ""}</p>
				</CollegeBox>
				<div>{/*<CroiModal college={p} />*/}</div>
			</div>
		);
	});

	const handleItemClick = (collegeId: string) => {
		collegeCreation.mutate(collegeId, {
			onSuccess: () => {
				getColleges.refetch();
			}
		});
		setSearchResults({ ...searchResults, slug: "" });
	};

	// Need to rework this make entire section onBlur - CFC 7/19/23
	/*
	const handleSearchBarBlur = () => {
		setSearchResults({ ...searchResults, slug: "" });
	};
	*/
	return (
		<>
			<LargeCard className="text-center">
				<h2>Add Colleges</h2>
				<SearchBar>
					<SearchInputs>
						<input
							className="p-2 w-100"
							style={{ border: "1px solid black", borderRadius: "5px" }}
							type="text"
							placeholder={"+ Search Colleges to Add to Your List"}
							value={searchResults.slug}
							onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
							//onBlur={handleSearchBarBlur}
						/>
					</SearchInputs>
					{searchResults.slug !== "" && (
						<SearchBox>
							{searchResults.loading && (
								<SearchItem>
									<div style={{ margin: "50px auto 50px auto" }}>
										<ScaleLoader color="black" loading={searchResults.loading} />
									</div>
								</SearchItem>
							)}
							{searchResults.results && (
								<SearchBar>
									{searchResults.results.map((value) => {
										return (
											<SearchItem key={value.id} onClick={() => handleItemClick(value.id)}>
												{value["school.name"]}
											</SearchItem>
										);
									})}
								</SearchBar>
							)}
						</SearchBox>
					)}
				</SearchBar>
				{listRenderer}
			</LargeCard>
		</>
	);
}
