import axios from "axios";

export const usersApi = axios.create({
	baseURL: "https://us-central1-croi-d96ed.cloudfunctions.net/users-api"
});

// CFC 3/14/23 - change env variables to match name
export const schoolApi = axios.create({
	baseURL: process.env.REACT_APP_USER_API_URL
});
/*
 https://api.data.gov/ed/collegescorecard/v1/schools.json?
 api_key=&school.name=ohio&fields=id,school.name,latest.cost.
 avg_net_price.overall,latest.student.retention_rate.four_year.
 full_time,latest.student.retention_rate.lt_four_year.full_time,
 latest.admissions.sat_scores.average.overall,latest.admissions.
 admission_rate.overall,latest.earnings.7_yrs_after_entry.mean_earnings
 */
