const preferencesData = [
	{
		id: 1,
		name: "Cost",
		description: "This will favor schools with a lower cost",
		position: 3
	},
	{
		id: 2,
		name: "Salary After Graduation",
		description: "This will favor schools with a higher salary after graduation",
		position: 3
	},
	{
		id: 3,
		name: "Selectivity",
		description: "This will favor schools with a lower acceptance rate",
		position: 3
	},
	{
		id: 4,
		name: "Student Enjoyment",
		description: "This will favor schools with a higher freshman retention rate",
		position: 3
	},
	{
		id: 5,
		name: "Course Rigor",
		description: "This will favor schools with a higher graduation rate",
		position: 3
	},
	{
		id: 6,
		name: "Job Success After Graduation",
		description: "This will favor schools with higher job post-graduation rate",
		position: 3
	}
];

export default preferencesData;
