import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import { deleteUser } from "../services/User.services";
import { auth } from "../firebase";
import { queryClient } from "../services/QueryClient.services";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TableRow = ({ id, data }: TableRowProps) => {
	const [show, setShow] = useState(false);

	const delUser = useMutation({
		mutationKey: ["usersList"],
		mutationFn: (body: any) => deleteUser(body),
		onSuccess: () => {
			console.log("User Successfully Deleted!");
			return queryClient.invalidateQueries(["users"]);
		}
	});

	const deleter = (id: string) => {
		const body = {
			adminID: auth.currentUser?.uid,
			userID: id
		};
		setShow(false);
		delUser.mutate(body);
	};

	const modalView = () => {
		return (
			<Modal show={show} onHide={() => setShow(!show)}>
				<Modal.Title style={{ textAlign: "center" }}>ARE YOU SURE?</Modal.Title>
				<div style={{ display: "flex", justifyContent: "space-evenly", height: "100px" }}>
					<DeleteButton onClick={() => deleter(id)} style={{ width: "40%" }}>
						Yes, delete user!
					</DeleteButton>
					<EditButton onClick={() => setShow(false)} style={{ width: "40%" }}>
						No, take me back!
					</EditButton>
				</div>
			</Modal>
		);
	};

	const navigate = useNavigate();

	return (
		<tr>
			{data.map((item) => {
				return <td key={item}>{item}</td>;
			})}
			<td>
				{modalView()}
				<DeleteButton onClick={() => setShow(true)}>Delete</DeleteButton>
				<ViewButton onClick={() => navigate(`/student/${id}`, { state: id })}>View</ViewButton>
			</td>
		</tr>
	);
};

const EditButton = styled.button`
	background-color: #3ad698;
	color: #fff;
	border-radius: 10px;
	width: 75px;
	margin: 10px;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;

const DeleteButton = styled.button`
	background-color: #d6533a;
	color: #fff;
	border-radius: 10px;
	width: 75px;
	margin: 10px 0;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;

const ViewButton = styled.button`
	background-color: black;
	color: #fff;
	border-radius: 10px;
	width: 75px;
	margin: 10px;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;

type TableRowProps = {
	id: string;
	data: string[];
};

export default TableRow;
