import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background: transparent;
    font-family: Aleo,serif;
  }

  title {
    background: transparent;
    font-family: Aleo,serif;
  }

  h1 {
    background: transparent;
    font-family: Aleo,serif;
  }

  :root {
    --croi-green: #3ad698;
    --croi-orange: #d6533a;
    --croi-gray: #626c6a;
    --croi-light-gray: #878787;
  }
  
  @font-face {
    font-family: "aleo-reg";
    src: local("Aleo");
  }
`;

export default GlobalStyle;
