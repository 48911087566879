import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../App.css";
import { auth } from "../firebase";
import "../index.css";
import AuthRoute from "./AuthRoute";
import Landing from "../pages/Landing/Landing";
import Login from "./Login/Login";
import About from "../pages/About/About";
import CustomerService from "../pages/CustomerService/CustomerService";
import Contact from "../pages/Contact/Contact";
import { School } from "../pages/School/School";
import Signup from "./Signup/Signup";
import BlogPage from "../pages/Blog";
import GlobalStyle from "../styles/GlobalStyles";
import CollegeDecisionTool from "../pages/CollegeDecisionTool/CollegeDecisionTool";
import { QueryClientProvider } from "@tanstack/react-query";
import UserSettings from "../pages/UserSettings/UserSettings";
import { AdminPage } from "../pages/AdminPage/AdminPage";
import StudentLoan from "../pages/StudentLoan/StudentLoan";
import PageNotFound from "../pages/PageNotFound";
import { queryClient } from "../services/QueryClient.services";
import Pricing from "../pages/Pricing/Pricing";
import ListBuilderTool from "../pages/ListBuilderTool/ListBuilderTool";
import AdminView from "../pages/AdminPage/AdminView";
import LoginFromLW from "./Login/LoginFromLW";
import SalaryInsights from "../pages/SalaryInsights/SalaryInsights";
import CostNav from "../pages/CostNav/CostNav";
import UserTools from "../pages/UserTools/UserTools";
import Counselors from "../pages/Counselors/Counselors";

function App() {
	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				//squeryClient = queryClientLoader();
				console.log("User detected");
			} else {
				console.log("No User");
			}
		});
	});

	return (
		<QueryClientProvider client={queryClient}>
			<GlobalStyle />
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<PageNotFound />} />
					<Route path="/" element={<Landing />} />
					<Route path="/login" element={<Login />} />
					<Route path="/loginLW" element={<LoginFromLW />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/about" element={<About />} />
					<Route path="/blog" element={<BlogPage />} />
					<Route path="/customerservice" element={<CustomerService />} />
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/listbuildertool" element={<ListBuilderTool />} />
					<Route path="/counselors" element={<Counselors />} />
					<Route
						path="/admin"
						element={
							<AuthRoute>
								<AdminPage />
							</AuthRoute>
						}
					/>
					<Route
						path="/decision"
						element={
							<AuthRoute>
								<CollegeDecisionTool />
							</AuthRoute>
						}
					/>
					<Route
						path="/settings"
						element={
							<AuthRoute>
								<UserSettings />
							</AuthRoute>
						}
					/>
					<Route
						path="/loan"
						element={
							<AuthRoute>
								<StudentLoan />
							</AuthRoute>
						}
					/>
					<Route
						path="/salary"
						element={
							<AuthRoute>
								<SalaryInsights />
							</AuthRoute>
						}
					/>
					<Route
						path="/costNav"
						element={
							<AuthRoute>
								<CostNav />
							</AuthRoute>
						}
					/>
					<Route
						path={"/userTools"}
						element={
							<AuthRoute>
								<UserTools />
							</AuthRoute>
						}
					/>
					<Route path="/school/:id" element={<School />} />
					<Route path="/student/:id" element={<AdminView />} />
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default App;
