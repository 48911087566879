import React from "react";
import TableHeadItem from "../../atoms/TableHeadItem";
import TableRow from "../../atoms/TableRow";
import { StyledTable } from "./Table.styles";

const Table = ({ tHead, tBody }: TableProps) => {
	return (
		<StyledTable>
			<thead>
				<tr>
					{tHead.map((h) => {
						return <TableHeadItem key={h} item={h} />;
					})}
				</tr>
			</thead>
			<tbody>
				{tBody.map((item) => {
					return <TableRow key={item.id} id={item.id} data={item.items} />;
				})}
			</tbody>
		</StyledTable>
	);
};

type TableProps = {
	tHead: string[];
	tBody: TableRowItem[];
};

type TableRowItem = {
	id: string;
	items: string[];
};

export default Table;
