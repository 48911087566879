import styled from "styled-components";

export const SalaryMaster = styled.div`
	margin-top: 25px;
	margin-left: 2.5%;
`;

export const SalarySearch = styled.div`
	background-color: #f0f0f0;
	width: 95%;
	margin-bottom: 25px;
	height: 40px;
	padding-left: 20px;
	border-radius: 10px;

	input {
		background: transparent;
		outline: none;
	}
`;

export const SalarySearchBox = styled.div`
	position: absolute;
	background-color: white;
	border-radius: 10px;
	width: 85%;
`;

export const SalarySearchBar = styled.div`
	margin-bottom: 15px;
	width: 82%;
	max-height: 200px;
	overflow: hidden;
	overflow-y: auto;
	margin-left: 2%;
	padding-right: 2%;
`;

export const SalarySearchItem = styled.div`
	line-height: 50px;
	margin-top: 5px;
	border-radius: 5px;
	padding: 5px;
	cursor: pointer;
	min-height: 50px;

	:hover {
		background: #cccccc;
	}
`;

export const CurrentCollege = styled.div`
	background-color: #f0f0f0;
	padding: 15px;
	display: flex;
	border-radius: 10px;
	width: 95%;
	min-height: 470px;

	h3 {
		font-weight: bolder;
		text-align: center;
	}

	h5 {
		color: gray;
		font-weight: bold;
		text-align: center;
	}

	h6 {
		font-size: 18px;
		text-align: center;
	}

	table {
		height: 40px;
		font-size: 1.5em;
		width: 75%;

		td {
			padding: 15px;
			text-align: center;
			width: 23%;
		}

		th {
			text-align: center;
		}

		tbody {
			background-color: #d9d9d9;
		}
	}
`;

export const SalaryDataBoxes = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-bottom: 15px;
	width: 95%;
`;
