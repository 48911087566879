import React from "react";
import { DataGraphs, ResultsLargeCard } from "./Results.styles";
import { useQuery } from "@tanstack/react-query";
import { fetchPetersonData } from "../../services/College.services";
import { CroiBarChartMoney, CroiBarChartPercent } from "../CroiBarChart";
import { ScaleLoader } from "react-spinners";
import ResultsTable from "./ResultsTable";
import { PetersonCollege } from "../../types/PetersonCollege.types";

export default function Results() {
	const getColleges = useQuery<PetersonCollege[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchPetersonData()
	});

	if (getColleges.isError) {
		return (
			<ResultsLargeCard>
				<h2>Results</h2>
				<span>
					Error: {getColleges.error.message} {getColleges.data}
				</span>
			</ResultsLargeCard>
		);
	}

	return (
		<ResultsLargeCard>
			{getColleges.isFetching || getColleges.isLoading ? (
				<>
					<div style={{ marginLeft: "49%" }}>
						<ScaleLoader color="black" loading={true} />
					</div>
				</>
			) : (
				<>
					{getColleges.data ? (
						<ResultsTable />
					) : (
						<p style={{ textAlign: "center", paddingBottom: "10px" }}>No Current Rankings</p>
					)}

					<h2>Data Breakdown</h2>
					{getColleges.data.length ? (
						<DataGraphs>
							<h2>For better results, view on computer</h2>
							<div>
								<h4>Cost</h4>
								<CroiBarChartMoney data={getColleges.data} y={"costPerYear"} x={"name"} varName={"Cost Per Year"} />
							</div>

							<div>
								<h4>Salary After Graduation</h4>
								<CroiBarChartMoney data={getColleges.data} y={"expectedSalary"} x={"name"} varName={"Salary"} />
							</div>

							<div>
								<h4>Admission Rate</h4>
								<CroiBarChartPercent
									data={getColleges.data}
									y={"acceptanceRate"}
									x={"name"}
									varName={"Admission Rate"}
								/>
							</div>

							<div>
								<h4>Retention Rate</h4>
								<CroiBarChartPercent
									data={getColleges.data}
									y={"freshmanRetentionRate"}
									x={"name"}
									varName={"Retention Rate"}
								/>
							</div>

							<div>
								<h4>Completion Rate</h4>
								<CroiBarChartPercent
									data={getColleges.data}
									y={"graduationRate" as "Completion Rate"}
									x={"name"}
									varName={"Completion Rate"}
								/>
							</div>

							<div>
								<h4>Job Matriculation Rate</h4>
								<CroiBarChartPercent
									data={getColleges.data}
									y={"jobSuccessRate"}
									x={"name"}
									varName={"Job Success Rate"}
								/>
							</div>
						</DataGraphs>
					) : (
						<p>No Data</p>
					)}
				</>
			)}
		</ResultsLargeCard>
	);
}
