import styled from "styled-components";

export const StyledTable = styled.table`
	width: 95%;

	thead {
		background-color: var(--croi-green-color);
		color: black;
		text-align: center;
		font-weight: bold;
	}

	td {
		text-align: center;
		padding: 10px;
	}

	tr {
		text-align: center;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}
`;
