import styled from "styled-components";

export const CriteriaContainer = styled.div`
	text-align: center;
	margin-top: 20px;

	h2 {
		height: 40px;
		font-weight: bold;
	}
`;

export const CriteriaBox = styled.div`
	background-color: #f0f0f0;
	width: 33vw;
	height: 200px;
`;

export const Criteria = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	padding-top: 5px;

	p {
		font-size: 18px;
		padding-right: 15px;
		width: 40%;
		text-align: right;
	}

	select {
		width: 50%;
		height: 30px;
		text-align: left;
		border: black solid 1px;
		border-radius: 5px;
		margin-right: 10%;
		padding-left: 10px;
	}

	.select-container {
		width: 50%;
		height: 30px;
		border-radius: 5px;
		margin-right: 10%;
	}
`;
