import { onAuthStateChanged } from "firebase/auth";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { auth } from "../firebase";

const AuthRoute = (props: { children: ReactElement }) => {
	const { children } = props;
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const navigateCallback = useCallback(navigate, [navigate]);

	useEffect(() => {
		const authCheck = onAuthStateChanged(auth, (user) => {
			if (user) {
				setLoading(false);
			} else {
				console.log("unathorized");
				navigateCallback("/login");
			}
		});

		authCheck();
	}, [navigateCallback]);

	if (loading)
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					textAlign: "center"
				}}>
				<ScaleLoader color="white" loading={loading} />
			</div>
		);
	return <div>{children}</div>;
};

export default AuthRoute;
