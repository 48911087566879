import dashLogo from "../atoms/sidebarLogos/DashboardLogo.svg";
import learnLogo from "../atoms/sidebarLogos/Learn.svg";
import collegeLogo from "../atoms/sidebarLogos/CollegeLogo.svg";
import loanLogo from "../atoms/sidebarLogos/Finance.svg";
import salaryLogo from "../atoms/sidebarLogos/Analyze.svg";
import matchLogo from "../atoms/sidebarLogos/Match.svg";

/*
 * navTitle: The title that goes above the navbar
 * navSection: The navbar section. Leave blank for no section
 * sideTitle: The sidebar option
 * subSection: If the sideTitle is meant to be a list
 * image: The image shown on sidebar
 * alt: The alt for the image
 */
export const sidebarOptions = [
	{
		navTitle: "Dashboard",
		navSection: "",
		sideTitle: "Dashboard",
		subSection: "",
		image: dashLogo,
		alt: "Dash Logo"
	},
	{
		navTitle: "croiLearn",
		navSection: "Learn",
		sideTitle: "Learn",
		subSection: "",
		image: learnLogo,
		alt: "Learn Logo"
	},
	{
		navTitle: "Financial Aid Match",
		navSection: "Match",
		sideTitle: "Match",
		subSection: "",
		image: matchLogo,
		alt: "Match Logo"
	},
	{
		navTitle: "Cost Navigator",
		navSection: "Analyze",
		sideTitle: "Analyze-Cost",
		subSection: "Cost Navigator",
		image: salaryLogo,
		alt: "Salary Logo"
	},
	{
		navTitle: "Salary Insights",
		navSection: "Analyze",
		sideTitle: "Analyze-Salary",
		subSection: "Salary Insights",
		image: salaryLogo,
		alt: "Salary Logo"
	},
	{
		navTitle: "College Decision Tool",
		navSection: "Decide",
		sideTitle: "Decide",
		subSection: "",
		image: collegeLogo,
		alt: "College Logo"
	},
	{
		navTitle: "Student Loan Tool",
		navSection: "Finance",
		sideTitle: "Finance",
		subSection: "",
		image: loanLogo,
		alt: "Loan Logo"
	},
	{
		navTitle: "User Settings",
		navSection: "",
		sideTitle: "Settings",
		subSection: "",
		image: null,
		alt: ""
	},
	{
		navTitle: "Admin Panel",
		navSection: "",
		sideTitle: "Admin",
		subSection: "",
		image: null,
		alt: ""
	}
];
