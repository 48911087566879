import { fetcher } from "./Fetcher.services";
import { User } from "../types/User.types";
import { mutator } from "./Mutator.services";
import { fileMutator } from "./FileMutator.services";

// import { fetcherBody } from "./FetcherBody";

export function fetchUser(userID: string) {
	return fetcher<User>("/users/" + userID, "GET");
}

export function fetchUsers(lower: number, upper: number) {
	return fetcher<User[]>(`/users/admin/${lower}/${upper}`, "GET");
}

export function deleteUser(body?: any | null) {
	return mutator("/users/admin/", "DELETE", body);
}

export function updateUser(userID: string, body?: any | null) {
	return mutator("/users/" + userID, "PUT", body);
}

export function addUser(body?: any | null) {
	return mutator("/users/admin", "POST", body);
}

export function addUsers(body?: any | null) {
	return fileMutator("/users/admin/bulk", "POST", body);
}

export function fetchStudent(uid: string) {
	return fetcher<User>(`users/student/${uid}`, "GET");
}

export function changePreferences(body?: any | null) {
	return mutator("/users/preferences", "PUT", body);
}

export function organizeAdminList(body?: any | null) {
	return mutator("users/admin", "PUT", body);
}

/*
 * Change Name
 * Change Profile Picture
 *
 */
