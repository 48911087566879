import styled from "styled-components";
import { device } from "../../util/device";

export const Topper = styled.div`
	border-bottom: solid var(--croi-green) 10px;
	text-align: center;
	width: 80vw;
	display: flex;
	justify-content: space-between;
	font-weight: bold;

	@media only screen and ${device.mobileM} {
		font-size: 5.5vw;
		height: 70px;
		margin: 50px auto 40px;

		b {
			margin-top: -40px;
			position: absolute;
		}
	}

	@media only screen and ${device.tablet} {
		font-size: 2.5vw;
		height: 85px;
		margin: 30px auto 40px;

		b {
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}
`;
