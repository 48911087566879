import styled from "styled-components";

export const HeaderBlock = styled.div`
	text-align: center;
	border-bottom: var(--croi-green) solid 10px;
	width: 80vw;
	display: block;
	margin: 40px auto;
	font-size: 40px;
`;

export const AdminColumn = styled.div`
	width: 25vw;
`;

export const ViewLargeContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 20px;
`;

export const UserBlock = styled.div`
	background-color: #eeeeee;
	margin-bottom: 10px;
	height: 100px;
	border-radius: 15px;
	text-align: center;
	padding-top: 10px;
`;

export const LearnBlock = styled.div`
	background-color: #eeeeee;
	height: 700px;
	width: 100%;
	text-align: center;
	border-radius: 15px;

	div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	h2 {
		padding-top: 10px;
	}
`;

export const CollegeBlock = styled.div`
	background-color: #eeeeee;
	width: 25vw;
	border-radius: 15px;
	text-align: center;
	padding-top: 10px;
`;

export const DecisionScores = styled.div`
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	padding-top: 5px;
	padding-bottom: 15px;

	div {
		background-color: #eeeeee;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		margin-top: 10px;
		height: 75px;
		padding-top: 5px;
	}
`;

export const PreferenceScores = styled.div`
	width: 75%;
	margin-left: auto;
	margin-right: auto;

	p {
		background-color: white;
	}
`;

export const LoanBlock = styled.div`
	background-color: #eeeeee;
	width: 25vw;
	border-radius: 15px;
	text-align: center;
	padding-top: 10px;
`;

export const LoanList = styled.div`
	background-color: white;
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	overflow-y: scroll;

	li {
		list-style-position: inside;
		text-align: left;
		margin-left: 20%;
	}

	p {
		margin-bottom: 0;
		border: var(--croi-green) 2px solid;
	}

	h4 {
		margin-top: 5px;
	}
`;
