import React from "react";
import {
	AMCards,
	AMImage,
	AMSection,
	CroiCheckSection,
	CroiCheckSectionHeading,
	DescriptionSection,
	DescriptionSectionHeading,
	FiveStepCard,
	FiveStepNumber,
	FiveStepSection,
	GraphSection,
	HeaderGraphSection,
	ImageBackground,
	RowGraphSection,
	ScheduleMeetingButton,
	StatCard,
	StatSection,
	TopLanding,
	TopTextLanding,
	VideoEmbed,
	WithoutCroiSection,
	WithoutCroiSectionHeading
} from "./Landing.styles";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navbar/Navbar";

export default function NewLanding() {
	return (
		<>
			<NavBar />
			<TopLanding>
				<TopTextLanding>
					<h2>
						The high schooler's guide to navigating <i>college finances&nbsp;</i>
					</h2>
					<p>
						Embark on your college journey with confidence! Croi’s platform teaches you all about college finances,
						matches you with the best fit, breaks down the true 4-year cost and potential salaries by major, and
						performs a cost-benefit analysis of your final decision. Create your free account and start planning today!
					</p>
				</TopTextLanding>

				<VideoEmbed>
					<iframe
						src={"https://www.youtube.com/embed/GKycBov926A?si=gCkSyRJbhYLXuOkI"}
						allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
						allowFullScreen
						title="Embedded youtube"
					/>
				</VideoEmbed>
			</TopLanding>

			<AMSection>
				{/* <AMCards>
					Students/Parents
					<AMImage imageUrl="images/StudentParent.png" />
					<a href={"https://calendly.com/nickgronda-croi"}>
						<ScheduleMeetingButton disabled={true}> Coming Soon! </ScheduleMeetingButton>
					</a>
				</AMCards> */}
				<AMCards>
					Counselors
					<AMImage imageUrl="images/Counselor.png" />
					<a href={"https://calendly.com/nickgronda-croi"}>
						<ScheduleMeetingButton> Schedule 15-minute meeting </ScheduleMeetingButton>
					</a>
				</AMCards>
			</AMSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
				Make the right decision with the&nbsp;<span style={{ color: "var(--croi-green)" }}>croi</span>&nbsp;5-step
				college process:
			</h2>
			<FiveStepSection>
				<FiveStepCard>
					<FiveStepNumber>1</FiveStepNumber>
					<ImageBackground>
						<img style={{ marginTop: "10px" }} src="/images/book.png" alt="book" />
					</ImageBackground>
					<p>Learn</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>2</FiveStepNumber>
					<ImageBackground>
						<img style={{ marginTop: "10px" }} src="/images/Vector.png" alt="Vector" />
					</ImageBackground>
					<p>Match</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>3</FiveStepNumber>
					<ImageBackground>
						<img src="/images/status-up.svg" alt="statusUp" />
					</ImageBackground>
					<p>Analyze</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>4</FiveStepNumber>
					<ImageBackground>
						<img style={{ marginTop: "10px" }} src="/images/school.png" alt="school" />
					</ImageBackground>
					<p>Decide</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>5</FiveStepNumber>
					<ImageBackground>
						<img style={{ marginTop: "10px" }} src="/images/account_balance.png" alt="accBalance" />
					</ImageBackground>
					<p>Finance</p>
				</FiveStepCard>
			</FiveStepSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
				Here's what students are saying about&nbsp;<span style={{ color: "var(--croi-green)" }}>croi...</span>
			</h2>

			<StatSection>
				<StatCard>
					<h1>92%</h1>
					<p>Found that Croi helped alleviate their anxiety regarding their college decision.</p>
				</StatCard>
				<StatCard>
					<h1>73%</h1>
					<p>Found Croi helpful in choosing the right college based on their wants and needs.</p>
				</StatCard>
			</StatSection>

			<GraphSection>
				<HeaderGraphSection>
					<DescriptionSectionHeading></DescriptionSectionHeading>
					<CroiCheckSectionHeading style={{ borderTop: "var(--croi-green) solid 4px" }}>
						<img src="/images/croiLogo.png" alt="croiLogo" />
					</CroiCheckSectionHeading>
					<WithoutCroiSectionHeading>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>Without Croi</a>
					</WithoutCroiSectionHeading>
				</HeaderGraphSection>
				<RowGraphSection>
					<DescriptionSection>Find the right school that is the best fit for you</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>Possibly make a life-long incorrect decision</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Built in financial aid tutorials</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>College may cost you too much.</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Shop for schools based on financial aid awards</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may leave free money behind.</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Break down the true cost of your education</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may be hit with surprise expenses.</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Compare your schools based on cost-benefit</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may select the more expensive college for the same job after college</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Compare the most relevant loan options </DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may miss out on cheaper loans and free scholarship money</a>
					</WithoutCroiSection>
				</RowGraphSection>
			</GraphSection>

			{/* <CreateAccountSection>
				<h1>Unlock your potential with data-driven guidance</h1>
				<CreateAccountButton disabled={true}>Coming Soon!</CreateAccountButton>
			</CreateAccountSection> */}
			<Footer />
		</>
	);
}
