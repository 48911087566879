import styled from "styled-components";
import { device } from "../../util/device";

interface ImageProps {
	imageUrl: string;
}

export const SquareImageBigger = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	width: 100%;
	height: 225px;
	margin-top: 20px;
`;

export const TitleSection = styled.div`
	justify-content: center;
	text-align: center;
	width: 80%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 100px;

	@media only screen and ${device.mobileM} {
	}

	@media only screen and ${device.tablet} {
	}
`;

export const Title = styled.h2`
	text-align: center;

	@media only screen and ${device.mobileM} {
		font-size: 3em;
	}

	@media only screen and ${device.tablet} {
		font-size: 4.7em;
	}
`;

export const TitleWrapper = styled.div`
	@media only screen and ${device.mobileM} {
		background-size: cover;
		height: 65vh;
	}

	@media only screen and ${device.tablet} {
		background-size: 100%;
		height: 100vh;
	}
`;

export const TitleImage = styled.div`
	@media only screen and ${device.mobileM} {
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flow;
	}
`;

export const PageText = styled.div`
	color: black;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;

	@media only screen and ${device.mobileM} {
		font-size: 15px;
		text-indent: 0;
	}

	@media only screen and ${device.tablet} {
		font-size: 24px;
		text-indent: 50px;
	}
`;
