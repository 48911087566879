import { User } from "../types/User.types";

export default function StudentListOrganizer(students: User[], organizeBy: string) {
	if (organizeBy === "first") {
		students.sort((a: User, b: User) => {
			if (a.data.firstName > b.data.firstName) {
				return 1;
			} else if (a.data.firstName < b.data.firstName) {
				return -1;
			} else {
				return 0;
			}
		});
	} else if (organizeBy === "last") {
		students.sort((a: User, b: User) => {
			if (a.data.lastName > b.data.lastName) {
				return 1;
			} else if (a.data.lastName < b.data.lastName) {
				return -1;
			} else {
				return 0;
			}
		});
	} else if (organizeBy === "email") {
		students.sort((a: User, b: User) => {
			if (a.data.email > b.data.email) {
				return 1;
			} else if (a.data.email < b.data.email) {
				return -1;
			} else {
				return 0;
			}
		});
	}

	const idList: string[] = [];
	for (let i = 0; i < students.length; i++) {
		idList.push(students[i].id);
	}
	return idList;
}
