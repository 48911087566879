import { fetcher } from "./Fetcher.services";
import { FinancialProfile } from "../types/FinancialProfile.types";
import { mutator } from "./Mutator.services";

export function fetchFinancialProfile() {
	return fetcher<FinancialProfile>("/financial", "GET");
}

export function updateFinancialProfile(body: any) {
	return mutator("/financial", "PUT", body);
}
