import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import bgSVG from "../../components/Background.svg";
import {
	CircleImageBigger,
	LinkedInimage,
	NameCardHeader,
	NameCardTitle,
	PageText,
	PageTitle,
	// StorySection,
	Title,
	// TitleImage,
	TitleSection,
	// TitleWrapper
} from "./About.styles";
// import logo2 from "../../atoms/logo_green.svg";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navbar/Navbar";

export default function About() {
	return (
		<>
			<NavBar />
			<TitleSection>
				<Title>Mission</Title>
				<PageText>
					Croi empowers high school students and their families to navigate the complexities of college decisions 
					with confidence. Through a blend of personalized data analytics, financial education, and tailored 
					resources, we provide the tools necessary to make informed choices about higher education. 
					Our mission is to simplify the college selection process, ensuring students can achieve their educational 
					goals with financial wisdom and clarity.
				</PageText>
			</TitleSection>

			<Container style={{ marginBottom: "70px" }} fluid>
				<Row>
					<PageTitle>Team</PageTitle>
				</Row>
				<Row className="justify-content-center text-center">
					<Col>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircleImageBigger imageUrl={"images/nick_gronda.png"} />
							<a href={"https://www.linkedin.com/in/nick-gronda-6b03631a5"}>
								<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
							</a>
						</Container>
						<div className="m-3">
							<NameCardHeader>Nick Gronda</NameCardHeader>
							<NameCardTitle>CEO/Co-Founder</NameCardTitle>
						</div>
					</Col>
					<Col>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircleImageBigger imageUrl={"images/caden-crist.jpg"} />
							<a href={"https://www.linkedin.com/in/caden-crist/"}>
								<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
							</a>
						</Container>
						<div className="m-3">
							<NameCardHeader>Caden Crist</NameCardHeader>
							<NameCardTitle>CTO/Co-Founder</NameCardTitle>
						</div>
					</Col>
					<Col>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircleImageBigger imageUrl={"images/lucas_sicard.jpg"} />
							<a href={"https://www.linkedin.com/in/lucas-sicard-a736a4187/"}>
								<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
							</a>
						</Container>
						<div className="m-3">
							<NameCardHeader>Lucas Sicard</NameCardHeader>
							<NameCardTitle>Full Stack Engineer</NameCardTitle>
						</div>
					</Col>
					<Col>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircleImageBigger imageUrl={"images/zach_rioux.jpg"} />
							<a href={"https://www.linkedin.com/in/zachary-rioux-514916255/"}>
								<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
							</a>
						</Container>
						<div className="m-3">
							<NameCardHeader>Zach Rioux</NameCardHeader>
							<NameCardTitle>Quality Engineer</NameCardTitle>
						</div>
					</Col>
				</Row>
				<Row className="justify-content-center text-center">
					<Col>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircleImageBigger imageUrl={"images/anthony_lopez.png"} />
							<a href={"https://www.linkedin.com/in/anthonyglopez1/"}>
								<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
							</a>
						</Container>
						<div className="m-3">
							<NameCardHeader>Anthony Lopez</NameCardHeader>
							<NameCardTitle>UI Designer</NameCardTitle>
						</div>
					</Col>
					<Col>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircleImageBigger imageUrl={"images/yama_profile.jpeg"} />
							<a href={"https://www.linkedin.com/in/habibzai/"}>
								<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
							</a>
						</Container>
						<div className="m-3">
							<NameCardHeader>Yama Habibzai</NameCardHeader>
							<NameCardTitle>Advisor</NameCardTitle>
						</div>
					</Col>
					<Col>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircleImageBigger imageUrl={"images/evan_macgregor.png"} />
							<a href={"https://www.linkedin.com/in/evan-macgregor/"}>
								<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
							</a>
						</Container>
						<div className="m-3">
							<NameCardHeader>Evan Macgregor</NameCardHeader>
							<NameCardTitle>Account Manager</NameCardTitle>
						</div>
					</Col>
				</Row>
			</Container>

			{/* <StorySection>
				<Title>Our Story</Title>
				<PageText>
					Nick Gronda faced a difficult decision in high school when deciding which college to attend, between Bucknell,
					WPI, or Fairfield. After weighing the scholarship awards and costs of each school with his parents, he
					realized that Bucknell would require him to take out $40k per year in loans, while WPI and Fairfield would
					only require $15k and $5k per year, respectively. Using data-driven decision making, Nick created an algorithm
					that balanced most important factors in his college decision and weighted them according to his wants and
					needs.
				</PageText>
				<img alt="CROI Logo" src={"/images/logo_green.svg"} style={{ width: "200px" }}></img>
				<PageText>
					In 2022, Nick Gronda approached his friend and skilled web developer, Caden Crist, with the idea for a
					web-based College Decision Tool. After developing and testing the tool, they expanded the website's
					functionality and began creating additional resources to assist students in navigating the financial aspects
					of higher education and beyond, leading to the birth of Croi.
				</PageText>
			</StorySection> */}

			<Footer />
		</>
	);
}
