import styled from "styled-components";

export const FAMaster = styled.div`
	margin-top: 15px;

	h4 {
		font-weight: bold;
		font-size: 28px;
		margin-top: 20px;
	}
`;

export const MatchTop = styled.div`
	display: flex;
	font-size: 20px;
`;

export const MatchEdit = styled.button`
	width: 100px;
	height: 30px;
	background-color: var(--croi-green);
	color: white;
	border-radius: 10px;
`;

export const MatchCompleteBar = styled.div`
	width: 50%;
	height: 40px;
	border-radius: 10px;
	font-weight: bold;
	margin-top: 15px;
	border: black 1px solid;

	.progress {
		height: 38px;
		padding-left: 15px;
		font-size: 25px;
		border-radius: 10px;
		background-color: var(--croi-green);
	}
`;

export const MatchSearchRow = styled.div`
	display: flex;
	width: 80%;
	justify-content: space-between;

	b {
		font-weight: bold;
		font-size: 25px;
	}
`;

export const MatchMeButton = styled.button`
	background-color: var(--croi-green);
	width: 320px;
	height: 55px;
	font-size: 23px;
	color: white;
	font-weight: bold;
	border-radius: 10px;
`;

export const MatchBoxList = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
`;

export const NameButtonSection = styled.div`
	display: flex;
	flex-direction: row;

	img {
		height: 30px;
		width: 30px;
		margin-top: 10px;
		margin-left: 3%;
	}
`;

export const LocationSection = styled.div`
	display: flex;
	flex-direction: row;

	img {
		height: 30px;
		width: 30px;
		margin-left: 3%;
	}
`; 

export const MatchCollegeBox = styled.div`
	width: 30%;
	height: 200px;
	background-color: #f0f0f0;
	border-radius: 10px;
	padding-left: 10px;
	margin-bottom: 20px;
	overflow: visible;

	h6 {
		padding-top: 6px;
	}

	h4 {
		margin-top: 15px;
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;
		cursor: pointer;
		width: 85%;

		/* Tooltip styling */
		&::before {
			content: attr(data-fulltext);
			visibility: hidden;
			opacity: 0;
			width: 100%;
			background-color: #f0f0f0;
			color: black;
			text-align: center;
			border-radius: 6px;
			position: absolute;
			height: 40px;
			white-space: normal;
			font-size: 15px;
			padding-right: 5px;
		}

		&:hover::before {
			visibility: visible;
			opacity: 1;
		}
	}

	hr {
		width: 97%;
		marginLeft: 1%;
		marginTop: 10px;
		margin-bottom: 0;
	}
`;

export const PricingInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SpecificPricing = styled.div`
	display: flex;
	flex-direction: row;
	text-align: right;
	justify-content: right;
	padding-right: 20px;

	b {
		padding-left: 5px;
	}
`;

export const AddSchoolBox = styled.div`
	border: dotted black;
	border-radius: 10px;
	width: 30%;
	height: 200px;
	text-align: center;
	font-size: 1.8em;
	font-weight: bolder;
	padding-top: 3%;
	cursor: pointer;

	p {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		background-color: var(--croi-green);
		height: 40px;
		width: 40px;
		border-radius: 50%;
	}
`;

export const MatchSearchBar = styled.div`
	border-radius: 5px;
	border: black 1px solid;
	width: 60%;
	height: 50px;
	padding: 10px;
	font-size: 20px;
	font-weight: bold;

	input {
		width: 100%;
		outline: none;
	}
`;

export const MatchSearchBox = styled.div`
	position: absolute;
	background-color: white;
	border-radius: 10px;
	width: 39%;
	opacity: 90%;
`;

export const MatchSearchItem = styled.div`
	text-align: center;
	line-height: 50px;
	margin-top: 5px;
	padding: 5px;
	cursor: pointer;
	min-height: 50px;

	:hover {
		background: #cccccc;
	}
`;
