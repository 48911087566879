import { Topper } from "./ToolHeader.styles";
import { ToolButton } from "../../atoms/ToolButton";

export default function ToolHeader(title: string, howToLink: string) {
	return (
		<>
			<Topper>
				{ToolButton("Dashboard", "/dashboard", false)}
				<b>{title}</b>
				{ToolButton("How-To Guide", howToLink, true)}
			</Topper>
		</>
	);
}
