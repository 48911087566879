import { fetcher } from "./Fetcher.services";
import { LearnWorldsUserProgressResponse, LWData } from "../types/LearnWorlds.types";
import { mutator } from "./Mutator.services";

export function fetchUserProgress() {
	return fetcher<LearnWorldsUserProgressResponse>("/learn/progress", "GET");
}

export function fetchUserAccess() {
	return fetcher<string>("/learn/access", "GET");
}

export function fetchStudentProgress(uid: string) {
	return fetcher<LearnWorldsUserProgressResponse>(`/learn/student/${uid}`, "GET");
}

export function changeLWAccess(body?: any | null) {
	return mutator("learn", "PUT", body);
}

export function fetchLWData() {
	return fetcher<LWData[]>("learn", "GET");
}
