import styled from "styled-components";

export const SubmitButton = styled.button`
	width: 100%;
	color: var(--white);
	border: none;
	margin: 10px 0 0 0;
	border-radius: 20px;
	padding: 10px;
	background-color: var(--main-teal);
`;
