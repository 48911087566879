import styled from "styled-components";
import { device } from "../util/device";

export const ToolButton = (name: string, link: string, invert: boolean) => {
	return (
		<>
			{invert ? (
				<a href={link}>
					<StyledToolButton style={{ color: "white", backgroundColor: "var(--croi-green)" }}>{name}</StyledToolButton>
				</a>
			) : (
				<a href={link}>
					<StyledToolButton style={{ color: "var(--croi-green)", backgroundColor: "white" }}>{name}</StyledToolButton>
				</a>
			)}
		</>
	);
};

const StyledToolButton = styled.button`
	height: 40px;
	border: 3px solid var(--croi-green);
	border-radius: 5px;

	@media only screen and ${device.mobileM} {
		font-size: 4vw;
		width: 30vw;
	}

	@media only screen and ${device.tablet} {
		font-size: 1.1vw;
		width: 10vw;
	}
`;
