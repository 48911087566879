import React from "react";
import { useLocation } from "react-router-dom";
import { College } from "../../types/College.types";
import {
	BottomWrapper,
	HeaderSchool,
	LargeCard,
	SalaryData,
	SmallCard,
	StyledButton,
	StyledHR,
	TopWrapper
} from "./School.styles";
import ToolHeader from "../../components/ToolHeader/ToolHeader";
import { Col, Row } from "react-bootstrap";
import { FinancialAward } from "../../types/FinancialAward.types";
import { currencyFormatter } from "../../util/currencyFormatter";
import { FoS, getFieldsOfStudy } from "../../services/College.services";
import { useQuery } from "@tanstack/react-query";

export const School = () => {
	const location = useLocation();
	const state = location.state as College;

	const calcFinAward = (amount: number, length: string) => {
		if (length === "1yr") {
			return amount;
		} else {
			return amount / 4;
		}
	};

	const calcNetCost = (sticker: number, FAs: FinancialAward[]) => {
		let cost = sticker;
		FAs.map((FA) => (cost = cost - calcFinAward(FA.data.amount, FA.data.type)));
		return cost;
	};

	// Some homepage urls do not have "https://" before it so they do not work
	let homePageUrl: string = state.schoolUrl.toString();
	if (state.schoolUrl.toString().indexOf("https://") == -1) {
		homePageUrl = "https://" + state.schoolUrl.toString();
		console.log(state);
	}

	// Some price urls do not have "https://" before it so they do not work
	let priceCalcUrl: string = state.priceCalculatorUrl.toString();
	const pos: number = state.priceCalculatorUrl.toString().indexOf("https://");
	if (pos == -1) {
		priceCalcUrl = state.priceCalculatorUrl.toString();
	} else if (pos > 1) {
		// Some price urls have "https://" but there is other text before it so they do not work
		priceCalcUrl = priceCalcUrl.substring(pos);
	}

	const getFOSList = useQuery<FoS[], Error>({
		queryKey: ["fieldsOfStudy"],
		queryFn: () => getFieldsOfStudy(state.collegeScorecardId),
		refetchInterval: false
	});

	return (
		<>
			{ToolHeader("College Decision Tool", "https://www.youtube.com/watch?v=1NnoI10zTJc")}
			<HeaderSchool>
				<h1>
					{state.name} ({state.state})
				</h1>
			</HeaderSchool>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<a href={priceCalcUrl}>
					<StyledButton style={{ color: "white", backgroundColor: "var(--croi-green)", marginTop: "20px" }}>
						Net Cost Calculator
					</StyledButton>
				</a>
				<a href={homePageUrl}>
					<StyledButton
						style={{ color: "var(--croi-green)", backgroundColor: "white", marginLeft: "40px", marginTop: "20px" }}>
						School Website
					</StyledButton>
				</a>
			</div>
			<TopWrapper>
				<SmallCard>
					<h2 style={{ marginTop: "15px" }}>Tuition (Per Year)</h2>
					<Row>
						<Col xs={9} style={{ marginLeft: "-30px" }}>
							<p>Sticker Price</p>
							{state.financialAwards.map((FA) => (
								<p>{FA.data.name}</p>
							))}
						</Col>
						<Col xs={3}>
							<p>${state.cost}</p>
							{state.financialAwards.map((FA) => (
								<p>{currencyFormatter.format(calcFinAward(FA.data.amount, FA.data.type))}</p>
							))}
						</Col>
					</Row>
					<Row>
						<StyledHR></StyledHR>
						<Col xs={9} style={{ marginLeft: "-30px" }}>
							<p>Net Cost</p>
						</Col>
						<Col xs={3}>
							<p>${calcNetCost(state.cost, state.financialAwards)}</p>
						</Col>
					</Row>
				</SmallCard>
				<SmallCard style={{ marginLeft: "30px" }}>
					<h2 style={{ marginTop: "15px" }}>College Data</h2>
					<Row>
						<Col xs={9} style={{ marginLeft: "-20px" }}>
							<p>Acceptance Rate</p>
							<p>Retention Rate</p>
							<p>Graduation Rate</p>
							<p>Average SAT</p>
						</Col>
						<Col xs={3}>
							<p>{(state.admissionRate * 100).toFixed(2)}%</p>
							<p>{(state.retentionRate * 100).toFixed(2)}%</p>
							<p>{(state.completionRate * 100).toFixed(2)}%</p>
							<p>{state.satScore}</p>
						</Col>
					</Row>
				</SmallCard>
			</TopWrapper>
			<BottomWrapper>
				<LargeCard>
					<h2>Salary Data</h2>
					<SalaryData>
						<div>
							<h4>Field Of Study</h4>
							{getFOSList && getFOSList.data && getFOSList.data.map((p) => <p>{p.title}</p>)}
						</div>
						<div>
							<h4>Median Pay</h4>
							{getFOSList && getFOSList.data && getFOSList.data.map((p) => <p>{p.earnings}</p>)}
						</div>
					</SalaryData>
				</LargeCard>
			</BottomWrapper>
		</>
	);
};
