import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
	LoggedInButtons,
	Logo,
	NavContent,
	NavSection,
	ProfileButton,
	ProfileOptions,
	TitleHeading,
	UserNavContent,
	UserNavSection
} from "./Navbar.styles";
import { Burger } from "../../atoms/Burger";
import { useQuery } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { fetchUser } from "../../services/User.services";

export default function NavBar() {
	const [showDropDown, setShowDropDown] = useState(false);
	// const navigate = useNavigate();
	// const signInButton = () => {
	// 	navigate("/signup");
	// };

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false,
		enabled: !!auth.currentUser
	});

	if (getUser.isError) {
		console.log("Error with getUser: " + getUser.error);
	}

	useEffect(() => {
		setShowDropDown(showDropDown);
	}, [showDropDown]);

	return (
		<NavContent>
			<Logo>
				<a href="/">
					<img alt="croi Logo" src={"/images/logo_green.svg"} style={{ width: "90px" }}></img>
				</a>
				<a href="/">croi</a>
			</Logo>
			<Burger />

			<NavSection>
				{/* <a href="/dashboard">Home</a> */}
				<a href="/about">About</a>
				{/*<a href="/pricing">Pricing</a>*/}
				<a href="/counselors">Counselors</a>
				{/*<a href="/contact">Contact</a>*/}
				<a href="/blog">Blog</a>
				<a href="/contact">Contact</a>
				{/*<a href="/faq">FAQ</a>*/}
				<a style={{ color: "var(--croi-green)" }} href="/login">
					Sign In
				</a>
			</NavSection>

			{/* <LoginButton onClick={() => signInButton()}>Create Free Account</LoginButton> */}
		</NavContent>
	);
}

export function UserNavBar(props: { title: string }) {
	const [showDropDown, setShowDropDown] = useState(false);
	const [user, setUser] = useState(auth.currentUser);
	const navigate = useNavigate();
	const signOutButton = () => {
		auth
			.signOut()
			.then(() => {
				navigate("/");
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	auth.onAuthStateChanged(function (user) {
		if (user) {
			setUser(auth.currentUser);
		} else {
			setUser(null);
		}
	});

	const toggleDropDown = () => {
		setShowDropDown(!showDropDown);
	};

	const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
		if (event.currentTarget === event.target) {
			setShowDropDown(false);
		}
	};

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false,
		enabled: !!auth.currentUser
	});

	if (getUser.isError) {
		console.log("Error with getUser: " + getUser.error);
	}

	const options = ["User Settings", getUser.data?.data.isAdmin ? "Admin Panel" : "", "Logout"];

	const optionSelection = (option: string): void => {
		if (option === "Logout") {
			signOutButton();
		}

		if (option === "User Settings") {
			navigate("/settings");
		}

		if (option === "Admin Panel") {
			navigate("/admin");
		}
	};

	useEffect(() => {
		setShowDropDown(showDropDown);
	}, [showDropDown]);

	const mapper = options.map((option: string) =>
		option.length > 0 ? (
			<ProfileOptions
				onClick={(): void => {
					optionSelection(option);
				}}>
				{option}
			</ProfileOptions>
		) : (
			<></>
		)
	);

	return (
		<>
			<UserNavContent>
				<TitleHeading className="user-navbar">{props.title}</TitleHeading>
				<Burger />

				<LoggedInButtons>
					<ProfileButton
						imageUrl={user?.photoURL ? user?.photoURL : "/images/ProfileIcon.png"}
						style={{
							transform: "translateX(-10%)",
							backgroundSize: "75%",
							backgroundRepeat: "no-repeat"
						}}
						className={showDropDown ? "active" : undefined}
						onClick={(): void => toggleDropDown()}
						onBlur={(e: React.FocusEvent<HTMLButtonElement>): void => dismissHandler(e)}>
						{showDropDown && <div className={showDropDown ? "dropdown" : "dropdown active"}>{mapper}</div>}
					</ProfileButton>
				</LoggedInButtons>
			</UserNavContent>
			<UserNavContent style={{ borderBottom: "2px solid black" }}>
				<UserNavSection>
					<p className={props.title === "Croi Learn" ? "selected" : ""}>LEARN</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"}></img>
					<p className={props.title === "search" ? "selected" : ""}>SEARCH</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"}></img>
					<p className={props.title === "Salary Insights" || props.title === "Cost Navigator" ? "selected" : ""}>
						ANALYZE
					</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"}></img>
					<p className={props.title === "College Decision Tool" ? "selected" : ""}>DECIDE</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"}></img>
					<p className={props.title === "finance" ? "selected" : ""}>FINANCE</p>
				</UserNavSection>
			</UserNavContent>
		</>
	);
}
