import styled from "styled-components";

export const LargeCard = styled.div`
	height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: #eeeeee;
	width: 30vw;
	margin-top: 115px;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		margin-top: 50px;
		margin-bottom: 50px;
		background-color: #e4e4e4;
		border-radius: 50px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--croi-green);
		border-radius: 100px;
	}

	h2 {
		margin-top: 15px;
	}

	h4 {
		width: 10px;
	}
`;

export const CollegeBox = styled.div`
	text-align: left;
	width: 85%;
	height: 100px;
	background-color: #cccccc;
	margin-bottom: 10px;
	border-radius: 10px;
	overflow-x: hidden;
	white-space: nowrap;

	h3 {
		margin-left: 10px;
		margin-top: 5px;
		height: 25px;
		font-size: 25px;
	}

	p {
		font-size: 16px;
		margin-left: 11px;
		height: 10px;
	}
`;

export const SearchBar = styled.div`
	margin-bottom: 15px;
	width: 82%;
	max-height: 200px;
	overflow: hidden;
	overflow-y: auto;
	border-radius: 5px;
	margin-left: 9.2%;
`;

export const SearchInputs = styled.div`
	display: flex;
`;

export const SearchItem = styled.div`
	line-height: 50px;
	margin-top: 5px;
	padding: 5px;
	cursor: pointer;
	min-height: 50px;
	:hover {
		background: #cccccc;
	}
`;

export const SearchBox = styled.div`
	position: absolute;
	background-color: white;
	border-radius: 10px;
	width: 27.1%;
`;

export const SubmitButtonCollegeCard = styled.button`
	background-color: var(--croi-green);
	color: #fff;
	border-radius: 10px;
	width: 100px;
`;

export const FoSBox = styled.div`
	margin-top: 5px;
	border-bottom: solid 2px black;

	:hover {
		cursor: pointer;
		background: #cccccc;
	}
`;

export const FABox = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 5px;

	p {
		width: 85px;
	}
`;
