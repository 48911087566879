import styled from "styled-components";

export const DashMaster = styled.div`
	display: flex;
	margin-top: 25px;
	justify-content: space-evenly;
`;

export const DashColumn = styled.div`
	width: 40%;
`;

export const ProfileBlock = styled.div`
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	p {
		margin-top: -15px;
		font-size: 20px;
	}

	u {
		color: gray;
		text-underline: gray;
		cursor: pointer;
	}
`;

export const ProfileImageCircle = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	width: 225px;
	height: 225px;
	border-radius: 50%;
	border: var(--croi-green) 2px solid;
	display: block;
	margin-right: auto;
	margin-left: auto;
	margin-top: 50px;
`;

export const DashNext = styled.div`
	margin-top: 15px;

	h3 {
		font-weight: bolder;
		text-align: left;
	}
`;

export const MyToolsBlock = styled.div`
	display: flex;
	flex-flow: wrap;
	width: 55vw;
	justify-content: space-evenly;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;

	a {
		text-decoration: none;
		color: black;
	}
`;

export const ToolImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center no-repeat;
	background-size: contain;
	position: absolute;
	width: 80px;
	height: 80px;
	transform: translate(-10%, 7%);
`;

export const CStoneImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center no-repeat;
	background-size: contain;
	position: absolute;
	width: 25vw;
	height: 100px;
	transform: translate(-5%, 20%);
`;

export const ToolBlockBig = styled.div`
	width: 53vw;
	height: 170px;
	font-size: 1.5vw;
	background: #d9d9d9;
	text-align: center;
	border-radius: 10px;
	padding: 0 30px 0;

	p {
		transform: translateY(200%);
		font-weight: bold;
	}

	div {
		font-size: 1.2vw;
	}
`;

export const ToolBlockSmall = styled.div`
	width: 26.3vw;
	height: 100px;
	font-size: 1.5vw;
	background: #d9d9d9;
	border-radius: 10px;
	padding: 5px 30px 0;
	margin-top: 10px;
	text-align: right;
	font-weight: bold;

	div {
		font-size: 1.2vw;
		font-weight: normal;
	}
`;

export const RedCStoneButton = styled.div`
	width: 20vw;
	height: 50px;
	font-size: 1.1vw;
	background: #691818;
	border-radius: 10px;
	margin-top: 4px;
	padding-top: 15px;
	text-align: center;
	font-weight: bold;
	color: white;
`;

export const GreyCStoneButton = styled.div`
	width: 20vw;
	height: 50px;
	font-size: 1.35vw;
	background: #d9d9d9;
	border-radius: 10px;
	border: 3px solid #691818;
	margin-top: 75px;
	padding-top: 8px;
	text-align: center;
	font-weight: bold;
	color: #691818;
`;

export const ButtonContainer = styled.div`
	width: 25vw;
	height: 200px;
	font-size: 1.5vw;
	background: #d9d9d9;
	border: 5px solid #691818;
	margin-top: 30px;
	margin-left: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: bold;

	a {
		text-decoration: none;
		color: white;
	}
`;

export const CStoneContainer = styled.div`
	width: 25vw;
	height: 200px;
	font-size: 1.5vw;
	background: white;
	margin-top: 25px;
	margin-left: 55px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: bold;

	a {
		text-decoration: none;
		color: #691818;
	}
`;

export const CStoneImageContainer = styled.div`
	width: 25vw;
	height: 100px;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

interface ImageProps {
	imageUrl: string;
}
