import styled from "styled-components";

export const HeaderSchool = styled.div`
	font-size: 3rem;
	color: #000;
	width: 100%;
	text-align: center;
`;

export const StyledButton = styled.button`
	width: 10vw;
	height: 40px;
	border: 3px solid var(--croi-green);
	border-radius: 5px;
	font-size: 1vw;
`;

export const SmallCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 400px;
	background-color: #cccccc;
	margin-bottom: 10px;
	border-radius: 10px;
`;

export const LargeCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 835px;
	background-color: #cccccc;
	margin-bottom: 10px;
	border-radius: 10px;
`;

export const SalaryData = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
`;

export const TopWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 85%;
	margin-left: 7.5%;
	margin-top: 20px;
`;

export const BottomWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledHR = styled.hr`
	border: 2px solid black;
	border-radius: 5px;
	width: 100%;
`;

// :hover {
// 	background-color: #000;
// 	color: #fff;
// }
