import styled from "styled-components";
import { device } from "../../util/device";

export const Circle = styled.circle`
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin-top: 5px;
	text-align: center;
	justify-content: center;
	padding-top: 12%;
	color: white;
`;

export const SmallCard = styled.div`
	background-color: #eeeeee;
	width: 90%;
	margin: 60px 5% 20px;
	padding-right: 4%;
	padding-left: 4%;
	padding-top: 10px;
	height: 80px;

	h2 {
		font-weight: bold;
		padding-top: 14px;
	}

	@media only screen and ${device.mobileM} {
		display: flow;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: space-between;
	}
`;

export const Section = styled.div`
	display: flex;
	height: 100%;

	h4 {
		padding-top: 16px;
		padding-left: 5px;
	}
`;
