const privateList = [
	{
		name: "College Ave",
		credit: "650",
		fixed: "4.42 - 16.99%",
		variable: "5.29 - 16.99%",
		link: "https://www.collegeavestudentloans.com",
		image: "images/collegeave.png"
	},
	{
		name: "Custom Choice",
		credit: "600",
		fixed: "4.43 - 14.66%",
		variable: "5.13 - 14.95%",
		link: "https://www.customchoice.com",
		image: "images/custom_choice.png"
	},
	{
		name: "Ascent",
		credit: "630",
		fixed: "4.48 - 15.51%",
		variable: "5.94 - 15.83%",
		link: "https://www.ascentfunding.com/",
		image: "images/ascent.png"
	},
	{
		name: "Sallie Mae",
		credit: "650",
		fixed: "4.50 - 15.49%",
		variable: "6.12 - 16.45%",
		link: "https://www.salliemae.com/",
		image: "images/salliemae.png"
	},
	{
		name: "InvestED",
		credit: "N/A",
		fixed: "4.60 - 8.23%",
		variable: "7.37 - 11.41%",
		link: "https://invested.org",
		image: "images/investED.png"
	},
	{
		name: "Sofi",
		credit: "650",
		fixed: "4.49 - 13.97%",
		variable: "4.44 - 14.70%",
		link: "https://www.sofi.com",
		image: "images/sofi.png"
	},
	{
		name: "Funding U",
		credit: "N/A",
		fixed: "N/A",
		variable: "7.49 - 12.99%",
		link: "https://www.funding-u.com",
		image: "images/FundingU.png"
	},
	{
		name: "Discover",
		credit: "Varies",
		fixed: "5.99 - 16.24%",
		variable: "4.49 - 14.99%",
		link: "https://www.discover.com",
		image: "images/discover.png"
	},
	{
		name: "Pnc",
		credit: "Varies",
		fixed: "5.89 - 15.39%",
		variable: "4.39 - 13.89%",
		link: "https://www.pnc.com",
		image: "images/pnc.png"
	},
	{
		name: "Citizens",
		credit: "Varies",
		fixed: "5.57 - 13.76%",
		variable: "4.43 - 12.57%",
		link: "https://www.citizensbank.com",
		image: "images/citizens.png"
	},
	{
		name: "Earnest",
		credit: "650",
		fixed: "4.42 - 15.90%",
		variable: "5.39 - 16.20%",
		link: "https://www.earnest.com",
		image: "images/earnest.png"
	},
	{
		name: "Education Loan Finance",
		credit: "680",
		fixed: "4.48 - 12.29%",
		variable: "4.98 - 12.79%",
		link: "https://www.elfi.com/",
		image: "images/education_loan_finance.png"
	},
	{
		name: "Lendkey",
		credit: "660",
		fixed: "4.39 - 11.11%",
		variable: "5.84 - 11.11%",
		link: "https://www.lendkey.com/",
		image: "images/lendkey.png"
	},
];

export default privateList;
