import styled from "styled-components";

export const FincancialWindow = styled.div`
	width: 50vw;
	background-color: white;
	position: absolute;
	z-index: 100;
	left: 25%;
	border: var(--croi-green) 10px solid;
	border-radius: 10px;

	h1 {
		text-align: center;
		font-weight: bold;
		font-size: 35px;
		margin-bottom: 0;
	}

	h2 {
		color: var(--croi-green);
		font-weight: bold;
		font-size: 32px;
		text-align: center;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		border-bottom: 10px solid var(--croi-green);
		margin-bottom: 25px;
	}

	h3 {
		text-align: center;
		font-weight: bold;
		font-size: 24px;
	}

	p {
		margin-right: auto;
		margin-left: auto;
		width: 80%;
		display: flex;
		justify-content: space-evenly;
		font-size: 20px;

		i {
			text-align: right;
			width: 40%;
		}
	}

	/* Chrome, Safari, Edge, Opera */

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input {
		border: 1px black solid;
		padding-left: 10px;
		border-radius: 5px;
		width: 40%;
	}

	button {
		display: block;
		margin: 30px auto;
		height: 50px;
		width: 300px;
		background-color: var(--croi-green);
		color: white;
		font-size: 24px;
		font-weight: bold;
		border-radius: 10px;
	}

	select {
		width: 50%;
		font-size: 15px;
		padding-left: 5px;
		border-radius: 5px;
		background-color: white;
		border: 1px black solid;
		height: 30px;
	}

	.select-container {
		width: 50%;
		font-size: 15px;
		padding-left: 5px;
		border-radius: 5px;
		background-color: white;
		height: 30px;
	}
`;

export const FinancialBackdrop = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: lightgrey;
	position: absolute;
	z-index: 99;
	left: 0;
	top: 0;
	opacity: 50%;
`;

export const WindowLeftSide = styled.div`
	border-right: var(--croi-green) solid 5px;
	text-align: right;
	width: 50%;
`;

export const WindowRightSide = styled.div`
	border-left: var(--croi-green) solid 5px;
	text-align: center;
	width: 50%;

	i,
	b {
		font-size: 20px;
	}

	b {
		display: flex;
		justify-content: center;
	}
`;

export const GreenCheckCircle = styled.div`
	background-color: var(--croi-green);
	border-radius: 50%;
	height: 20px;
	width: 20px;
	color: white;
	font-size: 13px;
	margin-top: 5px;
	margin-left: 5px;
`;

export const CloseXButton = styled.button`
	color: var(--croi-green);
`;

export const TitleWButton = styled.div`
	display: flex;
	flex-direction: column;

	button {
		margin-left: 90%;
		height: 30px;
		width: 30px;
		background-color: var(--croi-green);
		color: white;
		font-size: 10px;
		font-weight: bold;
		border-radius: 10px;
	}
`;
