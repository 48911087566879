export default function toText(node: any) {
	const tag = document.createElement("div");
	const tag2 = document.createElement("div");
	const second: number = node.lastIndexOf("figcaption") + 11;
	const text = node.substring(second);
	tag2.innerHTML = node.substring(node.indexOf(text), 0);
	tag.innerHTML = text;
	const article = {
		caption: tag2.innerText,
		text: tag.innerText.substring(200, 0)
	};
	return article;
}
