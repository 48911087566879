import React, { useState } from "react";
import styled from "styled-components";
import { device } from "../util/device";
import { Container, Nav, Navbar } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useQuery } from "@tanstack/react-query";
import { User } from "../types/User.types";
import { fetchUser } from "../services/User.services";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

export const Burger = () => {
	const [user, setUser] = useState(auth.currentUser);
	const navigate = useNavigate();

	const logOutButton = () => {
		auth
			.signOut()
			.then(() => {
				navigate("/");
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	auth.onAuthStateChanged(function (user) {
		if (user) {
			setUser(auth.currentUser);
		} else {
			setUser(null);
		}
	});

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false,
		enabled: !!auth.currentUser
	});

	if (getUser.isError) {
		console.log("Error with getUser: " + getUser.error);
	}

	return (
		<StyledBurger>
			{[false].map((expand) => (
				<Navbar key={`{expand:${expand}}`} bg="transparent" expand={false}>
					<Container fluid>
						<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="border-0" />
						<Navbar.Offcanvas
							id={`offcanvasNavbar-expand-${expand}`}
							aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
							placement="end">
							<Offcanvas.Header closeButton></Offcanvas.Header>
							<Offcanvas.Body>
								{user ? (
									<Nav className="justify-content-end flex-grow-1 pe-3">
										<Nav.Link href="/" style={{ color: "black" }}>
											CROI
										</Nav.Link>
										<Nav.Link href="/about" style={{ color: "black" }}>About</Nav.Link>
										<Nav.Link href="/pricing" style={{ color: "black" }}>Pricing</Nav.Link>
										<Nav.Link href="/counselors" style={{ color: "black" }}>Counselors</Nav.Link>
										<Nav.Link href="/blog" style={{ color: "black" }}>Blog</Nav.Link>
										<Nav.Link href="/contact" style={{ color: "black" }}>Contact</Nav.Link>
										<Nav.Link href="/login" style={{ color: "black" }}>Sign In</Nav.Link>
										<Nav.Link onClick={() => logOutButton()} style={{ color: "black" }}>
											Logout
										</Nav.Link>
									</Nav>
								) : (
									<Nav className="justify-content-end flex-grow-1 pe-3">
										<Nav.Link href="/" style={{ color: "black" }}>CROI</Nav.Link>
										<Nav.Link href="/about" style={{ color: "black" }}>About</Nav.Link>
										<Nav.Link href="/pricing" style={{ color: "black" }}>Pricing</Nav.Link>
										<Nav.Link href="/counselors" style={{ color: "black" }}>Counselors</Nav.Link>
										<Nav.Link href="/blog" style={{ color: "black" }}>Blog</Nav.Link>
										<Nav.Link href="/contact" style={{ color: "black" }}>Contact</Nav.Link>
										<Nav.Link href="/login" style={{ color: "black" }}>Sign In</Nav.Link>
									</Nav>
								)}
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					</Container>
				</Navbar>
			))}
		</StyledBurger>
	);
};

const StyledBurger = styled.div`
	@media only screen and ${device.mobileL} {
		display: flex;
	}

	@media only screen and ${device.tablet} {
		display: none;
	}
`;
