import React, { useState } from "react";
import {
	CalcButton,
	dotStyle,
	handleStyle,
	Importance,
	LargeCard,
	PrefList,
	railStyle,
	SmallCard,
	trackStyle
} from "./Preferences.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPetersonData, updateTypeScore } from "../../services/College.services";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { changePreferences, fetchUser } from "../../services/User.services";
import { auth } from "../../firebase";
import preferencesData from "../../atoms/Preferences";
import { queryClient } from "../../services/QueryClient.services";
import { User, userPreferences } from "../../types/User.types";
import { PetersonCollege } from "../../types/PetersonCollege.types";

type PreferenceType = {
	id: number;
	name: string;
	description: string;
	position: number;
};

export default function PreferenceList() {
	const [state, setState] = useState<PreferenceType[]>(preferencesData);
	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false
	});

	const getColleges = useQuery<PetersonCollege[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchPetersonData()
	});

	const setPreferences = useMutation({
		mutationKey: ["preferences"],
		mutationFn: (body: any) => changePreferences(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
		}
	});

	const setScore = useMutation({
		mutationKey: ["preferences"],
		mutationFn: (body: any) => updateTypeScore(body)
	});

	const calculateRankings = async () => {
		let costWeight = 0;
		let salaryWeight = 0;
		let acceptWeight = 0;
		let retenWeight = 0;
		let gradWeight = 0;
		let successWeight = 0;

		const pref: userPreferences = {
			cost: state[0].position,
			salary: state[1].position,
			acceptance: state[2].position,
			retention: state[3].position,
			graduation: state[4].position,
			success: state[5].position,
			environment: getUser.data!.data.pref.environment,
			size: getUser.data!.data.pref.size,
			type: getUser.data!.data.pref.type,
			campusLife: getUser.data!.data.pref.campusLife
		};

		await setPreferences.mutateAsync({
			uid: auth.currentUser!.uid,
			pref: pref
		});

		if (getColleges.data) {
			//Find maxes/weight
			for (let i = 0; i < getColleges.data!.length; i++) {
				if (getColleges.data[i].totalCost > costWeight) {
					costWeight = getColleges.data[i].totalCost;
				}
				if (getColleges.data[i].expectedSalary > salaryWeight) {
					salaryWeight = getColleges.data[i].expectedSalary;
				}
				if (getColleges.data[i].acceptanceRate > acceptWeight) {
					acceptWeight = getColleges.data[i].acceptanceRate;
				}
				if (getColleges.data[i].freshmanRetentionRate > retenWeight) {
					retenWeight = getColleges.data[i].freshmanRetentionRate;
				}
				if (getColleges.data[i].graduationRate > gradWeight) {
					gradWeight = getColleges.data[i].graduationRate;
				}
				if (getColleges.data[i].jobSuccessRate > successWeight) {
					successWeight = getColleges.data[i].jobSuccessRate;
				}
			}

			for (let i = 0; i < getColleges.data!.length; i++) {
				await setScore.mutateAsync({
					cid: getColleges.data[i].id,
					maxCost: costWeight,
					maxSalary: salaryWeight,
					maxAdmission: acceptWeight,
					maxRetention: retenWeight,
					maxGraduation: gradWeight,
					maxSuccess: successWeight,
					cost: getColleges.data[i].totalCost,
					salary: getColleges.data[i].expectedSalary,
					admission: getColleges.data[i].acceptanceRate,
					retention: getColleges.data[i].freshmanRetentionRate,
					graduation: getColleges.data[i].graduationRate,
					success: getColleges.data[i].jobSuccessRate,
					environment: getColleges.data[i].environment,
					size: getColleges.data[i].size,
					type: getColleges.data[i].type,
					life: getColleges.data[i].life
				});
			}
		}

		queryClient.invalidateQueries(["colleges"]);
		window.scrollBy(0, 900 - window.scrollY);
	};

	const handleSliderChange = (value: number | number[], id: number) => {
		const isValueArray = Array.isArray(value);

		if (isValueArray) {
			console.log("This is an error: ", value);
		} else {
			const newState = state.map((pref) => {
				if (pref.id === id) {
					pref.position = value;
				}
				return pref;
			});
			setState(newState);
		}
	};

	return (
		<>
			<LargeCard>
				<h2>My Preferences</h2>
				<i>Use the slider to rank how important each factor is to you in a college decision.</i>
				<Importance>
					<p>Very Unimportant</p>
					<p>Very Important</p>
				</Importance>
				<PrefList>
					{state.map((pref) => (
						<SmallCard key={`{Pref${pref.id}}`}>
							<h2>{pref.name}</h2>
							<p>{pref.description}</p>
							<Slider
								dotStyle={dotStyle}
								styles={{ handle: handleStyle, track: trackStyle, rail: railStyle }}
								style={{ width: "90%" }}
								min={1}
								max={5}
								step={1}
								dots
								defaultValue={pref.position}
								onChange={(value) => handleSliderChange(value, pref.id)}
							/>
						</SmallCard>
					))}
				</PrefList>
				<CalcButton onClick={() => calculateRankings()} disabled={getColleges.isLoading || getColleges.isError}>
					Calculate Rankings
				</CalcButton>
			</LargeCard>
		</>
	);
}
