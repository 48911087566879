import styled from "styled-components";

export const SalaryBox = styled.div`
	width: 30%;
	height: 200px;
	background-color: #f0f0f0;
	margin-top: 20px;
	border-radius: 10px;
	padding: 5px;

	h4 {
		font-weight: bold;
		text-align: center;
	}

	button {
		color: var(--croi-green);
		background: transparent;
		cursor: pointer;
		float: right;
		margin-right: 20px;
	}
`;

export const SalaryDataRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 95%;
	background-color: #d9d9d9;
	margin-left: 2.5%;
	border-radius: 10px;
	padding-left: 10px;
	margin-top: 5px;
	height: 40px;
	padding-top: 7px;

	p {
		width: 25%;
		text-align: center;

		&.title {
			text-align: left;
			font-weight: bold;
			width: 75%;
			text-wrap: nowrap;
			overflow-x: clip;
			text-overflow: ellipsis;
		}
	}
`;

export const SalaryTable = styled.table`
	overflow: scroll;
	border-collapse: collapse;
	margin-right: auto;
	margin-left: auto;

	td {
		padding: 2px;
		border: 1px solid black;
		text-align: center;
	}

	tbody tr:nth-child(odd) {
		background-color: lightgrey;
	}
`;
