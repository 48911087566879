import styled from "styled-components";

export const ResultsLargeCard = styled.div`
	height: 850px;
	background-color: #eeeeee;
	width: 80%;
	margin: auto;
	margin-top: 70px;
	margin-bottom: 20px;
	text-align: center;
`;

export const ResultsSmallBox = styled.div`
	text-align: center;
	width: 90%;
	height: 100px;
	background-color: #cccccc;
	margin-bottom: 10px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;

	h3 {
		width: 500px;
		margin-left: 10px;
		margin-top: 5px;
		height: 25px;
		font-size: 25px;
	}

	p {
		font-size: 14px;
		margin-left: 11px;
		height: 10px;
	}
`;

export const ResultsTopRow = styled.div`
	display: flex;
	font-size: 25px;
	p {
		margin-left: 1.5%;
	}
`;

export const DataGraphs = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;

	div {
		display: block;
	}
`;

export const TempLine = styled.hr`
	border-top: 5px solid var(--croi-green);
	width: 40%;
	margin-left: 30%;
	margin-bottom: 3%;
`;

export const NumberLineContainer = styled.div`
	position: relative;
	width: 90%;
	height: 20px;
	background-color: white;
`;

export const Bar = styled.div`
	position: absolute;
	width: 8px;
	height: 20px;
	background-color: var(--croi-green);
	transform: translateX(-50%);
`;

export const NumberLabelBottom = styled.span`
	position: absolute;
	top: 100%;
	transform: translateX(-50%);
	color: red;
`;

export const NumberLabelTop = styled.span`
	position: absolute;
	bottom: 100%;
	transform: translateX(-50%);
	color: red;
`;

export const NumberLabelMiddle = styled.span`
	position: absolute;
	transform: translateX(-50%);
	color: black;
`;

export const Circle = styled.div`
	background: var(--croi-green);
	border-radius: 50%;
	color: white;
	width: 130px;
	height: 130px;
	text-align: center;
	padding-top: 3%;
	font-size: 80px;
	margin-left: 40%;
	line-height: 100px;
`;

export const TriangleRed = styled.div`
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 20px solid red;
`;

export const TriangleGreen = styled.div`
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 20px solid green;
`;

export const TriangleBlack = styled.div`
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 20px solid black;
	transform: rotate(-90deg);
`;

export const ProfileButton = styled.button<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center no-repeat;
	width: 60px;
	height: 60px;
	margin-right: 100px;
`;

export const LoggedInButtons = styled.div``;

interface ImageProps {
	imageUrl: string;
}

export const ProfileOptions = styled.div`
	display: block;
	background: white;
	width: 150px;
	padding: 5px;
	transform: translate(-100px, 50px);
	border: black 2px solid;
	position: relative;
	z-index: 1;

	:hover {
		background: #3dd5f3;
	}
`;
