import React from "react";
import { Pie, PieChart, Sector } from "recharts";

const renderActiveShape = (props: any) => {
	const { cx, cy, innerRadius, outerRadius, fill, percent } = props;

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
				{(100 - percent * 100).toFixed(0)}%
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={0}
				endAngle={360}
				fill={"lightgray"}
			/>
		</g>
	);
};

export default function CroiStatusCircle(data: any) {
	return (
		<PieChart width={165} height={165}>
			<Pie
				stroke={"var(--croi-green)"}
				activeIndex={0}
				activeShape={renderActiveShape}
				data={data}
				cx={76}
				cy={76}
				innerRadius={50}
				outerRadius={80}
				fill="var(--croi-green)"
				dataKey="value"
				startAngle={90}
				endAngle={450}
				cornerRadius={4}
			/>
		</PieChart>
	);
}
