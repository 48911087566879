import React from "react";
import {
    BenefitCard,
    BenefitCardsSection,
    BenefitSection,
	CreateAccountButton,
	CreateAccountSection,
	FiveStepCard,
	FiveStepNumber,
	FiveStepSection,
	ImageBackground,
	ScheduleMeetingButton,
	StatCard,
	StatSection,
	TopLanding,
	TopTextLanding,
	VideoEmbed,
} from "./Counselors.styles";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navbar/Navbar";

export default function Counselors() {
	return (
		<>
			<NavBar />
			<TopLanding>
				<TopTextLanding>
					<h2>Strengthen the effectiveness of your college counseling.</h2>
					<a href={"https://calendly.com/nickgronda-croi"}>
						<ScheduleMeetingButton>Schedule 15-Minute Meeting</ScheduleMeetingButton>
					</a>
				</TopTextLanding>

				<VideoEmbed>
					<iframe
						src={"https://www.youtube.com/embed/GKycBov926A?si=gCkSyRJbhYLXuOkI"}
						allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
						allowFullScreen
						title="Embedded youtube"
					/>
				</VideoEmbed>
			</TopLanding>

            <BenefitSection>
                <h2>Benefits For Your Counseling Department</h2>
                <BenefitCardsSection>
                    <BenefitCard>
                        <img style={{height: "125px", width: "125px", marginBottom: "0px"}} src="images/Demand.png" alt="Demand"/>
                        <a>Address the individual wants and needs of EVERY one of your students.</a>
                    </BenefitCard>
                    <BenefitCard>
                        <img src="images/Clock.png" alt="Clock"/>
                        <a>Save time by eliminating the need to gather information from different online sources.</a>
                    </BenefitCard>
                    <BenefitCard>
                        <img src="images/Phone.png" alt="Phone"/>
                        <a>Manage financial aid and decision deadlines through text and email reminders.</a>
                    </BenefitCard>
                </BenefitCardsSection>
            </BenefitSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
				Students make the right decision with the&nbsp;<span style={{ color: "var(--croi-green)" }}>croi</span>&nbsp;5-step college process:
			</h2>	
			<FiveStepSection>
				<FiveStepCard>
					<FiveStepNumber>1</FiveStepNumber>
					<ImageBackground><img style={{marginTop: "10px"}} src="images/book.png" alt="book" /></ImageBackground>
					<p>Learn</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>2</FiveStepNumber>
					<ImageBackground><img style={{marginTop: "10px"}} src="images/Vector.png" alt="Vector" /></ImageBackground>
					<p>Match</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>3</FiveStepNumber>
					<ImageBackground><img src="images/status-up.svg" alt="statusUp" /></ImageBackground>
					<p>Analyze</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>4</FiveStepNumber>
					<ImageBackground><img style={{marginTop: "10px"}} src="images/school.png" alt="school"/></ImageBackground>
					<p>Decide</p>
				</FiveStepCard>
				<FiveStepCard>
					<FiveStepNumber>5</FiveStepNumber>
					<ImageBackground><img style={{marginTop: "10px"}} src="images/account_balance.png" alt="accBalance"/></ImageBackground>
					<p>Finance</p>
				</FiveStepCard>
			</FiveStepSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
					Here's what students are saying about&nbsp;<span style={{ color: "var(--croi-green)" }}>croi...</span>
			</h2>

			<StatSection>
				<StatCard>
					<h1>92%</h1>
					<p>Found that Croi helped alleviate their anxiety regarding their college decision.</p>
				</StatCard>
				<StatCard>
					<h1>73%</h1>
					<p>Found Croi helpful in choosing the right college based on their wants and needs.</p>
				</StatCard>
			</StatSection>

			<CreateAccountSection>
				<h1>Unlock your student’s potential with data-driven guidance</h1>
				<a href={"https://calendly.com/nickgronda-croi"}>
					<CreateAccountButton>Schedule 15-Minute Meeting</CreateAccountButton>
				</a>
			</CreateAccountSection>
			<Footer />
		</>
	);
}
