import styled from "styled-components";

export const SignupWrapper = styled.div`
	padding-top: 1%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 95vw;
`;

export const SignUpCardWrapper = styled.div`
	border: 3px solid var(--croi-green);
	border-radius: 8px;
`;
