import React from "react";
import toText from "../../util/toText";
// import bgSVG from "../Background.svg";
import { PageText, SquareImageBigger, Title, TitleSection } from "./BlogCard.styles";
import { Container, Row } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

type Blog = {
	item: any[];
	isLoading: boolean;
	error: string;
};

interface BlogProps {
	blog: Blog;
}
export default function Blog({ blog }: BlogProps) {
	const haveBlogs = () => {
		if (blog.item) {
			return blog.item.map((post, index) => (
				<Container style={{ marginBottom: "70px" }}>
					<Row className="justify-content-center text-center">
						<Container className="card ab shadow w-50" key={index}>
							<SquareImageBigger imageUrl={post.thumbnail} />
							<h5 className="card-title">
								<a href={post.link} style={{ color: "black" }} className="postTitle" rel="noreferrer" target="_blank">
									{post.title}
								</a>
							</h5>
							<p className="cardCaption">{toText(post.description).caption}</p>
							<p className="cardText">{`${toText(post.description).text}...`}</p>
						</Container>
					</Row>
				</Container>
			));
		}
	};

	return (
		<>
			<TitleSection>
				<Title>Resources</Title>
				<PageText>
					Expert insights and informative blog articles to empower students in their financial decisions.
				</PageText>
			</TitleSection>
			<Container style={{ marginBottom: "70px" }} fluid>
				<div
					style={{
						display: "flex",
						justifyContent: "space-evenly",
						flexWrap: "wrap"
					}}>
					{blog.isLoading ? <ScaleLoader color="black" loading={blog.isLoading} /> : haveBlogs()}
				</div>
			</Container>
		</>
	);
}

