import React from "react";
import { TopWrapper } from "./CollegeDecisionTool.styles";
import PreferenceList from "../../components/CollegeDecisionModules/Preferences";
import CollegeList from "../../components/CollegeDecisionModules/CollegeList";
import Results from "../../components/CollegeDecisionModules/Results";
import ColorKey from "../../components/CollegeDecisionModules/ColorKey";
import MyCriteria from "../../components/CollegeDecisionModules/MyCriteria";

export default function CollegeDecisionTool() {
	return (
		<>
			<TopWrapper>
				<div>
					<CollegeList />
					<MyCriteria />
				</div>
				<PreferenceList />
			</TopWrapper>
			<div>
				<ColorKey />
				<Results />
			</div>
		</>
	);
}
