import React, { useState } from "react";
import {
	CurrentCollege,
	SalaryDataBoxes,
	SalaryMaster,
	SalarySearch,
	SalarySearchBar,
	SalarySearchBox,
	SalarySearchItem
} from "./SalaryInsights.styles";
import { useQuery } from "@tanstack/react-query";
import {
	fetchBachelors,
	fetchCommonJobs,
	fetchCommunities,
	fetchMajors,
	fetchPayBack
} from "../../services/Salary.service";
import { BestAllBachelor, BestByMajor, BestTwoYear, CommonJobs, MajorPayBack } from "../../types/Salary.types";
import { ScaleLoader } from "react-spinners";
import useFetch from "../../hooks/useFetch";
import { currencyFormatter } from "../../util/currencyFormatter";
import SalaryDataBox from "../../components/SalaryDataBox/SalaryDataBox";

export default function SalaryInsights() {
	const { searchResults, setSearchResults } = useFetch();
	const [collegeBachelor, setCollegeBachelor] = useState<BestAllBachelor>();
	const [collegeCommunity, setCollegeCommunity] = useState<BestTwoYear>();

	const getBachelors = useQuery<BestAllBachelor[], Error>({
		queryKey: ["salary1"],
		queryFn: () => fetchBachelors(),
		refetchInterval: false
	});

	const getMajors = useQuery<BestByMajor[], Error>({
		queryKey: ["salary2"],
		queryFn: () => fetchMajors(),
		refetchInterval: false
	});

	const getCommunities = useQuery<BestTwoYear[], Error>({
		queryKey: ["salary3"],
		queryFn: () => fetchCommunities(),
		refetchInterval: false
	});

	const getCommonJobs = useQuery<CommonJobs[], Error>({
		queryKey: ["salary4"],
		queryFn: () => fetchCommonJobs(),
		refetchInterval: false
	});

	const getPayBack = useQuery<MajorPayBack[], Error>({
		queryKey: ["salary5"],
		queryFn: () => fetchPayBack(),
		refetchInterval: false
	});

	if (getBachelors.isError) {
		console.log(getBachelors.error);
	}

	if (getMajors.isError) {
		console.log(getMajors.error);
	}

	if (getCommunities.isError) {
		console.log(getCommunities.error);
	}

	if (getCommonJobs.isError) {
		console.log(getCommonJobs.error);
	}

	if (getPayBack.isError) {
		console.log(getPayBack.error);
	}

	const handleItemClick = (collegeId: string) => {
		setSearchResults({ ...searchResults, slug: "" });

		if (getBachelors.data && getCommunities.data) {
			if (getBachelors.data.find((info) => info.id === collegeId.toString())) {
				setCollegeBachelor(getBachelors.data.find((info) => info.id === collegeId.toString()));
				setCollegeCommunity(undefined);
			} else {
				setCollegeCommunity(getCommunities.data.find((info) => info.id === collegeId.toString()));
				setCollegeBachelor(undefined);
			}
		}
	};

	const displayCollege = () => {
		if (collegeBachelor !== undefined) {
			const majors: BestByMajor[] = [];

			getMajors.data?.forEach((major) => {
				if (major.name === collegeBachelor.name) {
					majors.push(major);
				}
			});

			return (
				<CurrentCollege>
					<div style={{ width: "25%" }}>
						<h3>{collegeBachelor.name}</h3>
						<h5>
							{collegeBachelor.state} {collegeBachelor.zip}
						</h5>
						{collegeBachelor.type.map((type) => (
							<h5 style={{ fontSize: "14px" }}>{type}</h5>
						))}
						<br />
						<h6>Rank {collegeBachelor.rank}</h6>
						<h6>{collegeBachelor.sector} College</h6>
						<h6>Average Early Salary {currencyFormatter.format(collegeBachelor.earlyPay)}</h6>
						<h6>Average Mid Salary {currencyFormatter.format(collegeBachelor.midPay)}</h6>
						<h6>How Meaningful: {collegeBachelor.jobMeaning}%</h6>
						<h6>
							{collegeBachelor.male}% Male | {collegeBachelor.female}% Female
						</h6>
						<h6>{collegeBachelor.stem}% STEM</h6>
						<h6>Undergrad Fall Enrollment: {collegeBachelor.enrollment}</h6>
					</div>
					<table>
						<thead>
							<tr>
								<th></th>
								<th>Rank</th>
								<th>Early Pay</th>
								<th>Mid Pay</th>
							</tr>
						</thead>
						<tbody>
							{majors.map((major) => (
								<>
									<tr>
										<td style={{ width: "31%", textAlign: "left" }}>{major.major}</td>
										<td>{major.rank}</td>
										<td>{currencyFormatter.format(major.earlyPay)}</td>
										<td>{currencyFormatter.format(major.midPay)}</td>
									</tr>
									<tr style={{ background: "transparent", height: "10px" }} />
								</>
							))}
						</tbody>
					</table>
				</CurrentCollege>
			);
		} else if (collegeCommunity !== undefined) {
			return (
				<CurrentCollege>
					<div style={{ width: "100%" }}>
						<h3>{collegeCommunity.name}</h3>
						<h5>
							{collegeCommunity.state} {collegeCommunity.zip}
						</h5>
						{collegeCommunity.type.map((type) => (
							<h5 style={{ fontSize: "14px" }}>{type}</h5>
						))}
						<br />
						<h6>Rank {collegeCommunity.rank}</h6>
						<h6>Average Early Salary {currencyFormatter.format(collegeCommunity.earlyPay)}</h6>
						<h6>Average Mid Salary {currencyFormatter.format(collegeCommunity.midPay)}</h6>
						<h6>How Meaningful: {collegeCommunity.jobMeaning}%</h6>
						<h6>
							{collegeCommunity.male}% Male | {collegeCommunity.female}% Female
						</h6>
						<h6>{collegeCommunity.stem}% STEM</h6>
						<h6>Undergrad Fall Enrollment: {collegeCommunity.enrollment}</h6>
					</div>
				</CurrentCollege>
			);
		} else {
			return (
				<CurrentCollege style={{ fontSize: "3em", paddingLeft: "35%", paddingTop: "10%" }}>
					No College Selected
				</CurrentCollege>
			);
		}
	};

	return (
		<SalaryMaster>
			<p><em>Search any school to receive a comprehensive breakdown of early and mid-career median salaries by field of study, along with valuable post-graduation employment information to guide your career planning.</em></p>
			<SalarySearch>
				<input
					className="p-2 w-100"
					type="text"
					placeholder={"Search College"}
					value={searchResults.slug}
					onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
				/>
				{searchResults.slug !== "" && (
					<SalarySearchBox>
						{searchResults.loading && (
							<SalarySearchItem>
								<div style={{ margin: "50px auto 50px auto" }}>
									<ScaleLoader color="black" loading={searchResults.loading} />
								</div>
							</SalarySearchItem>
						)}
						{searchResults.results && (
							<SalarySearchBar>
								{searchResults.results.map((value) => {
									return (
										<SalarySearchItem key={value.id} onClick={() => handleItemClick(value.id)}>
											{value["school.name"]}
										</SalarySearchItem>
									);
								})}
							</SalarySearchBar>
						)}
					</SalarySearchBox>
				)}
			</SalarySearch>
			{displayCollege()}
			<p style={{paddingTop: "10px"}}><em>Explore the curated lists below, each providing a ranked overview to help you identify leading institutions and programs in your field of interest.</em></p>
			<SalaryDataBoxes>
				{getBachelors.data ? <SalaryDataBox type={"BestAllBachelor"} data={getBachelors.data} /> : <></>}
				{getMajors.data ? <SalaryDataBox type={"BestSchoolByMajor"} data={getMajors.data} /> : <></>}
				{getMajors.data ? <SalaryDataBox type={"BestMajorBySalary"} data={getMajors.data} /> : <></>}
				{getCommunities.data ? <SalaryDataBox type={"BestTwoYear"} data={getCommunities.data} /> : <></>}
				{getCommonJobs.data ? <SalaryDataBox type={"CommonJobs"} data={getCommonJobs.data} /> : <></>}
				{getPayBack.data ? <SalaryDataBox type={"MajorPayBack"} data={getPayBack.data} /> : <></>}
			</SalaryDataBoxes>
		</SalaryMaster>
	);
}
