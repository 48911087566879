const federalList = [{
	name: "Federal Direct Student Loan",
	credit: "",
	fixed: "",
	variable: "",
	link: "https://studentaid.gov/understand-aid/types/loans/subsidized-unsubsidized#subsidized-vs-unsubsidized",
	image: "images/federal_student_loan.png"
},
{
	name: "Federal Parent Plus Loan",
	credit: "",
	fixed: "",
	variable: "",
	link: "https://studentaid.gov/plus-app/",
	image: "images/federal_student_loan.png"
},
{
	name: "Pell Grant",
	credit: "",
	fixed: "",
	variable: "",
	link: "https://studentaid.gov/understand-aid/types/grants/pell",
	image: "images/pellgrant.png"
},
{
	name: "TEACH Grant",
	credit: "",
	fixed: "",
	variable: "",
	link: "https://studentaid.gov/teach-grant-program",
	image: "images/pellgrant.png"
}];

export default federalList;