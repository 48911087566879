import { PetersonCollege } from "../types/PetersonCollege.types";
import { currencyFormatter } from "../util/currencyFormatter";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	changeFieldOfStudy,
	createFinancialAward,
	deleteCollege,
	deleteFinancialAward,
	FoS,
	getFieldsOfStudy,
	updateFinancialAward
} from "../services/College.services";
import { queryClient } from "../services/QueryClient.services";
import {
	CollegeDelete,
	FABox,
	FAEditButton,
	SubmitButtonCollegeCard
} from "../components/CollegeDecisionModules/CollegeList.styles";
import React, { useState } from "react";
import { FinancialAward } from "../types/FinancialAward.types";
import { orderBy } from "lodash";
import { Modal } from "react-bootstrap";

export const CollegeEntry = (props: { college: PetersonCollege }) => {
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const deleteQuery = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (id: string) => deleteCollege(id),
		onSuccess: () => {
			console.log("Deleted College Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const handleDeleteCollege = () => {
		deleteQuery.mutate(props.college.id);
	};

	/************************************ Field of Study ***********************************/
	const fosUpdate = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => changeFieldOfStudy(props.college.id, body),
		onSuccess: () => {
			console.log("Changed Field of Study Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const getFieldList = useQuery<FoS[], Error>({
		queryKey: [`fieldsOfStudy${props.college.iped}`],
		queryFn: () => getFieldsOfStudy(props.college.iped),
		refetchInterval: false
	});

	const fieldOptions = getFieldList.data?.map((p) => {
		return <option value={p.title}>{p.title}</option>;
	});

	const handleFieldOfStudyModal = (title: string) => {
		const body = {
			id: props.college.id,
			fieldOfStudyCode: 1234,
			fieldOfStudyName: title
		};

		fosUpdate.mutate(body);
	};
	/************************************ Field of Study ***********************************/

	/*********************************** Financial Award ***********************************/
	const [showFAList, setShowFAList] = useState(false);
	const [showFAEdit, setShowFAEdit] = useState(false);
	const [FAID, setFAID] = useState("");

	const [choice, setChoice] = useState("create");
	const [name, setName] = useState("");
	const [awardValue, setAwardValue] = useState(0);
	const [type, setType] = useState("1yr");

	const checkCost = (value: number) => {
		if (props.college.costPerYear - value < 0) {
			setFormErrorMessage("Financial award cannot be more than total cost");
			return;
		}

		setFormErrorMessage("");
	};

	const financialAwardCalc = () => {
		let totalAmount = 0;
		props.college.financialAwards.map((financialAward) => (totalAmount += financialAward.data.amount));
		return totalAmount;
	};

	const createFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => createFinancialAward(props.college.id, body),
		onSuccess: () => {
			console.log("Created Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const updateFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => updateFinancialAward(props.college.id, FAID, body),
		onSuccess: () => {
			console.log("Updated Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const deleteFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => deleteFinancialAward(props.college.id, FAID, body),
		onSuccess: () => {
			console.log("Deleted Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const handleCreateFA = () => {
		const body = {
			id: props.college.id,
			name: name,
			amount: awardValue,
			type: type
		};
		createFA.mutate(body);
		setShowFAEdit(false);
	};

	const handleUpdateFA = () => {
		const body = {
			id: FAID,
			name: name,
			amount: awardValue,
			type: type
		};
		updateFA.mutate(body);
		setShowFAEdit(false);
	};

	const handleDeleteFA = (f: string) => {
		setFAID(f);
		const body = {
			Cid: props.college.id,
			FAid: f
		};
		console.log(body);
		deleteFA.mutate(body);
		setShowFAList(false);
	};

	const handleEditFA = (c: string, f?: FinancialAward) => {
		setChoice(c);

		if (f) {
			setFAID(f.id);
			setName(f.data.name);
			setAwardValue(f.data.amount);
			setType(f.data.type);
		} else {
			setName("");
			setAwardValue(0);
			setType("1yr");
		}

		setShowFAList(false);
		setShowFAEdit(true);
	};

	const typeRender = (t: string) => {
		if (t === "1yr") {
			return "1 year";
		} else {
			return "4 year";
		}
	};

	const listFA = orderBy(props.college.financialAwards).map((FA) => {
		return (
			<FABox key={`{FA ${FA.id}}`}>
				<p>{FA.data.name}</p>
				<p>{currencyFormatter.format(FA.data.amount)}</p>
				<p>{typeRender(FA.data.type)}</p>
				<SubmitButtonCollegeCard
					onClick={() => {
						handleEditFA("update", FA);
					}}>
					Edit
				</SubmitButtonCollegeCard>
				<SubmitButtonCollegeCard
					onClick={() => {
						handleDeleteFA(FA.id);
					}}>
					Delete
				</SubmitButtonCollegeCard>
			</FABox>
		);
	});
	/*********************************** Financial Award ***********************************/

	return (
		<div>
			<h3>
				{props.college.name} <CollegeDelete onClick={() => handleDeleteCollege()}>x</CollegeDelete>
			</h3>
			<p style={{ display: "flex" }}>
				<div style={{ width: "40%" }}>Financial Award = {currencyFormatter.format(financialAwardCalc())}</div>
				<FAEditButton onClick={() => setShowFAList(true)}>Edit</FAEditButton>
			</p>
			<p>
				Field of Study:
				<select onChange={(value) => handleFieldOfStudyModal(value.target.value)} value={props.college.fieldOfStudy}>
					{getFieldList.isSuccess ? fieldOptions : <></>}
				</select>
			</p>

			{/*  MANAGE FINANCIAL AWARDS MODAL  */}
			<Modal
				show={showFAList}
				onHide={() => {
					setFormErrorMessage("");
					setShowFAList(false);
				}}>
				<Modal.Header closeButton>
					<Modal.Title>Manage Financial Awards</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ display: "flex", justifyContent: "space-between", width: "47%" }}>
						<p>Name</p>
						<p>Amount</p>
						<p>Type</p>
					</div>
					{listFA}
				</Modal.Body>
				<Modal.Footer>
					{!!formErrorMessage && <p style={{ color: "red" }}>{formErrorMessage}</p>}
					<SubmitButtonCollegeCard
						disabled={!!formErrorMessage}
						onClick={() => {
							handleEditFA("create", undefined);
						}}>
						Add
					</SubmitButtonCollegeCard>
				</Modal.Footer>
			</Modal>

			{/*  ADD/UPDATE FINANCIAL AWARD MODAL  */}
			<Modal
				show={showFAEdit}
				onHide={() => {
					setFormErrorMessage("");
					setShowFAEdit(false);
				}}>
				<Modal.Header closeButton>
					<Modal.Title>Input Financial Award</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Name:
					<input
						className="p-2 w-100"
						style={{ border: "1px solid black", borderRadius: "5px" }}
						type="text"
						placeholder={"Name"}
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					Amount:
					<input
						className="p-2 w-100"
						style={{ border: "1px solid black", borderRadius: "5px" }}
						type="number"
						placeholder={"Amount"}
						value={awardValue}
						onChange={(e) => {
							setAwardValue(e.target.valueAsNumber);
							checkCost(awardValue);
						}}
					/>
					Type:
					<br />
					<input id={"1yr"} type="radio" name={"awardType"} value={type} onClick={() => setType("1yr")} />1 year
					<br />
					<input id={"4yr"} type="radio" name={"awardType"} value={type} onClick={() => setType("4yr")} />4 year
				</Modal.Body>
				<Modal.Footer>
					{!!formErrorMessage && <p style={{ color: "red" }}>{formErrorMessage}</p>}
					<SubmitButtonCollegeCard
						disabled={!!formErrorMessage}
						onClick={() => {
							if (choice === "create") {
								handleCreateFA();
							} else {
								handleUpdateFA();
							}
						}}>
						Submit
					</SubmitButtonCollegeCard>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
