import React, { useEffect, useState } from "react";
import { Circle, SchoolSection, LargeCard } from "./SchoolGrade.styles";
// import { Col, Row } from "react-bootstrap";
import { useAtom } from "jotai";
import { gradingsAtom } from "../../services/Store.services";

export default function Grade() {
	const [gradings] = useAtom(gradingsAtom);
	const [reach, setReach] = useState<string[]>([]);
	const [target, setTarget] = useState<string[]>([]);
	const [safety, setSafety] = useState<string[]>([]);

	const schools = () => {
		const reachSchools: string[] = [];
		const targetSchools: string[] = [];
		const safetySchools: string[] = [];

		for (let i = 0; i < gradings.length; i++) {
			if (gradings[i].score > 66) {
				safetySchools.push(gradings[i].name);
			} else if (gradings[i].score > 33) {
				targetSchools.push(gradings[i].name);
			} else {
				reachSchools.push(gradings[i].name);
			}
		}
		setReach(reachSchools);
		setTarget(targetSchools);
		setSafety(safetySchools);
	};

	useEffect(() => {
		schools();
	}, [gradings]);

	return (
		<>
			<LargeCard className="text-center">
				<h2>My College List</h2>
				<SchoolSection>
					<Circle>{reach.length}</Circle>
					<div>
						<h3>Reach</h3>
						{reach.map((r) => (
							<p key={r}>{r}</p>
						))}
					</div>
				</SchoolSection>
				<SchoolSection>
					<Circle>{target.length}</Circle>
					<div>
						<h3>Target</h3>
						{target.map((r) => (
							<p key={r}>{r}</p>
						))}
					</div>
				</SchoolSection>
				<SchoolSection>
					<Circle>{safety.length}</Circle>
					<div>
						<h3>Safety</h3>
						{safety.map((r) => (
							<p key={r}>{r}</p>
						))}
					</div>
				</SchoolSection>
			</LargeCard>
		</>
	);
}
