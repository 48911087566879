import styled from "styled-components";

export const TopWrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin-top: 20px;
`;

export const Disclaimer = styled.div`
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 75vw;
	margin-bottom: -100px;
	font-size: 15px;
`;
