import styled from "styled-components";

export const UserToolsMaster = styled.main`
	width: 83vw;
	margin-left: 17vw;
`;

export const SidebarContainer = styled.div`
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	width: 15vw;
	font-family: Aleo, serif;
	background-color: #eeeeee;
	border-radius: 16px;
	box-shadow: 5px 5px 5px lightgrey;
	overflow-y: scroll;
	height: 100vh;
	min-height: 600px;
`;

export const Logo = styled.div`
	display: flex;
	padding: 5px;
	justify-content: center;
	height: 130px;

	a {
		text-decoration: none;
		color: var(--croi-green);
		font-weight: 700;
		font-size: 3rem;
	}

	img {
		width: 25%;
		margin-left: 5%;
	}
`;

export const Option = styled.div`
	width: 98%;
	padding: 10px;
	margin-left: 2%;
	border-radius: 10px 0 0 10px;
	font-size: 1.2rem;
	font-weight: 700;
	cursor: pointer;

	img {
		width: 9%;
		margin-right: 10%;
	}

	ul {
		list-style: none;
		margin-left: 20%;
		width: 84%;
		padding: 0;
		margin-bottom: 0;
	}

	li {
		padding-top: 3%;
		padding-left: 10%;
		border-radius: 10px 0 0 10px;
		height: 40px;

		&.selected {
			background: var(--croi-green);
		}
	}

	&.selected {
		background-color: #3ad698;
	}

	&.dropdown {
		background: #d0d0d0;
	}
`;

export const DropButton = styled.img`
	margin-left: 20%;
	width: 10px;
	height: 10px;
`;

export const Sponsorship = styled.a`
	border-top: 2px solid lightgrey;
	text-align: center;
	position: absolute;
	bottom: 0;
	color: #52121b;
	text-decoration: none;

	:hover {
		color: #52121b;
	}

	img {
		display: block;
		margin-right: auto;
		margin-left: auto;
		width: 75%;
	}
`;

export const NavBarContainer = styled.div`
	font-family: aleo, serif;
	width: 83vw;
	margin-left: 17vw;
	margin-top: 15px;

	h2 {
		font-weight: bold;
		font-size: 2rem;
	}
`;

export const ProgressBar = styled.div`
	display: flex;
	justify-content: space-evenly;
	border-bottom: black solid 2px;
	padding-right: 10vw;

	img {
		width: 40px;
		height: 40px;
	}

	p {
		text-decoration: none;
		color: black;
		font-size: 1.3vw;
		width: 180px;
		text-align: center;
		margin-bottom: 0;
		font-weight: bold;

		&.selected {
			background-color: var(--croi-green);
			border-radius: 15px 15px 0 0;
			color: white;
		}
	}
`;

export const ProfileButton = styled.button`
	background: transparent url("/images/ProfileIcon.png") center no-repeat;
	background-size: contain;
	width: 50px;
	height: 50px;
	margin-right: 2vw;
`;

export const ProfileOptions = styled.div`
	display: block;
	background: white;
	width: 150px;
	padding: 5px;
	transform: translate(-100px, 50px);
	border: black 2px solid;
	position: relative;
	z-index: 1;

	:hover {
		background: #3dd5f3;
	}
`;
