import styled from "styled-components";
import { device } from "../../util/device";

export const NavContent = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0 20px;
	background-color: white;
	font-family: aleo, serif;
`;

export const UserNavContent = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	font-family: aleo, serif;
	width: 84vw;
	margin-left: 16vw;
`;

export const Logo = styled.div`
	@media only screen and ${device.mobileM} {
		//switch to hamburger nav
		margin-left: 18%;
	}

	@media only screen and ${device.tablet} {
		margin-left: 5.5%;
	}

	display: flex;

	a {
		text-decoration: none;
		color: var(--croi-green);
		font-weight: 700;
		font-size: 3rem;
		font-family: Aleo, serif;
	}
`;

export const NavSection = styled.div`
	margin-left: 20%;
	margin-right: 5%;

	@media only screen and ${device.mobileM} {
		//switch to hamburger nav
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flex;
	}

	a {
		text-decoration: none;
		color: black;
		font-size: 1.1vw;
		padding: 0px 20px 0;

		:hover {
			background-color: black;
			color: white;
			border-radius: 15px;
		}
	}
`;

export const UserNavSection = styled.div`
	margin-top: 20px;

	@media only screen and ${device.mobileM} {
		//switch to hamburger nav
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: space-evenly;
		width: 80%;
	}

	img {
		width: 40px;
		height: 40px;
	}

	p {
		text-decoration: none;
		color: black;
		font-size: 1.1vw;
		width: 180px;
		text-align: center;
		margin-bottom: 0;
		font-weight: bold;

		&.selected {
			background-color: white;
			border-radius: 15px 15px 0 0;
			color: var(--croi-green);
		}
	}
`;

export const LoginButton = styled.button`
	@media only screen and ${device.mobileM} {
		//switch to hamburger nav
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flow;
	}

	color: var(--croi-green);
	font-weight: 700;
	font-size: 1.2rem;
	background-color: transparent;
	height: 60px;
	margin-right: 100px;
	padding: 0 20px 0;
	:hover {
		background-color: var(--croi-green);
		color: white;
		border-radius: 10px;
	}
`;

export const ProfileButton = styled.button<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center no-repeat;
	width: 60px;
	height: 60px;
	margin-right: 10px;

	@media only screen and ${device.mobileM} {
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flow;
	}
`;

export const ProfileOptions = styled.div`
	display: block;
	background: white;
	width: 150px;
	padding: 5px;
	transform: translate(-100px, 50px);
	border: black 2px solid;
	position: relative;
	z-index: 1;

	:hover {
		background: #3dd5f3;
	}
`;

export const LoggedInButtons = styled.div``;

interface ImageProps {
	imageUrl: string;
}

export const TitleHeading = styled.div`
	color: black;
	margin-left: 40px;
	margin-top: 15px;
	font-weight: bold;

	@media only screen and ${device.mobileM} {
		font-size: 5.5vw;
	}

	@media only screen and ${device.tablet} {
		font-size: 2rem;
	}
`;
