import React, { useState } from "react";
import {
	FAMaster,
	LocationSection,
	MatchBoxList,
	MatchCollegeBox,
	MatchCompleteBar,
	MatchEdit,
	MatchMeButton,
	MatchSearchBar,
	MatchSearchBox,
	MatchSearchItem,
	MatchSearchRow,
	MatchTop,
	NameButtonSection,
	PricingInfo,
	SpecificPricing
} from "./FinancialAidMatch.styles";
import { PetersonCollege } from "../../../types/PetersonCollege.types";
import { ScaleLoader } from "react-spinners";
import useFetch from "../../../hooks/useFetch";
import { currencyFormatter } from "../../../util/currencyFormatter";
import { useMutation } from "@tanstack/react-query";
import { createCollege } from "../../../services/College.services";
import { queryClient } from "../../../services/QueryClient.services";
import { FinancialProfile } from "../../../types/FinancialProfile.types";
import FinancialProfileWindow from "../../FinancialProfileWindow/FinancialProfileWindow";
import { MatchData } from "../../../types/MatchData.types";

export default function FinancialAidMatch(props: { colleges: PetersonCollege[]; profile: FinancialProfile }) {
	const { searchResults, setSearchResults } = useFetch();
	const [window, setWindow] = useState<"Profile" | "Match" | "None">("None");
	const [matchList, setMatchList] = useState<MatchData[]>([]);
	const { profile } = props;

	const collegeCreation = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (id: string) => createCollege(id),
		onSuccess: () => {
			console.log("Created College Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const addCollege = (collegeId: string) => {
		collegeCreation.mutate(collegeId);
		setSearchResults({ ...searchResults, slug: "" });
	};

	const completion = () => {
		const max = 10;
		let numerator = 0;

		numerator += profile.data.familySize > -1 ? 1 : 0;
		numerator += props.profile.data.familyInCollege > -1 ? 1 : 0;
		numerator += props.profile.data.numParents > -1 ? 1 : 0;
		numerator += props.profile.data.parentAssetValue > -1 ? 1 : 0;
		numerator += props.profile.data.studentIncome > -1 ? 1 : 0;
		numerator += props.profile.data.taxPaid > -1 ? 1 : 0;
		numerator += props.profile.data.parentIncome > -1 ? 1 : 0;
		numerator += props.profile.data.untaxedIncome > -1 ? 1 : 0;
		numerator += props.profile.data.studentAssetValue > -1 ? 1 : 0;
		numerator += props.profile.data.oldestParent > -1 ? 1 : 0;

		console.log(numerator);

		return (numerator / max) * 100;
	};

	return (
		<FAMaster>
			<FinancialProfileWindow profile={props.profile} state={window} setState={setWindow} setMatchList={setMatchList} />
			<MatchTop>
				Financial Aid Profile &nbsp; <MatchEdit onClick={() => setWindow("Profile")}>Edit</MatchEdit>
			</MatchTop>
			<p style={{paddingTop: "5px"}}><em>Update your family's financial information here to ensure accurate aid and cost predictions for your college matches.</em></p>
			<MatchCompleteBar>
				<div style={{ width: `${completion}%` }} className={"progress"}>
					{completion().toFixed(0)}%&nbsp;Complete
				</div>
			</MatchCompleteBar>
			<h4>Search</h4>
			<p><em>Search for a school to add or click 'Match Me' to input your preferences and find colleges that fit your criteria.</em></p>
			<MatchSearchRow>
				<MatchSearchBar>
					<input
						type="text"
						value={searchResults.slug}
						onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
					/>
					{searchResults.slug !== "" && (
						<MatchSearchBox>
							{searchResults.loading && (
								<MatchSearchItem>
									<div style={{ margin: "50px auto 50px auto" }}>
										<ScaleLoader color="black" loading={searchResults.loading} />
									</div>
								</MatchSearchItem>
							)}
							{searchResults.results && (
								<>
									{searchResults.results.map((value) => {
										return (
											<MatchSearchItem key={value.id} onClick={() => addCollege(value.id)}>
												{value["school.name"]}
											</MatchSearchItem>
										);
									})}
								</>
							)}
						</MatchSearchBox>
					)}
				</MatchSearchBar>
				<b>or</b>
				<MatchMeButton onClick={() => setWindow("Match")}>
					Match Me&nbsp;
					<img src={"images/MatchMe.png"} alt={"MatchMeIcon"} />
				</MatchMeButton>
			</MatchSearchRow>
			<h4>Match Results</h4>
			{matchList.length < 1 ? (
				<span>Not Complete - Select "Match Me" to find college recommendations based on your criteria</span>
			) : (
				<MatchBoxList>
					{matchList.map((college) => (
						<MatchCollegeBox>
							<NameButtonSection>
								<h4 data-fulltext={college.name}>{college.name}</h4>
								<img onClick={() => addCollege(college.iped)} style={{ cursor: "pointer" }} src="images/plus_button.png" alt="plusButton"/>
							</NameButtonSection>
							<LocationSection>
								<img src="images/location_black.png" alt="locationBlack"/>
								<h6> {college.city}, {college.state} </h6>
							</LocationSection>
							<hr/>
							<PricingInfo>
								<SpecificPricing style={{paddingTop: "10px"}}>
									<p> Sticker Price: </p>
									<b>{currencyFormatter.format(college.stickerPrice)}</b>
								</SpecificPricing>
								<SpecificPricing>
									<p> Your Net Cost (Year 1): </p>
									<b>{currencyFormatter.format(college.yourPrice)}</b>
								</SpecificPricing>
							</PricingInfo>
						</MatchCollegeBox>
					))}
				</MatchBoxList>
			)}
			<h4>My College List</h4>
			<MatchBoxList>
				{props.colleges.map((college) => (
					<MatchCollegeBox>
						<NameButtonSection>
							<h4 data-fulltext={college.name}>{college.name}</h4>
						</NameButtonSection>
						<LocationSection>
							<img src="images/location_black.png" alt="locationBlack"/>
							<h6> {college.city}, {college.state} </h6>
						</LocationSection>
						<hr/>
						<PricingInfo>
							<SpecificPricing style={{paddingTop: "10px"}}>
								<p> Sticker Price: </p>
								<b>{currencyFormatter.format(college.costPerYear)}</b>
							</SpecificPricing>
							<SpecificPricing>
								<p> Your Net Cost (Year 1): </p>
								<b>{currencyFormatter.format(college.totalCost)}</b>
							</SpecificPricing>
						</PricingInfo>
					</MatchCollegeBox>
				))}
				{/*<AddSchoolBox>*/}
				{/*	ADD SCHOOL <br /> <p>+</p>*/}
				{/*</AddSchoolBox>*/}
			</MatchBoxList>
		</FAMaster>
	);
}
