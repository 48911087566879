import { fetcher } from "./Fetcher.services";
import { College } from "../types/College.types";
import { mutator } from "./Mutator.services";
import { PetersonCollege } from "../types/PetersonCollege.types";
import { fetcherBody } from "./FetcherBody.services";
import { CostNavInfo } from "../types/CostNav.types";
import { MatchData } from "../types/MatchData.types";

export function fetchCollegeList() {
	return fetcher<College[]>("/colleges", "GET");
}

export function createCollege(collegeId: string) {
	const body = { collegeId: `${collegeId}` };

	return mutator("/colleges", "POST", body);
}

export function createFinancialAward(collegeID: string, body: any) {
	return mutator(`/colleges/${collegeID}/financial-award`, "POST", body);
}

export function deleteCollege(collegeID: string, body?: any | null) {
	body = {
		id: collegeID
	};
	return mutator("/colleges/" + collegeID, "DELETE", body);
}

export function updateFinancialAward(collegeID: string, FAID: string, body: any) {
	return mutator(`/colleges/${collegeID}/financial-award/${FAID}`, "PUT", body);
}

export function deleteFinancialAward(collegeID: string, FAID: string, body: any) {
	return mutator("/colleges/" + collegeID + "/financial-award/" + FAID, "DELETE", body);
}

export function changeFieldOfStudy(collegeID: string, body: any) {
	return mutator(`/colleges/${collegeID}/field-of-study`, "POST", body);
}

export interface FoS {
	title: string;
	code: string;
	earnings: number;
}

export function getFieldsOfStudy(collegeID: string) {
	const data = fetcher<FoS[]>(`colleges/${collegeID}/programs`, "GET"); // "latest.programs.cip_4_digit"
	return data.then((data) => {
		console.log(data);
		return data;
	});
}

export function fetchStudentColleges(uid: string) {
	return fetcher<College[]>(`/colleges/student/${uid}`, "GET");
}

export function updateTypeScore(body: any) {
	return mutator(`/colleges/${body.id}`, "PUT", body);
}

export function fetchPetersonData() {
	return fetcher<PetersonCollege[]>("/colleges/peterson", "POST");
}

export function fetchCostNav(body: any) {
	return fetcherBody<CostNavInfo>("/colleges/costNav", "Post", body);
}

export function fetchMatchData(body: any) {
	return fetcherBody<MatchData[]>("/colleges/match", "POST", body);
}
