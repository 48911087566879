import styled from "styled-components";

export const HeaderAdminPage = styled.div`
	font-family: aleo, serif;
	font-size: 3rem;
	color: #000;
	width: 100%;
`;

export const ViewContent = styled.nav`
	margin-left: 85%;
	display: flex;
	position: fixed;
	transform: translate(0px, 110px);
	background-color: #3ad698;
	//align-items: left;
	flex-direction: column;
	height: 100%;
	width: 20%;
	right: 0;
	top: 0;
	padding: 40px 0 0 0;
	border: 5px solid black;
`;

export const CollegeWrapper = styled.p`
	a {
		padding-left: 10px;
		display: block;
	}

	li {
		padding-left: 20px;
	}
`;

export const ImportStudentButtons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1%;
`;

export const AddStudent = styled.button`
	background-color: #3ad698;
	color: #fff;
	border-radius: 10px;
	width: 75px;
	height: 40px;
	margin-left: 10px;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;

export const AddBulk = styled.button`
	background-color: #d6533a;
	color: #fff;
	border-radius: 10px;
	width: 75px;
	height: 40px;
	margin-left: 10px;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;

export const ModalStyle = styled.div`
	/* Chrome, Safari, Edge, Opera */

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input {
		width: 350px;
		height: 40px;
		margin-top: 10px;
		border: 1px solid black;
		border-radius: 5px;
		padding-left: 10px;
	}

	div {
		display: flex;
	}

	p {
		width: 90px;
		padding-top: 15px;
		margin-left: 15px;
	}
`;

export const ResetButton = styled.button`
	border-radius: 20px;
	background: var(--croi-green);
	width: 100px;
	height: 40px;
`;

export const SortButton = styled.button`
	background-color: black;
	color: #fff;
	border-radius: 10px;
	width: 130px;
	height: 40px;
	margin-left: 10px;
`;
