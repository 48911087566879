import styled from "styled-components";
import { device } from "../../util/device";

export const TitleSection = styled.div`
	position: relative;
	padding-left: 5%;
	transform: translateY(25%);
	max-width: 800px;
	max-height: 50vh;
	margin-bottom: 500px;

	@media only screen and ${device.mobileM} {
		top: 0;
		left: 0;
	}

	@media only screen and ${device.tablet} {
		top: 100px;
		left: 130px;
	}
`;

export const Title = styled.h1`
	text-align: left;
	color: white;
	font-weight: bold;

	@media only screen and ${device.mobileM} {
		font-size: 3em;
	}

	@media only screen and ${device.tablet} {
		font-size: 4.7em;
	}
`;

export const TitleWrapper = styled.div`
	@media only screen and ${device.mobileM} {
		background-size: cover;
		height: 65vh;
	}

	@media only screen and ${device.tablet} {
		background-size: 100%;
		height: 100vh;
	}
`;

export const TitleImage = styled.div`
	@media only screen and ${device.mobileM} {
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flow;
	}
`;

export const PageText = styled.div`
	color: white;
	padding-top: 20px;
	text-align: left;

	@media only screen and ${device.mobileM} {
		font-size: 18px;
		text-indent: 0;
	}

	@media only screen and ${device.tablet} {
		font-size: 30px;
		text-indent: 0;
	}
`;

export const PageTitle = styled.h2`
	font-size: 8vh;
	color: black;
	text-align: center;
	font-weight: bold;
`;

export const PageSubTitle = styled.h2`
	font-size: 4vh;
	color: black;
	text-align: center;
	margin-top: 30px;
	text-decoration: underline;
	font-weight: bold;
`;

export const PageSubText = styled.h3`
	font-size: 30px;
	color: black;
	text-align: center;
	margin-top: 40px;
`;

export const InputBox = styled.input`
	@media only screen and ${device.mobileM} {
		//remove demo option on mobile
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flow;
	}

	height: 48px;
	border: 2px solid var(--croi-light-gray);
	background-color: #e8e8e8;
	width: 60%;
	margin-left: 20%;
	margin-top: 50px;
	font-size: 30px;
	color: var(--croi-light-gray);
`;

export const TriangleButton = styled.button`
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right: 45px solid transparent;
	border-left: 45px solid grey;
	font-size: 30px;
	cursor: pointer;
	margin-left: 77%;
	margin-top: -38px;
	background-color: transparent;
`;
