import styled from "styled-components";
import { device } from "../../util/device";

export const TitleSection = styled.div`
	color: black;
	text-align: center;

	@media only screen and ${device.mobileM} {
		h1 {
			font-size: 40px;
			font-weight: 900;
		}

		h3 {
			font-size: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		h1 {
			font-size: 3.5vw;
			font-weight: 900;
		}

		h3 {
			font-size: 1.75vw;
		}
	}
`;

export const ContactUsButton = styled.button`
	display: block;
	height: 50px;
	text-align: center;
	background-color: white;
	border-radius: 30px;
	width: 30%;
	font-size: 3vh;
	color: var(--croi-green);
	margin-left: 35%;
	margin-top: 5%;
	font-weight: bold;
	font-family: Aleo;

	a {
		color: var(--croi-green);
		font-family: Aleo;
	}

	@media only screen and ${device.mobileM} {
		//remove demo option on mobile
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: block;
		text-align: center;
	}
`;

export const ContactBox = styled.div`
	background-color: #E9FDF5;
	border-radius: 10px;
	text-align: center;
	width: 70%;
	margin-left: 15%;
	margin-bottom: 5%;
	margin-top: 60px;

	@media only screen and ${device.mobileS} {
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		height: 450px;
	}
`;

export const ContactColumn = styled.div`
	input {
		width: 90%;
		border: black solid 2px;
		margin-bottom: 15px;
		border-radius: 5px;
	}

	@media only screen and ${device.mobileS} {
		width: 90%;
		margin-left: 5%;
		text-align: left;
		margin-top: 20px;
	}

	@media only screen and ${device.tablet} {
		width: 40%;
		margin-left: 10%;
		text-align: left;
		margin-top: 20px;
	}
`;

export const InfoColumn = styled.div`
	h2 {
		padding-bottom: 10px;
	}

	@media only screen and ${device.mobileS} {
		width: 90%;
		margin-left: 5%;
		text-align: left;
		margin-top: 30px;
	}

	@media only screen and ${device.tablet} {
		width: 40%;
		margin-left: 5%;
		text-align: left;
		margin-top: 30px;
	}
`;

export const HeadquarterColumn = styled.div`
	@media only screen and ${device.mobileS} {
		text-align: left;
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		text-align: left;
		display: flex;
		flex-direction: row;
	}
`;

export const BusinessColumn = styled.div`
	@media only screen and ${device.mobileS} {
		text-align: left;
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		text-align: left;
		display: flex;
		flex-direction: row;
	}
`;

export const LocationColumn = styled.div`
	@media only screen and ${device.mobileS} {
		text-align: left;
		width: 90%;

		p {
			margin-left: 55px;
		}
	}

	@media only screen and ${device.tablet} {
		text-align: left;
		width: 40%;

		p {
			margin-left: 55px;
		}
	}
`;

export const PicWordRow = styled.div`
	justify-content: left;
	text-align: left;
	display: flex;
	flex-direction: row;
	margin-left: 20px;

	img {
		height: 25px;
		width: 35px;
		padding-right: 10px;
	}

	
`;

export const CroiContactColumn = styled.div`
	text-align: left;
	display: flex;
	flex-direction: column;
`;

export const SubmitButton = styled.button`
	height: 50px;
	text-align: center;
	background-color: var(--croi-green);
	border-radius: 5px;
	width: 90%;
	font-size: 3vh;
	color: white;
	margin-top: 15px;
	font-weight: bold;
`;

export const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;
  
export const ModalContent = styled.div`
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	position: relative;
	max-width: 500px;
	width: 100%;
`;
  
export const ModalClose = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
`;
  