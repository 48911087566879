import { auth } from "../../firebase";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { fetchUser } from "../../services/User.services";
import {
	DropButton,
	Logo,
	NavBarContainer,
	Option,
	ProfileButton,
	ProfileOptions,
	ProgressBar,
	SidebarContainer,
	Sponsorship,
	UserToolsMaster
} from "./UserTools.styles";
import { sidebarOptions } from "../../util/SidebarOptions";
import Dashboard from "../Dashboard/Dashboard";
import CostNav from "../CostNav/CostNav";
import CollegeDecisionTool from "../CollegeDecisionTool/CollegeDecisionTool";
import SalaryInsights from "../SalaryInsights/SalaryInsights";
import StudentLoan from "../StudentLoan/StudentLoan";
import { LWData } from "../../types/LearnWorlds.types";
import { fetchLWData } from "../../services/Learn.services";
import FinancialAidMatch from "../../components/UserToolModules/FinancialAidMatch/FinancialAidMatch";
import dashLogo from "../../atoms/sidebarLogos/DashboardLogo.svg";
import learnLogo from "../../atoms/sidebarLogos/Learn.svg";
import matchLogo from "../../atoms/sidebarLogos/Match.svg";
import analyzeLogo from "../../atoms/sidebarLogos/Analyze.svg";
import decideLogo from "../../atoms/sidebarLogos/CollegeLogo.svg";
import financeLogo from "../../atoms/sidebarLogos/Finance.svg";
import { PetersonCollege } from "../../types/PetersonCollege.types";
import { fetchPetersonData } from "../../services/College.services";
import { FinancialProfile } from "../../types/FinancialProfile.types";
import { fetchFinancialProfile } from "../../services/Profile.services";
import LearnNav from "../LearnNav/LearnNav";
import UserSettings from "../UserSettings/UserSettings";
import { AdminPage } from "../AdminPage/AdminPage";

export default function UserTools() {
	const [tool, setTool] = useState("Dashboard");
	const [showDropDown, setShowDropDown] = useState(false);

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchInterval: false,
		enabled: !!auth.currentUser
	});

	const getLWData = useQuery<LWData[], Error>({
		queryKey: ["LearnWorlds"],
		queryFn: () => fetchLWData(),
		refetchInterval: false
	});

	const getColleges = useQuery<PetersonCollege[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchPetersonData(),
		refetchInterval: false
	});

	const getProfile = useQuery<FinancialProfile, Error>({
		queryKey: ["profile"],
		queryFn: () => fetchFinancialProfile(),
		refetchInterval: false
	});

	const [analyzeDropDown, setAnalyzeDropdown] = useState(false);
	const SideBar = () => {
		return (
			<SidebarContainer>
				<Logo>
					<a href={"/userTools"}>
						<img alt={"croi logo"} src={"images/logo_green.svg"}></img>
						croi
					</a>
				</Logo>

				<Option className={tool === "Dashboard" ? "selected" : ""} onClick={() => setTool("Dashboard")}>
					<img alt={"Dash Logo"} src={dashLogo} />
					Dashboard
				</Option>
				<Option className={tool === "Learn" ? "selected" : ""} onClick={() => setTool("Learn")}>
					<img alt={"Learn Logo"} src={learnLogo} />
					Learn
				</Option>
				<Option className={tool === "Match" ? "selected" : ""} onClick={() => setTool("Match")}>
					<img alt={"Match Logo"} src={matchLogo} />
					Match
				</Option>
				<Option className={analyzeDropDown ? "dropdown" : ""}>
					<span onClick={() => setAnalyzeDropdown(!analyzeDropDown)}>
						<img alt={"Analyze Logo"} src={analyzeLogo} />
						Analyze
						{analyzeDropDown ? (
							<DropButton alt={"expand"} src={"images/expand_more.svg"} style={{ rotate: "180deg" }} />
						) : (
							<DropButton alt={"expand"} src={"images/expand_more.svg"} />
						)}
					</span>
					{analyzeDropDown ? (
						<>
							<ul>
								<li className={tool === "Analyze-Cost" ? "selected" : ""} onClick={() => setTool("Analyze-Cost")}>
									Cost Navigator
								</li>
								<li className={tool === "Analyze-Salary" ? "selected" : ""} onClick={() => setTool("Analyze-Salary")}>
									Salary Insights
								</li>
							</ul>
						</>
					) : (
						<></>
					)}
				</Option>
				<Option className={tool === "Decide" ? "selected" : ""} onClick={() => setTool("Decide")}>
					<img alt={"Decide Logo"} src={decideLogo} />
					Decide
				</Option>
				<Option className={tool === "Finance" ? "selected" : ""} onClick={() => setTool("Finance")}>
					<img alt={"Finance Logo"} src={financeLogo} />
					Finance
				</Option>

				{getUser.data!.data.highSchoolId === "Cornerstone Bank" ? (
					<Sponsorship href={"https://cornerstonebank.com/student/"}>
						This account is sponsored by:
						<img alt={"Cornerstone"} src={"/images/CornerStone.png"}></img>
					</Sponsorship>
				) : (
					<></>
				)}
			</SidebarContainer>
		);
	};

	const signOutButton = () => {
		auth
			.signOut()
			.then(() => {
				window.location.replace(window.location.origin);
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	const options = ["Settings", getUser.data?.data.isAdmin ? "Admin" : "", "Logout"];

	const optionSelection = (option: string): void => {
		if (option === "Logout") {
			signOutButton();
		}

		if (option === "User Settings") {
			window.location.replace(window.location.origin + "/settings");
		}

		if (option === "Admin Panel") {
			window.location.replace(window.location.origin + "/admin");
		}
	};

	const optionMapper = options.map((option: string) =>
		option.length > 0 ? (
			<ProfileOptions
				onClick={(): void => {
					optionSelection(option);
				}}>
				{option}
			</ProfileOptions>
		) : (
			<></>
		)
	);

	const NavBar = () => {
		return (
			<NavBarContainer>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<h2>{sidebarOptions.find((option) => option.sideTitle === tool)?.navTitle}</h2>
					<ProfileButton
						onClick={() => setShowDropDown(!showDropDown)}
						onBlur={() => setShowDropDown(false)}
						style={{
							backgroundImage: auth.currentUser?.photoURL ? `url(${auth.currentUser.photoURL})` : ""
						}}>
						{showDropDown ? optionMapper : <></>}
					</ProfileButton>
				</div>
				<ProgressBar>
					<p className={tool === "Learn" ? "selected" : ""}>Learn</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"} />
					<p className={tool === "Match" ? "selected" : ""}>Match</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"} />
					<p className={tool.includes("Analyze") ? "selected" : ""}>Analyze</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"} />
					<p className={tool === "Decide" ? "selected" : ""}>Decide</p>
					<img alt="Arrow Right" src={"/images/arrow_right.png"} />
					<p className={tool === "Finance" ? "selected" : ""}>Finance</p>
				</ProgressBar>
			</NavBarContainer>
		);
	};

	const selectedTool = () => {
		switch (tool) {
			case "Dashboard":
				return <Dashboard setTool={setTool} />;
			case "Learn":
				return <LearnNav {...getLWData.data!} />;
			case "Analyze-Cost":
				return <CostNav />;
			case "Analyze-Salary":
				return <SalaryInsights />;
			case "Decide":
				return <CollegeDecisionTool />;
			case "Finance":
				return <StudentLoan />;
			case "Match":
				return <FinancialAidMatch colleges={getColleges.data!} profile={getProfile.data!} />;
			case "Settings":
				return <UserSettings />;
			case "Admin":
				return <AdminPage />;
		}
	};

	const loadingData = () => {
		return getUser.isLoading || getLWData.isLoading || getColleges.isLoading || getProfile.isLoading;
	};

	return !loadingData() ? (
		<>
			<SideBar />
			<NavBar />
			<UserToolsMaster>{selectedTool()}</UserToolsMaster>
		</>
	) : (
		<div style={{ textAlign: "center", marginTop: "25vh", fontSize: "larger", fontWeight: "bold" }}>
			Loading User Data...
		</div>
	);
}
