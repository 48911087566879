import React, { useEffect, useState } from "react";
import Blog from "../components/BlogCard/BlogCard";

const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@cadencrist";

type Post = {
	categories: string[];
};

type Info = {
	data: Data;
};

type Data = {
	feed: Feed;
	items: [];
};
type Feed = {
	image: string;
	link: string;
};

import axios from "axios";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/Navbar/Navbar";

const BlogPage = () => {
	const [blog, setBlog] = useState({
		item: [],
		isLoading: true,
		error: ""
	});

	useEffect(() => {
		axios
			.get(mediumURL)
			.then((info: Info) => {
				const blogs = info.data.items;
				const posts = blogs.filter((post: Post) => post.categories.length > 0);

				setBlog({ item: posts, isLoading: false, error: "" });
			})
			.catch((err: Error) => setBlog({ item: [], isLoading: false, error: err.message }));
	}, [axios]);

	return (
		<>
			<NavBar />
			<Blog blog={blog} />
			<Footer />
		</>
	);
};

export default BlogPage;
