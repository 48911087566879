import React from "react";

const TableHeadItem = ({ item }: TableHeadItemProps) => {
	return <td title={item}>{item}</td>;
};

type TableHeadItemProps = {
	item: string;
};

export default TableHeadItem;
