import styled from "styled-components";

export const CalcButton = styled.button`
	background-color: var(--croi-green);
	border: none;
	border-radius: 10px;
	color: white;
	height: 50px;
	width: 80%;
	margin-top: 35px;
`;

export const LargeCard = styled.div`
	height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #eeeeee;
	width: 30vw;
	margin-top: 115px;

	p {
		font-size: 28px;
		margin-top: 30px;
		margin-left: 30px;
	}

	h2 {
		margin-top: 15px;
	}
`;

export const SmallCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 95%;
	height: 50px;
	background-color: #cccccc;
	margin-bottom: 10px;
	border-radius: 10px;
`;

export const SearchInputs = styled.div`
	display: flex;
	margin-top: 25px;
	font-size: 20px;
	margin-left: 10%;

	/* Chrome, Safari, Edge, Opera */

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	input[type="number"] {
		-moz-appearance: textfield;
	}
`;
