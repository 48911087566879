import {
	CostDataBox,
	CostNavMaster,
	CostNavMid,
	CostNavTop,
	CostSearchBar,
	CostSearchBox,
	CostSearchItem,
	EstimatedTotal,
	YearlyTotal,
	YearsBox
} from "./CostNav.styles";
import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useQuery } from "@tanstack/react-query";
import { fetchCostNav } from "../../services/College.services";
import { ScaleLoader } from "react-spinners";
import { CostNavInfo } from "../../types/CostNav.types";
import { currencyFormatter } from "../../util/currencyFormatter";

type option = {
	onCampus: boolean;
	mealPlan: boolean;
};

export default function CostNav() {
	const [years, setYears] = useState(4);
	const { searchResults, setSearchResults } = useFetch();
	const [iped, setIped] = useState("");
	const [name, setName] = useState("");
	const [enabler, setEnabler] = useState(false);
	const [options, setOptions] = useState<option[]>([
		{ onCampus: true, mealPlan: true },
		{ onCampus: true, mealPlan: true },
		{ onCampus: false, mealPlan: false },
		{ onCampus: false, mealPlan: false }
	]);
	const [midID, setMidID] = useState("1midSection");

	const getCostInfo = useQuery<CostNavInfo, Error>({
		queryKey: ["CostNav"],
		queryFn: () => fetchCostNav({ id: iped }),
		refetchInterval: false,
		enabled: enabler,
		retry: false
	});

	const handleItemClick = (collegeId: string, college: string) => {
		setIped(collegeId);
		setName(college);
		setEnabler(true);
		setSearchResults({ ...searchResults, slug: "" });
	};

	useEffect(() => {
		if (enabler) {
			getCostInfo.refetch();
		}
	}, [iped]);

	const yearlyCost = (info: CostNavInfo, index: number) => {
		let sum = info.booksNSupplies + info.tuition + info.fees + (index == 0 ? info.firstFees : 0);

		if (options[index].onCampus) {
			sum += info.room;
		} else {
			sum += info.living;
		}

		if (options[index].mealPlan) {
			sum += info.mealPlan;
		} else {
			sum += info.groceries;
		}

		return sum;
	};

	const optionSetter = (campus: boolean, value: boolean, index: number) => {
		const temp = options;
		if (campus) {
			temp[index].onCampus = value;
		} else {
			temp[index].mealPlan = value;
		}
		setOptions(temp);
		setMidID(`${parseInt(midID) + 1}midSection`);
	};

	const costMapper = (info: CostNavInfo) => {
		const data = [];

		for (let i = 0; i < years; i++) {
			data.push(
				<div style={{ width: "23%" }} id={`Year ${i + 1}`}>
					<CostDataBox>
						<h5>{i + 1} Year</h5>
						<div>
							<h6>Housing</h6>
							<select
								defaultValue={`${options[i].onCampus}`}
								onChange={(value) => optionSetter(true, value.target.value == "true", i)}>
								<option value="true">On-Campus</option>
								<option value="false">Off-Campus</option>
							</select>
						</div>
						<div>
							<h6>Food</h6>
							<select
								defaultValue={`${options[i].mealPlan}`}
								onChange={(value) => optionSetter(false, value.target.value == "true", i)}>
								<option value="true">Meal Plan</option>
								<option value="false">Groceries</option>
							</select>
						</div>
						<div>
							<h6>Tuition & Fees</h6>
							<p>{currencyFormatter.format(info.tuition + info.fees + (i == 0 ? info.firstFees : 0))}</p>
						</div>
						<div>
							<h6>{`${options[i].onCampus ? "Room &" : "Off-Campus &"} ${
								options[i].mealPlan ? "Board" : "Groceries"
							}`}</h6>
							<p>
								{currencyFormatter.format(
									(options[i].onCampus ? info.room : info.living) +
										(options[i].mealPlan ? info.mealPlan : info.groceries)
								)}
							</p>
						</div>
						<div style={{ paddingBottom: "1px" }}>
							<h6>Books & Supplies</h6>
							<p>{currencyFormatter.format(info.booksNSupplies)}</p>
						</div>
					</CostDataBox>
					<YearlyTotal>
						<h6>Estimated Cost</h6>
						<p>{currencyFormatter.format(yearlyCost(info, i))}</p>
					</YearlyTotal>
				</div>
			);
		}

		return data;
	};

	const costCalcTotal = (info: CostNavInfo) => {
		let sum = 0;

		for (let i = 0; i < years; i++) {
			sum += info.tuition + info.booksNSupplies + info.fees + info.firstFees;

			if (options[i].onCampus) {
				sum += info.room;
			} else {
				sum += info.living;
			}

			if (options[i].mealPlan) {
				sum += info.mealPlan;
			} else {
				sum += info.groceries;
			}
		}

		return sum;
	};

	return (
		<CostNavMaster>
			<CostNavTop>
				<CostSearchBar>
					<input
						type="text"
						placeholder={name.length > 0 ? name : "Search for a college"}
						value={searchResults.slug}
						onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
					/>
					{searchResults.slug !== "" && (
						<CostSearchBox>
							{searchResults.loading && (
								<CostSearchItem>
									<div style={{ margin: "50px auto 50px auto" }}>
										<ScaleLoader color="black" loading={searchResults.loading} />
									</div>
								</CostSearchItem>
							)}
							{searchResults.results && (
								<>
									{searchResults.results.map((value) => {
										return (
											<CostSearchItem key={value.id} onClick={() => handleItemClick(value.id, value["school.name"])}>
												{value["school.name"]}
											</CostSearchItem>
										);
									})}
								</>
							)}
						</CostSearchBox>
					)}
				</CostSearchBar>
				<YearsBox onChange={(e) => setYears(parseInt(e.target.value))} defaultValue={4}>
					<option value={1}>1 Year</option>
					<option value={2}>2 Years</option>
					<option value={3}>3 Years</option>
					<option value={4}>4 Years</option>
				</YearsBox>
			</CostNavTop>
			<CostNavMid id={midID} style={{ justifyContent: years <= 2 ? "space-evenly" : "space-between" }}>
				{getCostInfo.data ? costMapper(getCostInfo.data) : <h2>Please Select a College</h2>}
			</CostNavMid>
			<p><em>Select your school and customize yearly housing arrangements and meal options to see a detailed, personalized estimate of your total educational costs over four years.</em></p>
			<EstimatedTotal>
				<h6>Estimated {years}-Year Cost</h6>
				<p>{getCostInfo.data ? currencyFormatter.format(costCalcTotal(getCostInfo.data)) : "N/A"}</p>
			</EstimatedTotal>
		</CostNavMaster>
	);
}
