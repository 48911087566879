import axios, { AxiosResponse } from "axios";
import { auth } from "../firebase";

const base = process.env.REACT_APP_USER_API_URL;

export const mutator = async (url: string, method: string, body: object) => {
	const axiosInstance = axios.create({
		baseURL: base,
		timeout: 5000
	});

	const idToken = await auth.currentUser!.getIdToken();

	// console.log("Axios body: ", body);
	await axiosInstance({
		url: url,
		method,
		headers: { Authorization: `Bearer ${idToken}` },
		data: body
	}).then((res: AxiosResponse) => res.data);
};
