import React, { useState } from "react";
import { Card, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { SubmitButton } from "../../styles/SubmitButton.styles";
import { SignUpCardWrapper, SignupWrapper } from "./Signup.styles";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import NavBar from "../Navbar/Navbar";

export default function Signup() {
	const [registering, setRegistering] = useState<boolean>(false);
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [emailRef, setEmail] = useState<string>("");
	const [passwordRef, setPassword] = useState<string>("");
	const [passwordConf, setPasswordConf] = useState<string>("");
	const [accessCode, setAccessCode] = useState<string>("");
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	const signUp = async (firstName: string, lastName: string, email: string, password: string, accessCode: string) => {
		setRegistering(true);

		await axios
			.post(
				process.env.REACT_APP_SIGNUP_API_URL
					? process.env.REACT_APP_SIGNUP_API_URL + "/createUser"
					: "http://localhost:3000/createUser",
				{
					firstName,
					lastName,
					email,
					password,
					accessCode
				}
			)
			.then(() => {
				setRegistering(false);
				navigate("/login");
			})
			.catch((err) => {
				console.log(err.response.data.message);
				setRegistering(false);
				setError(err.response.data.message);
			});
	};

	return (
		<>
			<NavBar />
			<SignupWrapper>
				<Row className=" d-flex align-items-center justify-content-center">
					<div style={{ maxWidth: "400px" }}>
						<SignUpCardWrapper>
							<Card>
								<Card.Img variant="top" src="images/croi logo no words.png" />
								<Card.Body>
									<h4
										className="text-center mb-4"
										style={{
											borderBottom: "1px solid #000",
											lineHeight: "0.1em",
											fontFamily: "Montserrat"
										}}>
										<span
											style={{
												background: "var(--white)",
												padding: "0 10px"
											}}>
											Sign-Up
										</span>
									</h4>
									<Form onSubmit={(e) => e.preventDefault()}>
										<Form.Group>
											<Form.Label>First Name</Form.Label>
											<Form.Control
												type="string"
												onChange={(event) => setFirstName(event.target.value)}
												value={firstName}
												required
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Last Name</Form.Label>
											<Form.Control
												type="string"
												onChange={(event) => setLastName(event.target.value)}
												value={lastName}
												required
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Email </Form.Label>
											<Form.Control
												type="email"
												onChange={(event) => setEmail(event.target.value)}
												value={emailRef}
												required
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Password </Form.Label>
											<Form.Control
												type="password"
												autoComplete="new-password"
												onChange={(event) => setPassword(event.target.value)}
												value={passwordRef}
												required
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Password Confirmation </Form.Label>
											<Form.Control
												type="password"
												autoComplete="new-password"
												onChange={(event) => setPasswordConf(event.target.value)}
												value={passwordConf}
												required
											/>
										</Form.Group>
										{passwordConf !== passwordRef ? <p className="text-danger m-2">Passwords Do Not Match</p> : <p></p>}
										<Form.Group>
											<Form.Label>School Access Code</Form.Label>
											<Form.Control
												type="input"
												onChange={(event) => setAccessCode(event.target.value)}
												value={accessCode}
												required></Form.Control>
										</Form.Group>
										<SubmitButton
											onClick={() => signUp(firstName, lastName, emailRef, passwordRef, accessCode)}
											disabled={registering || passwordConf !== passwordRef}>
											Submit
										</SubmitButton>
									</Form>
									<Modal centered className="custom-modal" backDrop={"static"} show={registering}>
										<Modal.Body className="custom-modal-body">
											<ScaleLoader />
										</Modal.Body>
									</Modal>
									{error && <p style={{ color: "red" }}>{error}</p>}
								</Card.Body>
								<div className="w-100 text-center mb-2">
									Already have and account? <Link to="/login">Login</Link>
								</div>
							</Card>
						</SignUpCardWrapper>
					</div>
				</Row>
			</SignupWrapper>
		</>
	);
}
